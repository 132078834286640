import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Form } from "react-form";
import { connect } from "react-redux";
import DateFnsUtils from "@date-io/date-fns";
import { getValue } from "../../../api/utils";
import { editWorkPlanStatuses } from "../../../api/constants";

import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import ViewIcon from "@material-ui/icons/RemoveRedEye";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

import HelpLabel from "../../common/HelpLabel";
import Switch from "../../common/Switch";
import TextField from "../../common/TextField";

import { withStyles } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

import { Proposal } from "../../proposal/models";
import { Organization, WorkPlanTab } from "../../home/models";

import { createSelector, reloadListOfModels } from "../../common/orm";
import { StatusUpdateActivity, StatusUpdate, FinalWorkPlan, AmendmentLog } from "../models";

const getStatusUpdates = createSelector(
    (state, ownProps) => parseInt(ownProps.match.params["id"]),
    (session, id) => {
        var wp = session.FinalWorkPlan.filter(f => f.proposal_record_id === id).toModelArray()[0];
        if (wp) {
            return session.StatusUpdate.filter(x => x.work_plan === wp.id)
                .orderBy("id", "desc")
                .toModelArray()
                .map(su => ({
                    _activities: su.status_update_activity
                        .all()
                        .orderBy("id")
                        .toRefArray(),
                    ...su.ref
                }));
        }
    }
);

const getTab = WorkPlanTab.selectMessage(29);
const getStatusUpdateActivities = StatusUpdateActivity.selectAll();

const getActivities = createSelector(
    (state, ownProps) => ownProps.plan.id,
    (state, ownProps) => ownProps.match.path === "/draft/workplan/:id",
    (session, id, isDraft) => {
        if (isDraft) {
            return session.DraftProposalActivity.filter({ proposal: id })
                .toModelArray()
                .map(activity => ({
                    ...activity.ref,
                    milestones: activity.draft_milestone_activities
                        .all()
                        .orderBy("id")
                        .toRefArray()
                }));
        } else {
            return session.FinalProposalActivity.filter({ proposal: id })
                .toModelArray()
                .map(activity => ({
                    ...activity.ref,
                    milestones: activity.final_milestone_activities
                        .all()
                        .orderBy("id")
                        .toRefArray()
                }));
        }
    }
);

const getParcels = createSelector(
    (state, ownProps) => ownProps.plan.id,
    (state, ownProps) => ownProps.match.path === "/draft/workplan/:id",
    (session, id, isDraft) => {
        if (isDraft) {
            return session.DraftProposalParcel.filter({ proposal: id })
                .orderBy("name", "asc")
                .toRefArray();
        } else {
            return session.FinalProposalParcel.filter({ proposal: id })
                .orderBy("name", "asc")
                .toRefArray();
        }
    }
);

const getBoardAttachments = createSelector(
    (state, ownProps) => ownProps.plan.id,
    (state, ownProps) => ownProps.match.path === "/draft/workplan/:id",
    (session, id, isDraft) => {
        if (isDraft) {
            return session.DraftProposalAttachment.filter({ proposal: id, type: "Board Resolution" })
                .orderBy("id")
                .count();
        } else {
            return session.FinalProposalAttachment.filter({ proposal: id, type: "Board Resolution" })
                .orderBy("id")
                .count();
        }
    }
);

const getFinancialAttachments = createSelector(
    (state, ownProps) => ownProps.plan.id,
    (state, ownProps) => ownProps.match.path === "/draft/workplan/:id",
    (session, id, isDraft) => {
        if (isDraft) {
            return session.DraftProposalAttachment.filter({ proposal: id, type: "Financial Capacity" })
                .orderBy("id")
                .count();
        } else {
            return session.FinalProposalAttachment.filter({ proposal: id, type: "Financial Capacity" })
                .orderBy("id")
                .count();
        }
    }
);

const getContracts = createSelector(
    (state, ownProps) => ownProps.plan.id,
    (state, ownProps) => ownProps.match.path === "/draft/workplan/:id",
    (session, id, isDraft) => {
        if (isDraft) {
            return session.DraftProposalContract.filter({ proposal: id })
                .orderBy("id")
                .toModelArray();
        } else {
            return session.FinalProposalContract.filter({ proposal: id })
                .orderBy("id")
                .toModelArray();
        }
    }
);

const getProposalTravels = createSelector(
    (state, ownProps) => ownProps.plan.id,
    (state, ownProps) => ownProps.match.path === "/draft/workplan/:id",
    (session, id, isDraft) => {
        if (isDraft) {
            return session.DraftProposalTravel.filter({ proposal: id })
                .orderBy("id")
                .toModelArray();
        } else {
            return session.FinalProposalTravel.filter({ proposal: id })
                .orderBy("id")
                .toModelArray();
        }
    }
);

const getOrganizations = Organization.selectAll();

const getAmendments = createSelector(
    (state, ownProps) => parseInt(ownProps.match.params["id"]),
    (session, id) => {
        var wp = session.FinalWorkPlan.filter(f => f.proposal_record_id === id).toModelArray()[0];
        if (wp) {
        return session.AmendmentLog.filter(x => x.work_plan === wp.id)
            .orderBy("id", "desc")
            .toModelArray();
        }
    }
);

const getCollaboratorsAmendment = createSelector(
    (state, ownProps) => ownProps.plan.id,
    (session, id) => {
        return session.FinalProposalCollaborator.filter({ proposal: id }).toRefArray();
    }
);

const getActivitiesAmendment = createSelector(
    (state, ownProps) => ownProps.plan.id,
    (session, id) => {
        return session.FinalProposalActivity.filter({ proposal: id }).toModelArray()
            .map(activity => ({
                ...activity.ref,
                milestones: activity.final_milestone_activities.all().toModelArray()
            }));
    }
);

const getPersonnelAmendment = createSelector(
    (state, ownProps) => ownProps.plan.id,
    (session, id) => {
        return session.FinalProposalPersonnel.filter({ proposal: id }).toModelArray();
    }
);

const getContractsAmendment = createSelector(
    (state, ownProps) => ownProps.plan.id,
    (session, id) => {
        return session.FinalProposalContract.filter({ proposal: id }).toModelArray();
    }
);

const getExpendituresAmendment = createSelector(
    (state, ownProps) => ownProps.plan.id,
    (session, id) => {
        return session.FinalProposalExpenditure.filter({ proposal: id }).toModelArray();
    }
);

const getAcquisitionsAmendment = createSelector(
    (state, ownProps) => ownProps.plan.id,
    (session, id) => {
        return session.FinalProposalAcquisition.filter({ proposal: id }).toModelArray();
    }
);

const getTravelsAmendment = createSelector(
    (state, ownProps) => ownProps.plan.id,
    (session, id) => {
        return session.FinalProposalTravel.filter({ proposal: id }).toModelArray();
    }
);

const getPrintingsAmendment = createSelector(
    (state, ownProps) => ownProps.plan.id,
    (session, id) => {
        return session.FinalProposalPrinting.filter({ proposal: id }).toModelArray();
    }
);

const getExpensesAmendment = createSelector(
    (state, ownProps) => ownProps.plan.id,
    (session, id) => {
        return session.FinalProposalExpense.filter({ proposal: id }).toModelArray();
    }
);

const getFundsAmendment = createSelector(
    (state, ownProps) => ownProps.plan.id,
    (session, id) => {
        return session.FinalProposalNonENRTFFund.filter({ proposal: id }).toModelArray();
    }
);

const getParcelsAmendment = createSelector(
    (state, ownProps) => ownProps.plan.id,
    (session, id) => {
        return session.FinalProposalParcel.filter({ proposal: id }).toRefArray();
    }
);

const getAttachmentsAmendment = createSelector(
    (state, ownProps) => ownProps.plan.id,
    (session, id) => {
        return session.FinalProposalAttachment.filter({ proposal: id }).toRefArray();
    }
);

const styles = theme => ({
    rightAlign: {
        textAlign: "right"
    },
    button: {
        marginRight: theme.spacing.unit * 2
    },
    dialogTitleMargin: {
        marginRight: "48px"
    },
    closeButton: {
        position: "absolute",
        right: 8,
        top: 8
    },
    paddingLeft: {
        paddingLeft: "40px"
    },
    nowrap: {
        whiteSpace: "nowrap"
    },
    deleteWidth: {
        minWidth: 34,
        width: 34,
        marginRight: 8
    },
    tableCellAlignRight: {
        textAlign: "right"
    },
    tableCellAlignCenter: {
        textAlign: "center"
    },
    tableCellNoBorder: {
        border: "none"
    },
    tableCellBold: {
        fontWeight: "Bold",
        color: "#000000"
    },
    centerAlign: {
        textAlign: "center"
    },
    asterisk: {
        whiteSpace: "normal",
        lineHeight: 1.5,
        color: "#212529 !important",
        fontFamily: "Roboto, 'Helvetica Neue', Arial, sans-serif"
    },
    gotoButton: {
        padding: 4
    },
    flex: {
        flex: 1,
        fontWeight: 500,
        fontSize: "1.15rem",
        color: "#495057",
        lineHeight: 1.2
    },
});

class ReviewTab extends Component {
    state = {
        thanksDialog: false,
        thanksDialog2: false,
        isDraft: this.props.match.path === "/draft/workplan/:id"
    };

    componentDidMount() {
        const { authState, handleUnsavedFields } = this.props;
        document.title = "Work Plan: Review / Submit - LCCMR";
        this.props.onRef(this);
        const _this = this;
        // FIXME: react-forms calls formDidUpdate when (if) validation is ran right away
        // making it appear there is a edited field even if there isn't
        // Reset fields to false to not require validation when mounted
        setTimeout(function() {
            handleUnsavedFields(false);
            if (_this.props.appContainer.current) {
                _this.props.appContainer.current.scrollTop();
            }
        }, 1);

        if (authState && authState.user) {
            this.props.reloadListOfModels(["SystemSetting"]);

            // reload proposal
            this.props.ormProposalLoadDetail(this.props.match.params.id);
        }

        this.setState({
            isDraft: this.props.match.path === "/draft/workplan/:id"
        });
    }

    componentWillUnmount() {
        this.props.onRef(undefined);
    }

    updateProposal(values, fromStepper) {
        const { ormParentUpdate, ormProposalUpdate, handleUnsavedFields, getDateTimeStamp, plan, proposal, handlePrev, history, ormParentUpdateLocalOnly } = this.props;
        const { id } = plan;
        const { isDraft } = this.state;

        // See Attachments.js for an explanation of why I'm doing this
        values.map = plan.map;
        values.financial_capacity = plan.financial_capacity;
        values.modify_date_label = plan.modify_date_label;

        handleUnsavedFields(false);

        if (typeof fromStepper.step !== "undefined") {
            // If this is hit updateProposal initiated through a stepper click
            // Set the values of the steps to the one that was clicked.
            values.activeStep = fromStepper["step"];
            values.activeSubStep = fromStepper["subStep"];

            ormParentUpdateLocalOnly({
                id: id,
                activeStep: fromStepper["step"],
                activeSubStep: fromStepper["subStep"]
            });
        } else {
            var newValues = {};
            newValues.update_date = getDateTimeStamp();
            if (!this.state.previousStep) {
                // They've clicked a final submit button
                if (this.state.updateStatus) {
                    const date = new Date();
                    const dateFns = new DateFnsUtils();
                    const stamp = dateFns.format(date, "yyyy-MM-dd HH:mm:ss.SSSSSS");

                    if (isDraft) {
                        if (proposal.status === "Draft Work Plan Due") {
                            newValues["status"] = "Draft Work Plan Submitted";
                            values["status_date"] = stamp;
                        } else if (proposal.status === "Draft Work Plan Revisions Needed") {
                            newValues["status"] = "Draft Work Plan Revisions Submitted";
                            values["status_date"] = stamp;
                        }
                    } else {
                        if (proposal.status === "Final Work Plan Due") {
                            newValues["status"] = "Final Work Plan Submitted";
                            values["status_date"] = stamp;
                        } else if (proposal.status === "Final Work Plan Revisions Needed") {
                            newValues["status"] = "Final Work Plan Revisions Submitted";
                            values["status_date"] = stamp;
                        }
                    }
                }

                // Keep Comments and Revisions tab still with submitted status
                this.props.keepState();

                ormProposalUpdate({
                    id: proposal.id,
                    ...newValues
                });
                ormParentUpdate({
                    id: id,
                    run_spatial: true,
                    ...values
                });
                if (this.state.updateStatus) this.setState({ thanksDialog: true });
                else history.push("/dashboard/");
            } else {
                ormParentUpdate({
                    id: id,
                    ...values
                });
                handlePrev();
            }
        }
    }

    updateProposal2() {
        const { ormProposalUpdate, proposal } = this.props;

        ormProposalUpdate({
            id: proposal.id,
            status: "Final Abstract Submitted"
        });
        this.setState({ thanksDialog: true });

    }

    checkResultsStatusUpdate = status_update => {
        const { status_update_activities, show_parcel, classes } = this.props;
        var missing = [];
        if (!status_update) return null;

        if (!status_update.budget_amount) missing.push(<>Confirm budget amount is correct and up to date<IconButton className={classes.gotoButton} title="Go To Budget Amount Field" onClick={() => this.zoomToField(10, 0, "budget_amount", status_update.id)}><ViewIcon /></IconButton></>);
        if (!status_update.parcel_list && !show_parcel) missing.push(<>Confirm parcel list status is correct and up to date<IconButton className={classes.gotoButton} title="Go To Parcel List Field" onClick={() => this.zoomToField(10, 0, "parcel_list", status_update.id)}><ViewIcon /></IconButton></>);
        if (!status_update.overall_update) missing.push(<>Overall Update missing<IconButton className={classes.gotoButton} title="Go To Overall Update Field" onClick={() => this.zoomToField(10, 0, "overall_update", status_update.id)}><ViewIcon /></IconButton></>);

        var activities = status_update_activities.filter(sua => sua.status_update_id === status_update.id || sua.status_update === status_update.id);
        activities.forEach((a, index) => {
            if (!a.update_text) missing.push(<>Activity {(index + 1)} Update missing<IconButton className={classes.gotoButton} title="Go To Activity Update Field" onClick={() => this.zoomToField(10, 0, "update_text", status_update.id, a.id)}><ViewIcon /></IconButton></>);
        });
        if (!status_update.dissemination_update) missing.push(<>Dissemination Update missing<IconButton className={classes.gotoButton} title="Go To Dissemination Update Field" onClick={() => this.zoomToField(10, 0, "dissemination_update", status_update.id)}><ViewIcon /></IconButton></>);

        if (missing.length > 0) {
            return (
                <>
                    <span>{status_update.start_date}: The following fields are required. Please complete these before submitting.</span>
                    <ul>
                        {missing.map(m => (
                            <li>{m}</li>
                        ))}
                    </ul>
                </>
            );
        }
        return null;
    };

    zoomToField = (step, substep, field, activity_id, milestone_id) => {
        const { ormParentUpdateLocalOnly, plan } = this.props;
        ormParentUpdateLocalOnly({
            id: plan.id,
            activeStep: step,
            activeSubStep: substep,
            highlightedField: field,
            fromReview: true,
            review_activity: activity_id,
            review_milestone: milestone_id
        });
    }

    checkResults = (is_amendment) => {
        const { plan, proposal, activities, parcels, authState, amendments, classes, contracts, proposaltravels } = this.props;
        const _this = this;
        const check_amount = proposal.appropriation_amount !== null ? proposal.appropriation_amount : proposal.recommended_amount;
        const amount_str = proposal.appropriation_amount !== null ? "appropriated" : "recommended";

        var missing = [];
        const recipient = getValue(plan, "org_data.recipient");
        const org_name = getValue(plan, "org_data.organization_name");
        const ml_year = getValue(plan, "ml_year");
        const Easement = getValue(plan, "land_rights").indexOf("Easement (Other) Acquisition") > -1;
        const Fee = getValue(plan, "land_rights").indexOf("Fee Acquisition") > -1;
        var isOrgUofMN = false;
        if (
            plan.org_data &&
            plan.org_data.organization_name &&
            plan.org_data.organization_name.includes("U of MN")
        ) {
            isOrgUofMN = true;
        }
        //2_1
        if (!plan.pm_first_name) missing.push(<>Project Manager First Name (Project Collaborators -> Project Manager Info)<IconButton className={classes.gotoButton} title="Go To Project Manager First Name Field" onClick={() => this.zoomToField(1, 0, "pm_first_name")}><ViewIcon /></IconButton></>);
        if (!plan.pm_last_name) missing.push(<>Project Manager Last Name (Project Collaborators -> Project Manager Info)<IconButton className={classes.gotoButton} title="Go To Project Manager Last Name Field" onClick={() => this.zoomToField(1, 0, "pm_last_name")}><ViewIcon /></IconButton></>);
        if (plan.pm_first_name !== authState.user.first_name || plan.pm_last_name !== authState.user.last_name)
            missing.push(<>The person submitting this proposal must be the project manager. Your account name ({authState.user.first_name} {authState.user.last_name}) does not match the project manager's name ({proposal.pm_first_name} {proposal.pm_last_name}) (Project Collaborators -> Project Manager Info)<IconButton className={classes.gotoButton} title="Go To Project Manager First Name Field" onClick={() => this.zoomToField(1, 0, "pm_first_name")}><ViewIcon /></IconButton></>);
        if (!plan.pm_office_phone) missing.push(<>Project Manager Office Phone (Project Collaborators -> Project Manager Info)<IconButton className={classes.gotoButton} title="Go To Project Manager Office Phone Field" onClick={() => this.zoomToField(1, 0, "pm_office_phone")}><ViewIcon /></IconButton></>);
        if (!plan.pm_email) missing.push(<>Project Manager Email (Project Collaborators -> Project Manager Info)<IconButton className={classes.gotoButton} title="Go To Project Manager Email Field" onClick={() => this.zoomToField(1, 0, "pm_email")}><ViewIcon /></IconButton></>);
        if (!plan.pm_mailing_address) missing.push(<>Project Manager Mailing Address (Project Collaborators -> Project Manager Info)<IconButton className={classes.gotoButton} title="Go To Project Manager Mailing Address Field" onClick={() => this.zoomToField(1, 0, "pm_mailing_address")}><ViewIcon /></IconButton></>);
        if (!plan.pm_city) missing.push(<>Project Manager City (Project Collaborators -> Project Manager Info)<IconButton className={classes.gotoButton} title="Go To Project Manager City Field" onClick={() => this.zoomToField(1, 0, "pm_city")}><ViewIcon /></IconButton></>);
        if (!plan.pm_state) missing.push(<>Project Manager State (Project Collaborators -> Project Manager Info)<IconButton className={classes.gotoButton} title="Go To Project Manager State Field" onClick={() => this.zoomToField(1, 0, "pm_state")}><ViewIcon /></IconButton></>);
        if (!plan.pm_zip) missing.push(<>Project Manager Zip Code (Project Collaborators -> Project Manager Info)<IconButton className={classes.gotoButton} title="Go To Project Manager Zip Code Field" onClick={() => this.zoomToField(1, 0, "pm_zip")}><ViewIcon /></IconButton></>);
        if (!plan.pm_job_title) missing.push(<>Project Manager Job Title (Project Collaborators -> Project Manager Info)<IconButton className={classes.gotoButton} title="Go To Project Manager Job Title Field" onClick={() => this.zoomToField(1, 0, "pm_job_title")}><ViewIcon /></IconButton></>);
        //2_2
        if (!plan.organization) missing.push(<>Organization (Project Collaborators -> Organization Info)<IconButton className={classes.gotoButton} title="Go To Organization Field" onClick={() => this.zoomToField(1, 1, "organization")}><ViewIcon /></IconButton></>);
        if (!plan.org_description) missing.push(<>Organization Description (Project Collaborators -> Organization Info)<IconButton className={classes.gotoButton} title="Go To Organization Description Field" onClick={() => this.zoomToField(1, 1, "org_description")}><ViewIcon /></IconButton></>);

        if (recipient !== "State Government" && recipient !== "Federal Government") {
            if (plan.has_fiscal_agent !== true && plan.has_fiscal_agent !== false)
                missing.push(<>Does Organization Have Fiscal Agent (Project Collaborators -> Organization Info)<IconButton className={classes.gotoButton} title="Go To Does Organization Have Fiscal Agent Field" onClick={() => this.zoomToField(1, 1, "has_fiscal_agent")}><ViewIcon /></IconButton></>); // !proposal.has_fiscal_agent will miss false ("No")
            if (plan.has_fiscal_agent === true) {
                if (!plan.fiscal_agent) missing.push(<>Fiscal Agent (Project Collaborators -> Organization Info)<IconButton className={classes.gotoButton} title="Go To Fiscal Agent Field" onClick={() => this.zoomToField(1, 1, "fiscal_agent")}><ViewIcon /></IconButton></>);
                if (!plan.agent_contact_name) missing.push(<>Fiscal Agent Contact Name (Project Collaborators -> Organization Info)<IconButton className={classes.gotoButton} title="Go To Fiscal Agent Contact Name Field" onClick={() => this.zoomToField(1, 1, "agent_contact_name")}><ViewIcon /></IconButton></>);
                if (!plan.agent_contact_phone) missing.push(<>Fiscal Agent Contact Phone (Project Collaborators -> Organization Info)<IconButton className={classes.gotoButton} title="Go To Fiscal Agent Contact Phone Field" onClick={() => this.zoomToField(1, 1, "agent_contact_email")}><ViewIcon /></IconButton></>);
                if (!plan.agent_contact_email) missing.push(<>Fiscal Agent Contact Email (Project Collaborators -> Organization Info)<IconButton className={classes.gotoButton} title="Go To Fiscal Agent Contact Email Field" onClick={() => this.zoomToField(1, 1, "agent_contact_phone")}><ViewIcon /></IconButton></>);
            }
        }
        //2_3
        // These are checked directly in the dialog
        //3
        if (!plan.name) missing.push(<>Project Title (General Information)<IconButton className={classes.gotoButton} title="Go To Project Title Field" onClick={() => this.zoomToField(2, 0, "name")}><ViewIcon /></IconButton></>);
        if (!plan.funding_category) missing.push(<>LCCMR Funding Category (General Information)<IconButton className={classes.gotoButton} title="Go To LCCMR Funding Category Field" onClick={() => this.zoomToField(2, 0, "funding_category")}><ViewIcon /></IconButton></>);
        if (plan.funding_category === "Small Projects") {
            if (!plan.secondary_funding_category) missing.push(<>Seconday LCCMR Funding Category (General Information)<IconButton className={classes.gotoButton} title="Go To Seconday LCCMR Funding Category Field" onClick={() => this.zoomToField(2, 0, "secondary_funding_category")}><ViewIcon /></IconButton></>);
        }

        // land_rights & patent_royalty_revenue_potential (checkboxes might actually be none)
        if (plan.patent_royalty_revenue_potential.length === 0) {
            missing.push(<>Does your project have potential for royalties, copyrights, patents, sale of products and assets, or revenue generation? (General Information)<IconButton className={classes.gotoButton} title="Go To Does your project have potential for royalties, copyrights, patents, sale of products and assets, or revenue generation? Field" onClick={() => this.zoomToField(2, 0, "patent_royalty_revenue_potential")}><ViewIcon /></IconButton></>);
        } else if (plan.patent_royalty_revenue_potential.length === 2) {
            if (plan.understand_revenue && !plan.reinvestment_description) {
                missing.push(<>Description of revenues intended to be reinvested into project (General Information)<IconButton className={classes.gotoButton} title="Go To Description of revenues intended to be reinvested into project Field" onClick={() => this.zoomToField(2, 0, "reinvestment_description")}><ViewIcon /></IconButton></>);
            }
        }
        
        if (ml_year >= 2024) {
            if (plan.asset_tenthousand !== true && plan.asset_tenthousand !== false) {
                missing.push(<>Does your project include the pre-design, design, construction, or renovation of a building, trail, campground, or other fixed capital asset costing $10,000 or more or large-scale stream or wetland restoration? (General Information)<IconButton className={classes.gotoButton} title="Go To Does your project include the pre-design, design, construction, or renovation of a building, trail, campground, or other fixed capital asset costing $10,000 or more or large-scale stream or wetland restoration? Field" onClick={() => this.zoomToField(2, 0, "asset_tenthousand")}><ViewIcon /></IconButton></>);
            }
            if (Fee || Easement) {
                if (plan.acquisition_tenthousand !== true && plan.acquisition_tenthousand !== false) {
                    missing.push(<>Is the purpose of the acquisition for construction of a building(s), trail, campground, or other fixed capital asset costing $10,000 or more or large- scale stream or wetland restoration, either now or in the future? (General Information)<IconButton className={classes.gotoButton} title="Go To Is the purpose of the acquisition for construction of a building(s), trail, campground, or other fixed capital asset costing $10,000 or more or large- scale stream or wetland restoration, either now or in the future? Field" onClick={() => this.zoomToField(2, 0, "acquisition_tenthousand")}><ViewIcon /></IconButton></>);
                }
            }
            if (plan.children_services !== true && plan.children_services !== false) {
                missing.push(<>Do you propose using an appropriation from the Environment and Natural Resources Trust Fund to conduct a project that provides children's services, as defined in Minnesota Statutes section 299C.61 Subd.7? (General Information)<IconButton className={classes.gotoButton} title="Go To Do you propose using an appropriation from the Environment and Natural Resources Trust Fund to conduct a project that provides children's services, as defined in Minnesota Statutes section 299C.61 Subd.7? Field" onClick={() => this.zoomToField(2, 0, "children_services")}><ViewIcon /></IconButton></>);
            }
            if (plan.children_services === true ) {
                if (plan.background_checks !== true && plan.background_checks !== false) {
                    missing.push(<>Do you certify that background checks are performed for background check crimes, as defined in Minnesota Statutes, section 299C.61, Subd. 2, on all employees, contractors, and volunteers who have or may have access to a child to whom children's services are provided by your organization? (General Information)<IconButton className={classes.gotoButton} title="Go To Do you certify that background checks are performed for background check crimes, as defined in Minnesota Statutes, section 299C.61, Subd. 2, on all employees, contractors, and volunteers who have or may have access to a child to whom children's services are provided by your organization? Field" onClick={() => this.zoomToField(2, 0, "background_checks")}><ViewIcon /></IconButton></>);
                }
            }
        }

        if (!plan.location_scale) missing.push(<>Project Location (General Information)<IconButton className={classes.gotoButton} title="Go To Project Location Field" onClick={() => this.zoomToField(2, 0, "location_scale")}><ViewIcon /></IconButton></>);
        if (plan.location_scale && plan.location_scale !== "Statewide") {
            if (!plan.location_areas.length === 0) missing.push(<>Project Location Areas (General Information)<IconButton className={classes.gotoButton} title="Go To Project Location Areas Field" onClick={() => this.zoomToField(2, 0, "location_areas")}><ViewIcon /></IconButton></>);
        }
        if (!plan.impact_scale) missing.push(<>Project Area Impacted (General Information)<IconButton className={classes.gotoButton} title="Go To Project Area Impacted Field" onClick={() => this.zoomToField(2, 0, "impact_scale")}><ViewIcon /></IconButton></>);
        if (plan.impact_scale && plan.impact_scale !== "Statewide") {
            if (!plan.impact_areas.length === 0) missing.push(<>Project Area Impacted Areas (General Information)<IconButton className={classes.gotoButton} title="Go To Project Area Impacted Areas Field" onClick={() => this.zoomToField(2, 0, "impact_areas")}><ViewIcon /></IconButton></>);
        }
        // impact_occur (checkboxes might actually be none)
        // Other ENRTF Appropriations Awarded in the Last Six Years (optional?)
        // 4
        if (!plan.summary) missing.push(<>Project Summary (Narrative)<IconButton className={classes.gotoButton} title="Go To Project Summary Field" onClick={() => this.zoomToField(3, 0, "summary")}><ViewIcon /></IconButton></>);
        if (!plan.opportunity) missing.push(<>Opportunity or Problem Proposal Addresses (Narrative)<IconButton className={classes.gotoButton} title="Go To Opportunity or Problem Proposal Addresses Field" onClick={() => this.zoomToField(3, 0, "opportunity")}><ViewIcon /></IconButton></>);
        if (!plan.solution) missing.push(<>Proposed Solution (Narrative)<IconButton className={classes.gotoButton} title="Go To Proposed Solution Field" onClick={() => this.zoomToField(3, 0, "solution")}><ViewIcon /></IconButton></>);
        if (!plan.outcome) missing.push(<>Specific Project Outcomes (Narrative)<IconButton className={classes.gotoButton} title="Go To Specific Project Outcomes Field" onClick={() => this.zoomToField(3, 0, "outcome")}><ViewIcon /></IconButton></>);
        if (!plan.timeline) missing.push(<>Timeline (Narrative)<IconButton className={classes.gotoButton} title="Go To Timeline Field" onClick={() => this.zoomToField(3, 0, "timeline")}><ViewIcon /></IconButton></>);
        if (!plan.longterm_implementation) missing.push(<>Long-Term Implementation and Funding (Narrative)<IconButton className={classes.gotoButton} title="Go To Long-Term Implementation and Funding Field" onClick={() => this.zoomToField(3, 0, "longterm_implementation")}><ViewIcon /></IconButton></>);
        // 5
        if (activities.length === 0) {
            if (!plan.timeline) missing.push("Your project must have at least one activity (Activities and Milestones)");
        }
        var budget_total = 0;
        activities.forEach(function(a) {
            var temptitle = a.title ? a.title : "An activity";
            if (!a.title) missing.push(<>{temptitle} is missing a Title (Activities and Milestones)<IconButton className={classes.gotoButton} title="Go To Title Field" onClick={() => _this.zoomToField(4, 0, "title", a.id)}><ViewIcon /></IconButton></>);
            if (!a.description) missing.push(<>{temptitle} is missing a Description (Activities and Milestones)<IconButton className={classes.gotoButton} title="Go To Description Field" onClick={() => _this.zoomToField(4, 0, "description", a.id)}><ViewIcon /></IconButton></>);
            if (!a.budget) missing.push(<>{temptitle} is missing a ENRTF Budget (Activities and Milestones)<IconButton className={classes.gotoButton} title="Go To ENRTF Budget Field" onClick={() => _this.zoomToField(4, 0, "budget", a.id)}><ViewIcon /></IconButton></>);
            budget_total += a.budget;
            if (a.milestones.length === 0) missing.push(<>{temptitle} must contain at least one milestone (Activities and Milestones)<IconButton className={classes.gotoButton} title="Go To Button" aria-label="Go To Milestone Button" onClick={() => _this.zoomToField(4, 0, "milestone_button", a.id)}><ViewIcon /></IconButton></>);
            a.milestones.forEach(function(m) {
                if (m.completion_date > plan.timeline)
                    missing.push(<>{temptitle} has a milestone completion date that is past the project timeline date (Activities and Milestones)<IconButton className={classes.gotoButton} title="Go To Table" aria-label="Go To Milestone Table" onClick={() => _this.zoomToField(4, 0, "milestone_table", a.id, m.id)}><ViewIcon /></IconButton></>);
            });
        });
        //6
        if (ml_year >= 2022) {
            contracts.forEach(function(a) {
                if (!a.understand_service) {
                    missing.push(<>{a.entity} is missing an acknowledgment (Budget -> Services and Subawards)<IconButton className={classes.gotoButton} title="Go To Services and Subawards" onClick={() => _this.zoomToField(5, 1, "entity", a.id)}><ViewIcon /></IconButton></>);
                }
            })
        }
        proposaltravels.forEach(function(a) {
            if ((isOrgUofMN && !a.understand_umn_policy) || (!isOrgUofMN && !a.understand_commissioner_plan)) {
                missing.push(<>{a.type} {a.category} is missing an acknowledgment (Budget -> Travel and Conferences)<IconButton className={classes.gotoButton} title="Go To Services and Subawards" onClick={() => _this.zoomToField(5, 4, "type", a.id)}><ViewIcon /></IconButton></>);
            }
        })
        // It seems that every budget thing is required already in the budget dialog
        // Just check if the value is rounded to the thousandth
        var is_thousand = plan.funding_total % 1000 === 0;
        if (!is_thousand)
            missing.push(<>The Total ENRTF Funds Requested Amount is not a round thousand dollars. Currently the total is ${plan.funding_total.toLocaleString()}. (Budget)<IconButton className={classes.gotoButton} title="Go To Table" aria-label="Go To Budget Summary Table" onClick={() => _this.zoomToField(5, 8, "_no")}><ViewIcon /></IconButton></>);
        if (check_amount === null) {
            missing.push("Recommended amount is not set (contact staff)");
        }
        if (proposal.appropriation_amount === null && check_amount === 0) {
            missing.push("Recommended amount is not set (contact staff)");
        }
        if (check_amount !== null && budget_total !== check_amount) {
            missing.push(<>The activity budget total of ${(budget_total ? budget_total.toLocaleString() : "0".toLocaleString())} does not match the {amount_str} amount of ${check_amount.toLocaleString()}. (Activities and Milestones)<IconButton className={classes.gotoButton} title="Go To Activity" aria-label="Go To Activity and Milestones Page" onClick={() => _this.zoomToField(4, 0, "_no")}><ViewIcon /></IconButton></>);
        }
        if (check_amount !== null && (plan.funding_total == null || (plan.funding_total && plan.funding_total !== check_amount))) {
            missing.push(<>The budget total of ${(plan.funding_total ? plan.funding_total.toLocaleString() : "0".toLocaleString())} does not match the {amount_str} amount of ${check_amount.toLocaleString()}. (Budget)<IconButton className={classes.gotoButton} title="Go To Table" aria-label="Go To Budget Summary Table" onClick={() => _this.zoomToField(5, 8, "_no")}><ViewIcon /></IconButton></>);
        }
        if (!plan.accurate && ml_year >= 2025) {
            missing.push(<>Total Project Cost accurately reflects total project costs? (Budget Summary)<IconButton className={classes.gotoButton} title="Go To Table" aria-label="Go To Budget Summary Table" onClick={() => _this.zoomToField(5, 8, "_no")}><ViewIcon /></IconButton></>);
        }

        //7
        // Only check for attachment fields not in a table
        // Assume they may have 0 or many attachments in the tables
        // The table fields should be checked for in the dialog on save
        if (plan.map === null || plan.map === "") missing.push(<>Map or Visual Component (Attachments)<IconButton className={classes.gotoButton} title="Go To Map or Visual Component Field" onClick={() => this.zoomToField(6, 0, "map")}><ViewIcon /></IconButton></>);
        if (plan.map_description === null || plan.map_description === "") missing.push(<>Map or Visual Component Description (Attachments)<IconButton className={classes.gotoButton} title="Go To Map or Visual Component Description Field" onClick={() => this.zoomToField(6, 0, "map_description")}><ViewIcon /></IconButton></>);
        
        if (ml_year <= 2024) {
            if (
                (recipient === "For-Profit Business/Entity" || recipient === "Private College/University" || recipient === "Non-Profit Business/Entity") &&
                (plan.financial_capacity === null || plan.financial_capacity === "")
            )
                missing.push(<>Financial Capacity Information (Attachments)<IconButton className={classes.gotoButton} title="Go To Financial Capacity Information Field" onClick={() => this.zoomToField(6, 0, "financial_capacity")}><ViewIcon /></IconButton></>);

        } else {
            if (
                (recipient === "For-Profit Business/Entity" || recipient === "Private College/University"|| recipient === "Local/Regional Government" || recipient === "Non-Profit Business/Entity") &&
                (plan.financial_capacity === null || plan.financial_capacity === "") && this.props.financialAttachment === 0
            )
                missing.push(<>Financial Capacity Information (Attachments)<IconButton className={classes.gotoButton} title="Go To Financial Capacity Information Field" onClick={() => this.zoomToField(6, 0, "financial_capacity")}><ViewIcon /></IconButton></>);
        }
        


        const board_resolution =
            recipient === "Tribal" ||
            recipient === "Federal Government" ||
            recipient === "Local/Regional Government" ||
            recipient === "K-12 Education" ||
            recipient === "Non-Profit Business/Entity" ||
            (ml_year >= 2024 && org_name && org_name.includes("U of MN")) ||
            (ml_year >= 2026 && recipient === "For-Profit Business/Entity");

        if (board_resolution && this.props.boardAttachment === 0) {
            missing.push(<>Board Resolution or Letter (Attachments)<IconButton className={classes.gotoButton} title="Go To Board Resolution or Letter Field" onClick={() => this.zoomToField(6, 0, "board_resolution")}><ViewIcon /></IconButton></>);
        }

        // 8
        // Must have selected some land rights to have access
        const Restoration = getValue(plan, "land_rights").indexOf("Restoration") > -1;
        const Conservation = getValue(plan, "land_rights").indexOf("Conservation Easement Acquisition") > -1;
        if (Restoration || Conservation || Easement || Fee) {
            // They must have at least 1 parcel
            if (parcels.length === 0) missing.push(<>This project must have at least one parcel (Acquisition and Restoration -> Parcel List)<IconButton className={classes.gotoButton} title="Go To Table" aria-label="Go To Parcel Table" onClick={() => this.zoomToField(7, 0, "parcel_table")}><ViewIcon /></IconButton></>);
            if (Fee) {
                if (!plan.fee_selection_process) missing.push(<>Selection Process (Acquisition and Restoration -> Fee Acquisition)<IconButton className={classes.gotoButton} title="Go To Selection Process Field" onClick={() => this.zoomToField(7, 1, "fee_selection_process")}><ViewIcon /></IconButton></>);
                if (!plan.fee_resource_plans) missing.push(<>Natural Resource Plans (Acquisition and Restoration -> Fee Acquisition)<IconButton className={classes.gotoButton} title="Go To Natural Resource Plans Field" onClick={() => this.zoomToField(7, 1, "fee_resource_plans")}><ViewIcon /></IconButton></>);
                if (!plan.fee_management_plans) missing.push(<>Restoration and Management Plans (Acquisition and Restoration -> Fee Acquisition)<IconButton className={classes.gotoButton} title="Go To Restoration and Management Plans Field" onClick={() => this.zoomToField(7, 1, "fee_management_plans")}><ViewIcon /></IconButton></>);
                if (!plan.fee_county_board_approval) missing.push(<>County Board Approval (Acquisition and Restoration -> Fee Acquisition)<IconButton className={classes.gotoButton} title="Go To County Board Approval Field" onClick={() => this.zoomToField(7, 1, "fee_county_board_approval")}><ViewIcon /></IconButton></>);
            }
            if (Conservation) {
                if (!plan.cea_selection_process) missing.push(<>Selection Process (Acquisition and Restoration -> Conservation Easement Acquisition)<IconButton className={classes.gotoButton} title="Go To Selection Process Field" onClick={() => this.zoomToField(7, 2, "cea_selection_process")}><ViewIcon /></IconButton></>);
                if (!plan.cea_resource_plans) missing.push(<>Natural Resource Plans (Acquisition and Restoration -> Conservation Easement Acquisition)<IconButton className={classes.gotoButton} title="Go To Natural Resource Plans Field" onClick={() => this.zoomToField(7, 2, "cea_resource_plans")}><ViewIcon /></IconButton></>);
                if (!plan.cea_management_plans)
                    missing.push(<>Restoration and Management Plans (Acquisition and Restoration -> Conservation Easement Acquisition)<IconButton className={classes.gotoButton} title="Go To Restoration and Management Plans Field" onClick={() => this.zoomToField(7, 2, "cea_management_plans")}><ViewIcon /></IconButton></>);
                if (!plan.cea_county_board_approval)
                    missing.push(<>County Board Approval (Acquisition and Restoration -> Conservation Easement Acquisition)<IconButton className={classes.gotoButton} title="Go To County Board Approval Field" onClick={() => this.zoomToField(7, 2, "cea_county_board_approval")}><ViewIcon /></IconButton></>);
                if (!plan.cea_water_quality_protection)
                    missing.push(<>Water Quality Projection (Acquisition and Restoration -> Conservation Easement Acquisition)<IconButton className={classes.gotoButton} title="Go To Water Quality Projection Field" onClick={() => this.zoomToField(7, 2, "cea_water_quality_protection")}><ViewIcon /></IconButton></>);
                if (!plan.cea_monitoring_program)
                    missing.push(<>Long Term Programs for Conservation Easements (Acquisition and Restoration -> Conservation Easement Acquisition)<IconButton className={classes.gotoButton} title="Go To Long Term Programs for Conservation Easements Field" onClick={() => this.zoomToField(7, 2, "cea_monitoring_program")}><ViewIcon /></IconButton></>);
            }
            if (Easement) {
                if (!plan.eao_selection_process) missing.push(<>Selection Process (Acquisition and Restoration -> Easement Acquisition (Other))<IconButton className={classes.gotoButton} title="Go To Selection Process Field" onClick={() => this.zoomToField(7, 3, "eao_selection_process")}><ViewIcon /></IconButton></>);
                if (!plan.eao_resource_plans) missing.push(<>Natural Resource Plans (Acquisition and Restoration -> Easement Acquisition (Other))<IconButton className={classes.gotoButton} title="Go To Natural Resource Plans Field" onClick={() => this.zoomToField(7, 3, "eao_resource_plans")}><ViewIcon /></IconButton></>);
                if (!plan.eao_management_plans)
                    missing.push(<>Restoration and Management Plans (Acquisition and Restoration -> Easement Acquisition (Other))<IconButton className={classes.gotoButton} title="Go To Restoration and Management Plans Field" onClick={() => this.zoomToField(7, 3, "eao_management_plans")}><ViewIcon /></IconButton></>);
                if (!plan.eao_county_board_approval) missing.push(<>County Board Approval (Acquisition and Restoration -> Easement Acquisition (Other))<IconButton className={classes.gotoButton} title="Go To County Board Approval Field" onClick={() => this.zoomToField(7, 3, "eao_county_board_approval")}><ViewIcon /></IconButton></>);
            }
            if (Restoration) {
                if (!plan.restoration_permanent_protection)
                    missing.push(<>Activities Will Occur On Land Permanently Protected (Acquisition and Restoration -> Restoration)<IconButton className={classes.gotoButton} title="Go To Activities Will Occur On Land Permanently Protected Field" onClick={() => this.zoomToField(7, 4, "restoration_permanent_protection")}><ViewIcon /></IconButton></>);
                if (!plan.restoration_management_plans) missing.push(<>Restoration and Management Plans (Acquisition and Restoration -> Restoration)<IconButton className={classes.gotoButton} title="Go To Restoration and Management Plans Field" onClick={() => this.zoomToField(7, 4, "restoration_management_plans")}><ViewIcon /></IconButton></>);
                if (!plan.restoration_nveeg)
                    missing.push(<>Native Vegetation Establishment and Enhancement Guidelines (Acquisition and Restoration -> Restoration)<IconButton className={classes.gotoButton} title="Go To Native Vegetation Establishment and Enhancement Guidelines Field" onClick={() => this.zoomToField(7, 4, "restoration_nveeg")}><ViewIcon /></IconButton></>);
                if (!plan.restoration_longterm_needs) missing.push(<>Long Term Maintenance Needs (Acquisition and Restoration -> Restoration)<IconButton className={classes.gotoButton} title="Go To Long Term Maintenance Needs Field" onClick={() => this.zoomToField(7, 4, "restoration_longterm_needs")}><ViewIcon /></IconButton></>);
                if (!plan.restoration_ccm) missing.push(<>Consideration for Conservation Corps of Minnesota (Acquisition and Restoration -> Restoration)<IconButton className={classes.gotoButton} title="Go To Consideration for Conservation Corps of Minnesota Field" onClick={() => this.zoomToField(7, 4, "restoration_ccm")}><ViewIcon /></IconButton></>);
                if (!plan.restoration_evaluations) missing.push(<>Evaluations On Completed Parcels (Acquisition and Restoration -> Restoration)<IconButton className={classes.gotoButton} title="Go To Evaluations On Completed Parcels Field" onClick={() => this.zoomToField(7, 4, "restoration_evaluations")}><ViewIcon /></IconButton></>);
            }
        }
        if (!plan.dissemination) missing.push(<>Dissemination Efforts (Dissemination)<IconButton className={classes.gotoButton} title="Go To Dissemination Efforts Field" onClick={() => this.zoomToField(8, 0, "dissemination")}><ViewIcon /></IconButton></>);

        if (!plan.authorized) missing.push("Authorized to Submit (Review / Submit)");
        if (!plan.major_contributors && ml_year >= 2025) missing.push("Individuals assisting in the completion (Review / Submit)");
        if (this.form && !this.form.getValue("changes")) missing.push("Changes made from proposal to work plan (Review / Submit)");

        if (is_amendment && is_amendment !== "Amendment Approved") {
            var in_progress_amendment = amendments.filter(su => !su.date_accepted);
            if (!in_progress_amendment[0].justification)
                missing.push(<>Explanation & Justification for Amendment Request (Reporting -> Amendments)<IconButton className={classes.gotoButton} title="Go To Justification Field" onClick={() => this.zoomToField(10, 1, "justification")}><ViewIcon /></IconButton></>);
            else {
                if (in_progress_amendment[0].justification.split(" ").length < 30)
                missing.push(<>Explanation & Justification for Amendment Request needs to be a minimum of 30 words (Reporting -> Amendments)<IconButton className={classes.gotoButton} title="Go To Justification Field" onClick={() => this.zoomToField(10, 1, "justification")}><ViewIcon /></IconButton></>);
            }
        }

        if (missing.length > 0) {
            return (
                <>
                    <span>The following fields are required. Please complete these before submitting.</span>
                    <ul>
                        {missing.map(m => (
                            <li>{m}</li>
                        ))}
                    </ul>
                </>
            );
        }
        return null;
    };

    checkResultsAbstract = () => {
        const { plan, classes } = this.props;

        var missing = [];
        //2_1
        if (!plan.project_outcomes_short) missing.push(<>Sound Bite of Project Outcomes and Results<IconButton className={classes.gotoButton} title="Go To Sound Bite of Project Outcomes and Results Field" onClick={() => this.zoomToField(10, 2, "project_outcomes_short")}><ViewIcon /></IconButton></>);
        if (!plan.project_outcomes_long) missing.push(<>Overall Project Outcomes and Results<IconButton className={classes.gotoButton} title="Go To Overall Project Outcomes and Results Field" onClick={() => this.zoomToField(10, 2, "project_outcomes_long")}><ViewIcon /></IconButton></>);
        if (!plan.project_results) missing.push(<>Project Results Use and Dissemination<IconButton className={classes.gotoButton} title="Go To Project Results Use and Dissemination Field" onClick={() => this.zoomToField(10, 2, "project_results")}><ViewIcon /></IconButton></>);


        if (missing.length > 0) {
            return (
                <>
                    <span>The following fields are required. Please complete these before submitting.</span>
                    <ul>
                        {missing.map(m => (
                            <li>{m}</li>
                        ))}
                    </ul>
                </>
            );
        }
        return null;
    };

    applyOther = value => {
        const { ormParentUpdateLocalOnly, plan } = this.props;
        const { id } = plan;
        ormParentUpdateLocalOnly({
            id: id,
            authorized: value
        });
        this.setState({ foo: "bar" });
    };

    handleClose = async () => {
        const { history, ormFinalWorkPlanReload } = this.props;
        this.setState({ thanksDialog: false, thanksDialog2: false });

        // Resets active and sub step so when coming back to page after submitting it's not suck.
        this.props.handleStep(0, false);
        await ormFinalWorkPlanReload();
        history.push("/dashboard/");
    };

    updateStatusUpdate(status_update) {
        const { ormStatusUpdateUpdate } = this.props;

        var d = new Date();
        status_update.submitted_date = d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + d.getDate();

        ormStatusUpdateUpdate({
            id: status_update.id,
            ...status_update
        });
        this.setState({ thanksDialog2: true });
    }

    updateAmendment() {
        const { 
            ormParentUpdate, plan, amendments, collaborators_amendment, activities_amendment, personnel_amendment, contracts_amendment, expenditures_amendment,
            acquisitions_amendment, travels_amendment, printings_amendment, expenses_amendment, funds_amendment, parcels_amendment, attachments_amendment, ormAmendmentLogUpdate
        } = this.props;

        var in_progress_amendment = amendments.filter(su => !su.date_accepted);

        var detected_changes = []
        if (plan.detected_changes) {
            plan.detected_changes.forEach(dc => {
                if (!detected_changes.includes(dc.tab)) 
                    detected_changes.push(dc.tab)
            });
        }
        collaborators_amendment.forEach(a => {
            if (a.detected_changes) {
                a.detected_changes.forEach(dc => {
                    if (!detected_changes.includes(dc.tab)) 
                        detected_changes.push(dc.tab)
                });
            }
        })
        activities_amendment.forEach(a => {
            if (a.detected_changes) {
                a.detected_changes.forEach(dc => {
                    if (!detected_changes.includes(dc.tab)) 
                        detected_changes.push(dc.tab)
                });
            }
            a.milestones.forEach(b => {
                if (b.detected_changes) {
                    b.detected_changes.forEach(dc => {
                        if (!detected_changes.includes(dc.tab)) 
                            detected_changes.push(dc.tab)
                    });
                }
            })
        })
        personnel_amendment.forEach(a => {
            if (a.detected_changes) {
                a.detected_changes.forEach(dc => {
                    if (!detected_changes.includes(dc.tab)) 
                        detected_changes.push(dc.tab)
                });
            }
        })
        contracts_amendment.forEach(a => {
            if (a.detected_changes) {
                a.detected_changes.forEach(dc => {
                    if (!detected_changes.includes(dc.tab)) 
                        detected_changes.push(dc.tab)
                });
            }
        })
        expenditures_amendment.forEach(a => {
            if (a.detected_changes) {
                a.detected_changes.forEach(dc => {
                    if (!detected_changes.includes(dc.tab)) 
                        detected_changes.push(dc.tab)
                });
            }
        })
        acquisitions_amendment.forEach(a => {
            if (a.detected_changes) {
                a.detected_changes.forEach(dc => {
                    if (!detected_changes.includes(dc.tab)) 
                        detected_changes.push(dc.tab)
                });
            }
        })
        travels_amendment.forEach(a => {
            if (a.detected_changes) {
                a.detected_changes.forEach(dc => {
                    if (!detected_changes.includes(dc.tab)) 
                        detected_changes.push(dc.tab)
                });
            }
        })
        printings_amendment.forEach(a => {
            if (a.detected_changes) {
                a.detected_changes.forEach(dc => {
                    if (!detected_changes.includes(dc.tab)) 
                        detected_changes.push(dc.tab)
                });
            }
        })
        expenses_amendment.forEach(a => {
            if (a.detected_changes) {
                a.detected_changes.forEach(dc => {
                    if (!detected_changes.includes(dc.tab)) 
                        detected_changes.push(dc.tab)
                });
            }
        })
        funds_amendment.forEach(a => {
            if (a.detected_changes) {
                a.detected_changes.forEach(dc => {
                    if (!detected_changes.includes(dc.tab)) 
                        detected_changes.push(dc.tab)
                });
            }
        })
        attachments_amendment.forEach(a => {
            if (a.detected_changes) {
                a.detected_changes.forEach(dc => {
                    if (!detected_changes.includes(dc.tab)) 
                        detected_changes.push(dc.tab)
                });
            }
        })
        parcels_amendment.forEach(a => {
            if (a.detected_changes) {
                a.detected_changes.forEach(dc => {
                    if (!detected_changes.includes(dc.tab)) 
                        detected_changes.push(dc.tab)
                });
            }
        })
        
        const date = new Date();
        const dateFns = new DateFnsUtils();
        const stamp = dateFns.format(date, "yyyy-MM-dd HH:mm:ss.SSSSSS");

        var string = "<ul>"; 
        detected_changes.forEach(dc => string += "<li>" + dc + "</li>"); 
        string += "</ul>";

        ormAmendmentLogUpdate({
            id: in_progress_amendment[0].id,
            date_submitted: stamp,
            changes_detected: string
        })

        var status = "Amendment Submitted";
        if (plan.status === "Amendment Revisions Needed")
            status = "Amendment Revisions Submitted";
        
        ormParentUpdate({
            id: plan.id,
            status: status
        });

        this.setState({ thanksDialog2: true });
    }

    render() {
        const { classes, plan, proposal, handleUnsavedFields, ActionMenu, authState, status_updates, tab } = this.props;
        const { isDraft } = this.state;

        const results = this.checkResults(plan.status);
        const results_abstract = this.checkResultsAbstract(plan.status);

        var is_read_only = false;

        if (this.props.authState && this.props.authState.user && (this.props.authState.user.role === "Staff" || this.props.authState.user.role === "Member")) {
            is_read_only = true;
        } else if ((isDraft && proposal.status.includes("Final")) || (!isDraft && proposal.status.includes("Draft"))) {
            is_read_only = true;
        } else if (
            !editWorkPlanStatuses.includes(proposal.status) ||
            proposal.secondary_status === "Withdrawal Requested" ||
            proposal.secondary_status === "Withdrawn"
        ) {
            is_read_only = true;
        }

        // BOTH AMENDMENT AND STATUS UPDATE
        if (!isDraft && 
            (plan.status === "Amendment In Progress" || plan.status === "Amendment Revisions Needed") && 
            (plan.reporting_status === "Update Due" || plan.reporting_status === "Final Update Due" || plan.reporting_status === "Additional Update In Progress" || plan.reporting_status === "Update Revisions Needed" || plan.reporting_status === "Final Update Revisions Needed" || plan.reporting_status === "Additional Update Revisions Needed")
        ) {
            var in_progress_updates = status_updates.filter(su => !su.submitted_date);
            var statusResults = [];
            const _this = this;
            in_progress_updates.forEach(function(ipu) {
                statusResults.push(_this.checkResultsStatusUpdate(ipu));
            })
            return (
                <>
                    <Form
                        onSubmit={values => {
                            this.updateAmendment();
                            in_progress_updates.forEach(function(ipu) {
                                _this.updateStatusUpdate(ipu);
                            })
                        }}>
                        {formApi => (
                            <form onSubmit={formApi.submitForm}>
                                <Grid container spacing={16}>
                                    <Grid item xs={12} lg={10} xl={7}>
                                        <Typography variant="h3" gutterBottom style={{ display: "inline-block" }}>
                                            Review
                                        </Typography>
                                        {ActionMenu}
                                    </Grid>
                                    <Grid item xs={12} lg={10} xl={7}>
                                        <Typography variant="h3" gutterBottom>
                                            Amendment Completeness Check
                                        </Typography>
                                        <Typography style={{ color: !results ? "darkgreen" : "darkred" }}>
                                            {!results ? "All required fields are complete and you may proceed to submitting." : results}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} lg={10} xl={7}>
                                        <Typography variant="h3" gutterBottom>
                                            Status Update Completeness Check
                                        </Typography>
                                        <Typography style={{ color: statusResults.every(element => element === null) ? "darkgreen" : "darkred" }}>
                                            {statusResults.every(element => element === null) ? "All required fields are complete and you may proceed to submitting." : statusResults}
                                        </Typography>
                                    </Grid>
                                    {authState.user.id !== proposal.user_id ? (
                                        <Grid item xs={12} lg={10} xl={7}>
                                            <Typography variant="h3">The owner of the project must be the one to to submit for LCCMR consideration.</Typography>
                                        </Grid>
                                    ) : (
                                        <Grid item xs={12} lg={10} xl={7}>
                                            <Button variant="contained" type="submit" disabled={results || !statusResults.every(element => element === null)} color="primary">
                                                Save and Submit Amendment and Status Update to LCCMR
                                            </Button>
                                        </Grid>
                                    )}
                                </Grid>
                            </form>
                        )}
                    </Form>
                    <Dialog role="main" aria-label="Amendment and Status Update Submitted" open={this.state.thanksDialog2} onClose={this.handleClose} disableBackdropClick={true}>
                        <DialogTitle className={classes.dialogTitleMargin} disableTypography id="form-dialog-title">
                            <Typography variant="h1" className={classes.flex}>Amendment and Status Update Submitted</Typography>
                            <IconButton className={classes.closeButton} onClick={this.handleClose}>
                                <CloseIcon />
                            </IconButton>
                        </DialogTitle>
                        <DialogContent>
                            <Typography>
                                Thank you for your submission. A confirmation email has been sent. Please be sure to check your spam folder and add
                                noreply@lccmrprojectmgmt.leg.mn to your address book to ensure receipt of future notifications.
                            </Typography>
                        </DialogContent>
                        <DialogActions>
                            <Button color="primary" variant="contained" onClick={this.handleClose}>
                                Return to Dashboard
                            </Button>
                        </DialogActions>
                    </Dialog>
                </>
            );
        }

        // AMENDMENT ONLY
        if (!isDraft && (plan.status === "Amendment In Progress" || plan.status === "Amendment Revisions Needed")) {
            return (
                <>
                    <Form
                        onSubmit={values => this.updateAmendment(values)}>
                        {formApi => (
                            <form onSubmit={formApi.submitForm}>
                                <Grid container spacing={16}>
                                    <Grid item xs={12} lg={10} xl={7}>
                                        <Typography variant="h3" gutterBottom style={{ display: "inline-block" }}>
                                            Amendment Review
                                        </Typography>
                                        {ActionMenu}
                                    </Grid>
                                    <Grid item xs={12} lg={10} xl={7}>
                                        <Typography variant="h3" gutterBottom>
                                            Completeness Check
                                        </Typography>
                                        <Typography style={{ color: !results ? "darkgreen" : "darkred" }}>
                                            {!results ? "All required fields are complete and you may proceed to submitting." : results}
                                        </Typography>
                                    </Grid>
                                    {authState.user.id !== proposal.user_id ? (
                                        <Grid item xs={12} lg={10} xl={7}>
                                            <Typography variant="h3">The owner of the project must be the one to to submit for LCCMR consideration.</Typography>
                                        </Grid>
                                    ) : (
                                        <Grid item xs={12} lg={10} xl={7}>
                                            <Button variant="contained" type="submit" disabled={results} color="primary">
                                                Save and Submit Amendment to LCCMR
                                            </Button>
                                        </Grid>
                                    )}
                                </Grid>
                            </form>
                        )}
                    </Form>
                    <Dialog role="main" aria-label="Amendment Submitted" open={this.state.thanksDialog2} onClose={this.handleClose} disableBackdropClick={true}>
                        <DialogTitle className={classes.dialogTitleMargin} disableTypography id="form-dialog-title">
                            <Typography variant="h1" className={classes.flex}>Amendment Submitted</Typography>
                            <IconButton className={classes.closeButton} onClick={this.handleClose}>
                                <CloseIcon />
                            </IconButton>
                        </DialogTitle>
                        <DialogContent>
                            <Typography>
                                Thank you for your submission. A confirmation email has been sent. Please be sure to check your spam folder and add
                                noreply@lccmrprojectmgmt.leg.mn to your address book to ensure receipt of future notifications.
                            </Typography>
                        </DialogContent>
                        <DialogActions>
                            <Button color="primary" variant="contained" onClick={this.handleClose}>
                                Return to Dashboard
                            </Button>
                        </DialogActions>
                    </Dialog>
                </>
            );
        }

        // STATUS UPDATE ONLY
        if (
            !isDraft &&
            (plan.reporting_status === "Update Due" || plan.reporting_status === "Final Update Due" ||
                plan.reporting_status === "Additional Update In Progress" ||
                plan.reporting_status === "Update Revisions Needed" ||
                plan.reporting_status === "Final Update Revisions Needed" ||
                plan.reporting_status === "Additional Update Revisions Needed")
        ) {
            var in_progress_updates2 = status_updates.filter(su => !su.submitted_date);
            var statusResults2 = [];
            const _this = this;
            in_progress_updates2.forEach(function(ipu) {
                statusResults2.push(_this.checkResultsStatusUpdate(ipu));
            })
            return (
                <>
                    <Form
                        onSubmit={values => {
                            in_progress_updates2.forEach(function(ipu) {
                                _this.updateStatusUpdate(ipu);
                            })
                        }}>
                        {formApi => (
                            <form onSubmit={formApi.submitForm}>
                                <Grid container spacing={16}>
                                    <Grid item xs={12} lg={10} xl={7}>
                                        <Typography variant="h3" gutterBottom style={{ display: "inline-block" }}>
                                            Status Update Review
                                        </Typography>
                                        {ActionMenu}
                                    </Grid>
                                    <Grid item xs={12} lg={10} xl={7}>
                                        <Typography variant="h3" gutterBottom>
                                            Completeness Check
                                        </Typography>
                                        <Typography style={{ color: statusResults2.every(element => element === null) ? "darkgreen" : "darkred" }}>
                                            {statusResults2.every(element => element === null) ? "All required fields are complete and you may proceed to submitting." : statusResults2}
                                        </Typography>
                                    </Grid>
                                    {authState.user.id !== proposal.user_id ? (
                                        <Grid item xs={12} lg={10} xl={7}>
                                            <Typography variant="h3">The owner of the project must be the one to to submit for LCCMR consideration.</Typography>
                                        </Grid>
                                    ) : (
                                        <Grid item xs={12} lg={10} xl={7}>
                                            <Button variant="contained" type="submit" disabled={!statusResults2.every(element => element === null)} color="primary">
                                                Save and Submit Status Update to LCCMR
                                            </Button>
                                        </Grid>
                                    )}
                                </Grid>
                            </form>
                        )}
                    </Form>
                    <Dialog role="main" aria-label="Status Update Submitted" open={this.state.thanksDialog2} onClose={this.handleClose} disableBackdropClick={true}>
                        <DialogTitle className={classes.dialogTitleMargin} disableTypography id="form-dialog-title">
                            <Typography variant="h1" className={classes.flex}>Status Update Submitted</Typography>
                            <IconButton className={classes.closeButton} onClick={this.handleClose}>
                                <CloseIcon />
                            </IconButton>
                        </DialogTitle>
                        <DialogContent>
                            <Typography>
                                Thank you for your submission. A confirmation email has been sent. Please be sure to check your spam folder and add
                                noreply@lccmrprojectmgmt.leg.mn to your address book to ensure receipt of future notifications.
                            </Typography>
                        </DialogContent>
                        <DialogActions>
                            <Button color="primary" variant="contained" onClick={this.handleClose}>
                                Return to Dashboard
                            </Button>
                        </DialogActions>
                    </Dialog>
                </>
            );
        }

        // AMENDMENT SUBMIT
        if (!isDraft && (plan.status && plan.status !== "Amendment Approved")) {
            return (
                <Grid container spacing={16}>
                    <Grid item xs={12} lg={10} xl={7}>
                        <Typography variant="h3" gutterBottom style={{ display: "inline-block" }}>
                        Amendment Review
                        </Typography>
                        {ActionMenu}
                    </Grid>
                    <Grid item xs={12} lg={10} xl={7}>
                        <Typography variant="h3">Your Amendment has already been submitted. Please wait to hear back from LCCMR staff.</Typography>
                    </Grid>
                    <Dialog role="main" aria-label="Amendment Submitted" open={this.state.thanksDialog2} onClose={this.handleClose} disableBackdropClick={true}>
                        <DialogTitle className={classes.dialogTitleMargin} disableTypography id="form-dialog-title">
                            <Typography variant="h1" className={classes.flex}>Amendment Submitted</Typography>
                            <IconButton className={classes.closeButton} onClick={this.handleClose}>
                                <CloseIcon />
                            </IconButton>
                        </DialogTitle>
                        <DialogContent>
                            <Typography>
                                Thank you for your submission. A confirmation email has been sent. Please be sure to check your spam folder and add
                                noreply@lccmrprojectmgmt.leg.mn to your address book to ensure receipt of future notifications.
                            </Typography>
                        </DialogContent>
                        <DialogActions>
                            <Button color="primary" variant="contained" onClick={this.handleClose}>
                                Return to Dashboard
                            </Button>
                        </DialogActions>
                    </Dialog>
                </Grid>
            );
        }

        // STATUS UPDATE SUBMIT
        if (
            !isDraft &&
            (plan.reporting_status === "Update Submitted" || plan.reporting_status === "Final Update Submitted" ||
                plan.reporting_status === "Additional Update Submitted" ||
                plan.reporting_status === "Update Revisions Submitted" ||
                plan.reporting_status === "Final Update Revisions Submitted" ||
                plan.reporting_status === "Additional Update Revisions Submitted")
        ) {
            return (
                <Grid container spacing={16}>
                    <Grid item xs={12} lg={10} xl={7}>
                        <Typography variant="h3" gutterBottom style={{ display: "inline-block" }}>
                            Review
                        </Typography>
                        {ActionMenu}
                    </Grid>
                    <Grid item xs={12} lg={10} xl={7}>
                        <Typography variant="h3">Your Status Update has already been submitted. Please wait to hear back from LCCMR staff.</Typography>
                    </Grid>
                </Grid>
            );
        }

        // Submit Final Abstract
        if (proposal.status === "Final Abstract Due" || proposal.status === "Final Abstract Revisions Needed") {
            return (
                <Form
                    key={proposal.id}
                    dontValidateOnMount={true}
                    validateOnSubmit={true}
                    formDidUpdate={() => handleUnsavedFields(true)}
                    onSubmit={(values, fromStepper) => this.updateProposal2(values, fromStepper)}>
                    {formApi => (
                        <form onSubmit={formApi.submitForm}>
                            <Grid container spacing={16}>
                                <Grid item xs={12} lg={10} xl={7}>
                                    <Typography variant="h2" gutterBottom style={{ display: "inline-block" }}>
                                        Final Abstract Check
                                    </Typography>
                                    {ActionMenu}
                                    <Typography style={{ color: !results_abstract ? "darkgreen" : "darkred" }}>
                                        {!results_abstract ? "All required fields are complete and you may proceed to submitting." : results_abstract}
                                    </Typography>
                                </Grid>
                                {authState.user.id !== proposal.user_id ? (
                                    <Grid item xs={12} lg={10} xl={7}>
                                        <Typography variant="h3">The owner of the project must be the one to to submit for LCCMR consideration.</Typography>
                                    </Grid>
                                ) : (
                                    <Grid item xs={12} lg={10} xl={7}>
                                        <Button
                                            variant="contained"
                                            type="submit"
                                            color="primary"
                                            disabled={results_abstract}
                                            onClick={() => this.setState({ previousStep: false, updateStatus: true })}>
                                            Save and Submit Final Abstract to LCCMR
                                        </Button>
                                    </Grid>
                                )}
                            </Grid>
                        </form>
                    )}
                </Form>
            );
        }
        if (proposal.status === "Final Abstract Submitted") {
            return (
                <Grid container spacing={16}>
                    <Grid item xs={12} lg={10} xl={7}>
                        <Typography variant="h2" gutterBottom style={{ display: "inline-block" }}>
                            Final Abstract Check
                        </Typography>
                        {ActionMenu}
                        <Typography>
                            You have already submitted your final abstract. Please wait for staff to approve.
                        </Typography>
                    </Grid>
                    <Dialog role="main" aria-label="Final Abstract Submitted" open={this.state.thanksDialog} onClose={this.handleClose} disableBackdropClick={true}>
                        <DialogTitle className={classes.dialogTitleMargin} disableTypography id="form-dialog-title">
                            <Typography variant="h1" className={classes.flex}>Final Abstract Submitted</Typography>
                            <IconButton className={classes.closeButton} onClick={this.handleClose}>
                                <CloseIcon />
                            </IconButton>
                        </DialogTitle>
                        <DialogContent>
                            <Typography>
                                Thank you for your submission. A confirmation email has been sent. Please be sure to check your spam folder and add
                                noreply@lccmrprojectmgmt.leg.mn to your address book to ensure receipt of future notifications.
                            </Typography>
                        </DialogContent>
                        <DialogActions>
                            <Button color="primary" variant="contained" onClick={this.handleClose}>
                                Return to Dashboard
                            </Button>
                        </DialogActions>
                    </Dialog>
                </Grid>
            );
        }
        if (proposal.status === "Project Completed") {
            return (
                <Grid container spacing={16}>
                    <Grid item xs={12} lg={10} xl={7}>
                        <Typography variant="h2" gutterBottom style={{ display: "inline-block" }}>
                            Check
                        </Typography>
                        {ActionMenu}
                        <Typography>
                            This project has been completed.
                        </Typography>
                    </Grid>
                </Grid>
            );
        }
        if (is_read_only) {
            return (
                <div>
                    <Grid container spacing={16}>
                        <Grid item xs={12} lg={10} xl={7}>
                            <Typography variant="h3" gutterBottom style={{ display: "inline-block" }}>
                            Status Update Review
                            </Typography>
                            {ActionMenu}
                        </Grid>
                        <Grid item xs={12} lg={10} xl={7}>
                            <Typography variant="h3">Your Work Plan has already been submitted. Please wait to hear back from LCCMR staff.</Typography>
                        </Grid>
                        <Grid item xs={12} lg={10} xl={7}>
                            <Form key={proposal.id} dontValidateOnMount={true} defaultValues={plan.formData}>
                                {formApi => (
                                    <form onSubmit={formApi.submitForm}>
                                        <TextField disabled={true} field="changes" multiline fullWidth>
                                            <HelpLabel
                                                inputLabel="Describe changes from proposal to workplan stage."
                                                htmlText={true}
                                                showLabel={true}
                                                helpText={tab.tab_help_texts.find(tht => tht.label === "Describe changes from proposal to workplan stage.").text}
                                            />
                                        </TextField>
                                    </form>
                                )}
                            </Form>
                        </Grid>
                    </Grid>
                    <Dialog role="main" aria-label="Work Plan Submitted" open={this.state.thanksDialog ? true : false} onClose={this.handleClose} disableBackdropClick={true}>
                        <DialogTitle className={classes.dialogTitleMargin} disableTypography id="form-dialog-title">
                            <Typography variant="h1" className={classes.flex}>Work Plan Submitted</Typography>
                            <IconButton className={classes.closeButton} onClick={this.handleClose}>
                                <CloseIcon />
                            </IconButton>
                        </DialogTitle>
                        <DialogContent>
                            <Typography>
                                Thank you for your submission. A confirmation email has been sent. Please be sure to check your spam folder and add
                                noreply@lccmrprojectmgmt.leg.mn to your address book to ensure receipt of future notifications.
                            </Typography>
                        </DialogContent>
                        <DialogActions>
                            <Button color="primary" variant="contained" onClick={this.handleClose}>
                                Return to Dashboard
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>
            );
        }

        if (authState.user.id !== proposal.user_id) {
            return (
                <Grid container spacing={16}>
                    <Grid item xs={12} lg={10} xl={7}>
                        <Typography variant="h2" gutterBottom style={{ display: "inline-block" }}>
                            Completeness Check
                        </Typography>
                        {ActionMenu}
                        <Typography style={{ color: !results ? "darkgreen" : "darkred" }}>
                            {!results ? "All required fields are complete and you may proceed to submitting." : results}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} lg={10} xl={7}>
                        <Typography variant="h3">The owner of the project must be the one to to submit for LCCMR consideration.</Typography>
                    </Grid>
                </Grid>
            );
        }

        //These statuses should prevent draft/final work plan from submitting.
        var is_binary = false;
        if (
            proposal.secondary_status === "Withdrawal Requested" ||
            proposal.secondary_status === "Withdrawn" ||
            proposal.secondary_status === "Governor Veto" ||
            proposal.secondary_status === "Not Passed By Legislature"
        ) {
            is_binary = true;
        }

        const notLocked = !editWorkPlanStatuses.includes(proposal.status);

        return (
            <Form
                getApi={el => (this.form = el)}
                key={proposal.id}
                dontValidateOnMount={true}
                validateOnSubmit={true}
                defaultValues={plan.formData}
                formDidUpdate={() => handleUnsavedFields(true)}
                onSubmit={(values, fromStepper) => this.updateProposal(values, fromStepper)}>
                {formApi => (
                    <form onSubmit={formApi.submitForm}>
                        <Grid container spacing={16}>
                            <Grid item xs={12} lg={10} xl={7}>
                                <Typography variant="titleAction">Review</Typography>
                                {ActionMenu}
                            </Grid>
                            <Grid item xs={12} lg={10} xl={7}>
                                <Switch field="authorized" style={{ marginTop: 0 }} eventHandle={this.applyOther} label="">
                                    <HelpLabel
                                        inputLabel="Are you authorized to submit on behalf of your organization?"
                                        htmlText={true}
                                        showLabel={true}
                                        helpText={tab.tab_help_texts.find(tht => tht.label === "Are you authorized to submit on behalf of your organization?").text}
                                    />
                                </Switch>
                                {parseInt(plan.ml_year) >= 2025 && (
                                    <TextField field="major_contributors" eventHandle={this.applyOther} label="Provide the name(s) and organization(s) of additional individuals assisting in the completion of this project." multiline fullWidth/>
                                )}
                            </Grid>
                            <Grid item xs={12} lg={10} xl={7}>
                                <Typography variant="h3" gutterBottom>
                                    Completeness Check
                                </Typography>
                                <Typography style={{ color: !results ? "darkgreen" : "darkred" }}>
                                    {!results ? "All required fields are complete and you may proceed to submitting." : results}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} lg={10} xl={7}>
                                <Typography variant="h3" gutterBottom>
                                    Difference between proposal and work plan
                                    {isDraft && (
                                        <span className={classes.asterisk}>
                                            <b>&thinsp;(new)</b>
                                        </span>
                                    )}
                                </Typography>
                                <TextField disabled={is_read_only} field="changes" multiline fullWidth>
                                    <HelpLabel
                                        inputLabel="Describe changes from proposal to workplan stage."
                                        htmlText={true}
                                        showLabel={true}
                                        helpText={tab.tab_help_texts.find(tht => tht.label === "Describe changes from proposal to workplan stage.").text}
                                    />
                                </TextField>
                            </Grid>
                            <Grid item xs={12} lg={10} xl={7}>
                                <Typography variant="h3" gutterBottom>
                                    Word Report
                                </Typography>
                                <Typography gutterBottom>
                                    Do you want to create a printable version of your workplan? Click on the button below to create a Word file for download.
                                    This can also be generated after the workplan is submitted from the dashboard or from the action menu.
                                </Typography>
                                <Button variant="contained" onClick={() => window.open(`/reports/workplan/${plan.id}?draft=${isDraft ? "y" : "n"}`)}>
                                    Print to Word
                                </Button>
                            </Grid>
                            <Grid item xs={12} lg={10} xl={7}>
                                <Grid container spacing={16}>
                                    <Grid item xs={4}>
                                        <Button
                                            disabled={proposal.status === "Draft Submitted"}
                                            variant="contained"
                                            type="submit"
                                            style={{ float: "left" }}
                                            onClick={() => this.setState({ previousStep: true })}
                                            fullWidth>
                                            Save and Return to Previous Step
                                        </Button>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Button
                                            disabled={proposal.status === "Draft Submitted"}
                                            variant="contained"
                                            type="submit"
                                            onClick={() => this.setState({ previousStep: false, updateStatus: false })}
                                            fullWidth>
                                            Save and Return to Dashboard
                                        </Button>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Button
                                            variant="contained"
                                            type="submit"
                                            color="primary"
                                            fullWidth
                                            disabled={results || is_binary || notLocked}
                                            onClick={() => this.setState({ previousStep: false, updateStatus: true })}>
                                            Save and Submit Work Plan to LCCMR
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </form>
                )}
            </Form>
        );
    }
}

ReviewTab = connect(
    (state, ownProps) => ({
        authState: state.auth,
        activities: getActivities(state, ownProps),
        organizations: getOrganizations(state, ownProps),
        parcels: getParcels(state, ownProps),
        boardAttachment: getBoardAttachments(state, ownProps),
        financialAttachment: getFinancialAttachments(state, ownProps),
        status_updates: getStatusUpdates(state, ownProps),
        status_update_activities: getStatusUpdateActivities(state, ownProps),
        contracts: getContracts(state, ownProps),
        proposaltravels: getProposalTravels(state, ownProps),

        amendments: getAmendments(state, ownProps),
        collaborators_amendment: getCollaboratorsAmendment(state, ownProps),
        activities_amendment: getActivitiesAmendment(state, ownProps),
        personnel_amendment: getPersonnelAmendment(state, ownProps),
        contracts_amendment: getContractsAmendment(state, ownProps),
        expenditures_amendment: getExpendituresAmendment(state, ownProps),
        acquisitions_amendment: getAcquisitionsAmendment(state, ownProps),
        travels_amendment: getTravelsAmendment(state, ownProps),
        printings_amendment: getPrintingsAmendment(state, ownProps),
        expenses_amendment: getExpensesAmendment(state, ownProps),
        funds_amendment: getFundsAmendment(state, ownProps),
        parcels_amendment: getParcelsAmendment(state, ownProps),
        attachments_amendment: getAttachmentsAmendment(state, ownProps),
        tab: getTab(state, ownProps),
    }),
    {
        ...Proposal.actions,
        ...StatusUpdate.actions,
        ...StatusUpdateActivity.actions,
        ...FinalWorkPlan.actions,
        ...AmendmentLog.actions,
        reloadListOfModels: reloadListOfModels
    }
)(ReviewTab);

export default withStyles(styles)(withRouter(ReviewTab));
