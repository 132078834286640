import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Form } from "react-form";
import { connect } from "react-redux";
import DateFnsUtils from "@date-io/date-fns";
import { addDays } from "date-fns";

import Button from "@material-ui/core/Button";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import { withStyles } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import Toolbar from "@material-ui/core/Toolbar";

import Close from "@material-ui/icons/Close";

import Select from "../../common/Select";
import MonthPicker from "../../common/MonthPicker";
import TextField from "../../common/TextField";
import RadioGroup from "../../common/RadioGroup";
import HelpLabel from "../../common/HelpLabel";
import { WorkPlanTab, StatusUpdateTab } from "../../home/models";
import { CompletionRequest } from "../models";

const getTab = WorkPlanTab.selectMessage(4);
const getStatusTab = StatusUpdateTab.selectMessage(6);

const styles = theme => ({
    rightAlign: {
        textAlign: "right"
    },
    button: {
        marginRight: theme.spacing.unit * 2
    },
    link: {
        cursor: "pointer",
        textDecoration: "underline"
    },
    flex: {
        flex: 1,
        fontWeight: 500,
        fontSize: "1.15rem",
        color: "#495057",
        lineHeight: 1.2
    },
    asterisk: {
        whiteSpace: "normal",
        lineHeight: 1.5,
        color: "#212529 !important",
        fontFamily: "Roboto, 'Helvetica Neue', Arial, sans-serif"
    }
});

const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "July", "Aug", "Sept", "Oct", "Nov", "Dec"];

class NarrativeTab extends Component {
    state = {
        requestDialog: false,
        OrgDialogOpen: false,
        formkey: null,
        isDraft: this.props.match.path === "/draft/workplan/:id"
    };

    componentDidMount() {
        const { handleUnsavedFields, ormParentUpdateLocalOnly, plan } = this.props;
        document.title = "Work Plan: Narrative - LCCMR";
        this.props.onRef(this);
        const _this = this;
        // FIXME: react-forms calls formDidUpdate when (if) validation is ran right away
        // making it appear there is a edited field even if there isn't
        // Reset fields to false to not require validation when mounted
        setTimeout(function() {
            handleUnsavedFields(false);
            if (!plan.fromReview) {
                _this.props.appContainer.current.scrollTop();
            } else {
                ormParentUpdateLocalOnly({
                    id: plan.id,
                    fromReview: false
                });
            }
        }, 1);
    }

    componentWillUnmount() {
        this.props.onRef(undefined);
    }

    updatePlan(values, fromStepper) {
        const {
            ormParentUpdate,
            history,
            handleUnsavedFields,
            handleNext,
            handlePrev,
            getDateTimeStamp,
            plan,
            ormParentUpdateLocalOnly,
            is_read_only
        } = this.props;
        const { id } = plan;

        if (typeof fromStepper.step !== "undefined") {
            // If this is hit updateProposal initiated through a stepper click
            // Set the values of the steps to the one that was clicked.
            values.activeStep = fromStepper["step"];
            values.activeSubStep = fromStepper["subStep"];

            // If not draft just update the tab steps locally
            if (is_read_only) {
                ormParentUpdateLocalOnly({
                    id: id,
                    ...values
                });
                return;
            }
        }

        // See Attachments.js for an explanation of why I'm doing this
        values.map = plan.map;
        values.financial_capacity = plan.financial_capacity;
        values.modify_date_label = plan.modify_date_label;
        values.update_date = getDateTimeStamp();

        ormParentUpdate({
            id: id,
            ...values
        });

        handleUnsavedFields(false);

        if (typeof fromStepper.step === "undefined") {
            // If this is hit updateProposal was initialed by the bottom save button
            // Either go to the dashboard or call handlNext to determine the next step
            if (this.state.draftClick) {
                history.push("/dashboard/");
            } else if (this.state.previousStep) {
                handlePrev();
            } else {
                handleNext();
            }
        }
    }

    errorValidator(values) {
        const checkNull = date => {
            if (!isNaN(date)) return null;
            else return "Required";
        };

        return {
            request_first_date: checkNull(values.request_first_date),
            request_second_date: checkNull(values.request_second_date)
        };
    }

    handleValidator = values => {
        const { accept } = this.state;
        const checkNull = date => {
            if (date) return null;
            else return "Required";
        };

        return {
            comments_for_pm: !accept ? checkNull(values.comments_for_pm) : null
        };
    };

    notifyPM(values) {
        const { authState, plan, ormParentUpdate, history } = this.props;
        const { accept } = this.state;

        var tmp = {
            request_first_date: this.reportDates(plan.request_first_date + "T12:00:00"),
            request_second_date: this.reportDates(plan.request_second_date + "T12:00:00"),
            original_first_date: this.reportDates(plan.first_date + "T12:00:00"),
            original_second_date: this.reportDates(plan.second_date + "T12:00:00")
        };

        if (accept) {
            ormParentUpdate({
                id: plan.id,
                reporting_date_accepted: true,
                first_date: plan.request_first_date,
                second_date: plan.request_second_date,
                request_first_date: null,
                request_second_date: null
            });
        } else {
            ormParentUpdate({
                id: plan.id,
                reporting_date_accepted: false,
                request_first_date: null,
                request_second_date: null
            });
        }

        fetch("reporting/schedule", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Token " + authState.user.auth_token
            },
            body: JSON.stringify({
                id: plan.id,
                type: accept ? "accept" : "",
                phase: this.props.match.path === "/draft/workplan/:id" ? "draft" : "final",
                comment: !accept ? values.comments_for_pm : null,
                first_date: tmp.request_first_date,
                second_date: tmp.request_second_date,
                original_first_date: tmp.original_first_date,
                original_second_date: tmp.original_second_date
            })
        });
        this.setState({ handleRequest: false });
        history.push("/dashboard/");
    }

    requestReportingSchedule(values) {
        const { authState, plan, ormParentUpdate } = this.props;

        const obj = {};
        var date = new Date();

        obj["request_first_date"] = `${date.getFullYear()}-${values.request_first_date + 1}-01`;
        obj["request_second_date"] = `${date.getFullYear()}-${values.request_second_date + 1}-01`;

        ormParentUpdate({
            id: plan.id,
            request_first_date: obj.request_first_date,
            request_second_date: obj.request_second_date
        });

        if (this.form) {
            this.form.setValue("request_first_date", obj.request_first_date);
            this.form.setValue("request_second_date", obj.request_second_date);
        }

        fetch("reporting/schedule", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Token " + authState.user.auth_token
            },
            body: JSON.stringify({
                id: plan.id,
                type: "request",
                phase: this.props.match.path === "/draft/workplan/:id" ? "draft" : "final",
                first_date: plan.first_date,
                second_date: plan.second_date
            })
        });

        this.setState({ requestDialog: false });
    }

    calculateFinalReportDate(date) {
        if (date) {
            var tempDate = new Date(date + "T12:00:00");
            const finalDate = addDays(tempDate, 45);
            const dateFns = new DateFnsUtils();
            return dateFns.format(finalDate, "MM/dd/yyyy");
        } else {
            return "";
        }
    }

    displayApprovalDate(proposal) {
        if (proposal && proposal.workplan_date && (proposal.status === "Final Work Plan Approved" || proposal.status === "Final Abstract Due" || proposal.status === "Final Abstract Submitted" || proposal.status === "Final Abstract Revisions Needed")) {
            var tempDate = new Date(proposal.workplan_date + "T12:00:00");
            const dateFns = new DateFnsUtils();
            return "Approval Date: " + dateFns.format(tempDate, "MM/dd/yyyy");
        } else if (proposal && proposal.workplan_approved) {
            return "Approval Date: ";
        } else {
            return "";
        }
    }

    calulateAppropriationDate(date) {
        if (date) {
            var temp = new Date(date);
            if (temp.getMonth() + 1 <= 6) {
                return `6/30/${temp.getFullYear()}`;
            } else {
                return `6/30/${temp.getFullYear() + 1}`;
            }
        } else {
            return "";
        }
    }

    reportDates(value) {
        const first = new Date(value);
        return `${months[first.getMonth()]} ${first.getDate()}`;
    }

    displayCitation(proposal) {
        var str = "";
        str += proposal.citation_ml_year ? " M.L. " + proposal.citation_ml_year + "," : "";
        str += proposal.special_session ? " " + proposal.special_session + "," : "";
        str += proposal.chapter ? " Chp. " + proposal.chapter + "," : "";
        str += proposal.article ? " Art. " + proposal.article + "," : "";
        str += proposal.section ? " Sec. " + proposal.section + "," : "";
        str += proposal.subdivision ? " Subd. " + proposal.subdivision : "";
        str += proposal.additional_citation ? " " + proposal.additional_citation : "";
        return str;
    }

    render() {
        const { classes, plan, proposal, is_read_only, handleUnsavedFields, ActionMenu, tab, authState, completion_requests, ormCompletionRequestCreate, status_tab, ormParentUpdateLocalOnly } = this.props;
        const { isDraft, accept, handleRequest, requestDialog, OrgDialogOpen, formkey } = this.state;

        const first_date = plan.first_date ? plan.first_date + "T12:00:00" : null;
        const second_date = plan.second_date ? plan.second_date + "T12:00:00" : null;
        const request_first_date = plan.request_first_date ? plan.request_first_date + "T12:00:00" : null;
        const request_second_date = plan.request_second_date ? plan.request_second_date + "T12:00:00" : null;

        const admin = authState && authState.user && authState.user.role === "Staff";
        const user = authState && authState.user && authState.user.role === "User";

        const options = [{ value: null, label: " " }, ...months.map((x, idx) => ({ label: x + " 1", value: idx }))];

        var is_dnr = false;
        if (authState && authState.user.is_read_only) is_dnr = true;
        var is_member = false;
        if (authState && authState.user.role === "Member") is_member = true;

        return (
            <>
                <Form
                    getApi={el => (this.form = el)}
                    key={plan.id}
                    dontValidateOnMount={true}
                    validateOnSubmit={true}
                    defaultValues={plan.formData}
                    formDidUpdate={() => handleUnsavedFields(true)}
                    onSubmit={(values, fromStepper) => this.updatePlan(values, fromStepper)}>
                    {formApi => (
                        <form onSubmit={formApi.submitForm}>
                            <Grid container spacing={16}>
                                <Grid item xs={12} lg={10} xl={7}>
                                    <Typography variant="titleAction">Project Description</Typography>
                                    {ActionMenu}
                                </Grid>
                                <Grid item xs={12} lg={10} xl={7}>
                                    <TextField disabled={is_read_only} field="summary" maxWords="30" multiline fullWidth checkFocus={plan} focusRemoval={ormParentUpdateLocalOnly}>
                                        <HelpLabel
                                            inputLabel="Project Summary (30 word limit)"
                                            htmlText={true}
                                            showLabel={true}
                                            helpText={tab.tab_help_texts.find(tht => tht.label === "Project Summary. (30 word limit)").text}
                                        />
                                    </TextField>
                                    <TextField
                                        field="opportunity"
                                        maxWords="200"
                                        multiline
                                        checkFocus={plan} 
                                        focusRemoval={ormParentUpdateLocalOnly}
                                        disabled={is_read_only}
                                        fullWidth
                                        label="Describe the opportunity or problem your proposal seeks to address. Include any relevant background information. (200 word limit)"
                                    />
                                    <TextField
                                        field="solution"
                                        maxWords="200"
                                        checkFocus={plan} 
                                        focusRemoval={ormParentUpdateLocalOnly}
                                        multiline
                                        disabled={is_read_only}
                                        fullWidth
                                        label="What is your proposed solution to the problem or opportunity discussed above? Introduce us to the work you are seeking funding to do. You will be asked to expand on this proposed solution in Activities & Milestones. (200 word limit)"
                                    />
                                    <TextField
                                        field="outcome"
                                        maxWords="100"
                                        checkFocus={plan} 
                                        focusRemoval={ormParentUpdateLocalOnly}
                                        multiline
                                        disabled={is_read_only}
                                        fullWidth
                                        label="What are the specific project outcomes as they relate to the public purpose of protection, conservation, preservation, and enhancement of the state’s natural resources? (100 word limit)"
                                    />
                                </Grid>
                                <Grid item xs={12} lg={10} xl={7}>
                                    <HelpLabel
                                        inputLabel="Timeline"
                                        htmlText={true}
                                        showLabel={true}
                                        helpText={tab.tab_help_texts.find(tht => tht.label === "Timeline").text}
                                    />
                                    <br />
                                    <MonthPicker
                                        field="timeline"
                                        checkFocus={plan} 
                                        focusRemoval={ormParentUpdateLocalOnly}
                                        aria-label={plan.status && "Completion date can only be edited via request to change completion date"} 
                                        disabled={is_read_only || plan.status}
                                        label={
                                            "Assuming your project is approved to begin July 1, " +
                                            proposal.ml_year +
                                            ", when will your project be complete?"
                                        }
                                    />
                                    {plan.timeline_restore && <Typography style={{color:"darkred"}}>* Pending Legislative Approval</Typography>}
                                    <br />
                                    {!is_dnr && !is_member && (proposal.status === "Final Work Plan Approved" || proposal.status === "Final Abstract Due") && completion_requests.length === 0 && (
                                        <Link
                                            onClick={() =>
                                                this.setState({
                                                    OrgDialogOpen: true,
                                                    formkey: window.performance.now()
                                                })
                                            }
                                            style={{display:"inline-block", marginBottom: 16, marginTop:8}}
                                            className={classes.link}>
                                            Request to change completion date.
                                        </Link>
                                    )}
                                    {completion_requests.length > 0 && (
                                        <Typography variant="caption" style={{marginTop: 8, color:"#d32f2f"}}>A completion date change request has already been initiated. Please wait for LCCMR Staff to review. You may also withdraw your previous request using the select action menu.</Typography>
                                    )}
                                    <br />
                                    <Typography>
                                        Appropriation End Date:&nbsp;
                                        {proposal.app_end_date
                                            ? new Date(proposal.app_end_date + "T12:00:00").toLocaleDateString()
                                            : this.calulateAppropriationDate(formApi.values["timeline"])}
                                        {isDraft && (
                                            <span className={classes.asterisk}>
                                                <b>&thinsp;(new)</b>
                                            </span>
                                        )}
                                    </Typography>
                                    <br />
                                    <Typography>
                                        Your final report due date would be: {this.calculateFinalReportDate(formApi.values["timeline"])}
                                        {isDraft && (
                                            <span className={classes.asterisk}>
                                                <b>&thinsp;(new)</b>
                                            </span>
                                        )}
                                    </Typography>
                                    <br />
                                    <Typography>
                                        <>
                                            {admin ? "Original Reporting Schedule: " : "Your "} status update reports would be due each year on:{" "}
                                            {this.reportDates(first_date)} / {this.reportDates(second_date)}
                                        </>
                                        {!admin && user && !request_first_date && proposal.status !== "Project Completed" && !request_second_date && (
                                            <>
                                                &nbsp;&nbsp;&nbsp;
                                                <Link onClick={() => this.setState({ requestDialog: true })} className={classes.link}>
                                                    Request a different reporting schedule
                                                </Link>
                                            </>
                                        )}
                                        {isDraft && (
                                            <span className={classes.asterisk}>
                                                <b>&nbsp;&thinsp;(new)</b>
                                            </span>
                                        )}
                                    </Typography>
                                    {!admin && request_first_date && request_second_date && (
                                        <>
                                            <br />
                                            <Typography>
                                                Your requested reporting schedule:&nbsp;
                                                {this.reportDates(request_first_date)} /&nbsp;
                                                {this.reportDates(request_second_date)}
                                                {isDraft && (
                                                    <span className={classes.asterisk}>
                                                        <b>&thinsp;(new)</b>
                                                    </span>
                                                )}
                                            </Typography>
                                        </>
                                    )}
                                    {admin && request_first_date && request_second_date && (
                                        <>
                                            <br />
                                            <Typography>
                                                Requested reporting schedule:&nbsp;
                                                {this.reportDates(request_first_date)} /&nbsp;
                                                {this.reportDates(request_second_date)}
                                            </Typography>
                                        </>
                                    )}
                                    {admin && request_first_date && request_second_date && (
                                        <>
                                            <br />
                                            <Link onClick={() => this.setState({ accept: true, handleRequest: true })} className={classes.link}>
                                                Accept Schedule
                                            </Link>
                                            &nbsp;/&nbsp;
                                            <Link onClick={() => this.setState({ accept: false, handleRequest: true })} className={classes.link}>
                                                Decline Schedule
                                            </Link>
                                            <br />
                                        </>
                                    )}
                                    <br />
                                    <br />
                                    <Typography>{this.displayApprovalDate(proposal)}</Typography>
                                </Grid>
                                <Grid item xs={12} lg={10} xl={7}>
                                    <Typography variant="titleAction">
                                        Legal Citation
                                        {isDraft && (
                                            <span className={classes.asterisk}>
                                                <b>&thinsp;(new)</b>
                                            </span>
                                        )}
                                    </Typography>
                                    <br />
                                    <br />
                                    <Typography>{this.displayCitation(proposal)}</Typography>
                                </Grid>
                                <Grid item xs={12} lg={10} xl={7}>
                                    <Typography variant="titleAction">
                                        Appropriation Language
                                        {isDraft && (
                                            <span className={classes.asterisk}>
                                                <b>&thinsp;(new)</b>
                                            </span>
                                        )}
                                    </Typography>
                                    <br />
                                    <br />
                                    <Typography>{proposal.app_lanuage ? proposal.app_lanuage : ""} {proposal.additional_app_lanuage && proposal.additional_app_lanuage}</Typography>
                                </Grid>
                                <Grid item xs={12} lg={10} xl={7}>
                                    <Typography variant="titleAction">Long-Term Implementation and Funding</Typography>
                                    <TextField
                                        field="longterm_implementation"
                                        maxWords="100"
                                        checkFocus={plan} 
                                        focusRemoval={ormParentUpdateLocalOnly}
                                        disabled={is_read_only}
                                        multiline
                                        fullWidth
                                        label="Describe how the results will be implemented and how any ongoing effort will be funded. If not already addressed as part of the project, how will findings, results, and products developed be implemented after project completion? If additional work is needed, how will this work be funded? (100 word limit)"></TextField>
                                </Grid>
                                <Grid item xs={12} lg={10} xl={7} className={classes.rightAlign}>
                                    <Button
                                        variant="contained"
                                        type="submit"
                                        style={{ float: "left" }}
                                        disabled={is_read_only}
                                        onClick={() => this.setState({ draftClick: false, previousStep: true })}
                                        className={classes.button}>
                                        Save and Return to Previous Step
                                    </Button>
                                    <Button
                                        variant="contained"
                                        type="submit"
                                        disabled={is_read_only}
                                        onClick={() => this.setState({ draftClick: true })}
                                        className={classes.button}>
                                        Save Draft and Return to Dashboard
                                    </Button>
                                    <Button
                                        variant="contained"
                                        type="submit"
                                        disabled={is_read_only}
                                        color="primary"
                                        onClick={() => this.setState({ draftClick: false, previousStep: false })}>
                                        Save and Proceed to Next Step
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                    )}
                </Form>
                <CompletionDialog
                    open={OrgDialogOpen}
                    proposal={proposal}
                    plan={plan}
                    help_text={status_tab.tab_help_texts.find(tht => tht.label === "Completion Date Instructions").text}
                    ormCompletionRequestCreate={ormCompletionRequestCreate}
                    returnDialog={() => this.setState({ OrgDialogOpen: false })}
                    classes={classes}
                    formkey={formkey}
                />
                <Dialog role="main" aria-label="Request Different Reporting Schedule" open={requestDialog}>
                    <Toolbar>
                        <Typography variant="h1" className={classes.flex}>
                            Request Different Reporting Schedule
                        </Typography>
                        <IconButton
                            aria-label="Close Dialog"
                            onClick={() => {
                                this.setState({ requestDialog: false });
                            }}>
                            <Close />
                        </IconButton>
                    </Toolbar>
                    <DialogContent>
                        <DialogContentText>
                            <Form
                                dontValidateOnMount={true}
                                validateOnSubmit={true}
                                validateError={this.errorValidator}
                                onSubmit={values => this.requestReportingSchedule(values)}>
                                {formApi => (
                                    <form onSubmit={formApi.submitForm}>
                                        <Grid container spacing={16}>
                                            <Grid item xs={6}>
                                                <Select
                                                    options={options}
                                                    required
                                                    field="request_first_date"
                                                    //disabled={is_read_only}
                                                    label="First alternate reporting schedule date"
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Select
                                                    options={options}
                                                    required
                                                    field="request_second_date"
                                                    //disabled={is_read_only}
                                                    label="Second alternate reporting schedule date"
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Button
                                                    onClick={() => {
                                                        this.setState({ requestDialog: false });
                                                    }}
                                                    fullWidth
                                                    variant="contained"
                                                    color="default">
                                                    cancel
                                                </Button>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Button /*disabled={is_read_only}*/ fullWidth type="submit" variant="contained" color="primary">
                                                    Request Reporting Schedule
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </form>
                                )}
                            </Form>
                        </DialogContentText>
                    </DialogContent>
                </Dialog>
                <Dialog role="main" aria-label={accept ? "Accept Different Reporting Schedule" : "Decline Different Reporting Schedule"} open={handleRequest} fullWidth maxWidth="sm">
                    <Toolbar>
                        <Typography variant="h1" className={classes.flex}>
                            {accept ? "Accept" : "Decline"} Different Reporting Schedule
                        </Typography>
                        <IconButton
                            aria-label="Close Dialog"
                            onClick={() => {
                                this.setState({ handleRequest: false });
                            }}>
                            <Close />
                        </IconButton>
                    </Toolbar>
                    <DialogContent>
                        <DialogContentText>
                            <Form
                                dontValidateOnMount={true}
                                validateOnSubmit={true}
                                validateError={this.handleValidator}
                                onSubmit={values => this.notifyPM(values)}>
                                {formApi => (
                                    <form onSubmit={formApi.submitForm}>
                                        <Grid container spacing={16}>
                                            {!accept && (
                                                <Grid item xs={12}>
                                                    <TextField
                                                        required
                                                        multiline
                                                        fullWidth
                                                        field="comments_for_pm"
                                                        label="Reason for declining to be sent to project manager"
                                                    />
                                                </Grid>
                                            )}
                                            <Grid item xs={6}>
                                                <Button
                                                    onClick={() => {
                                                        this.setState({ handleRequest: false });
                                                    }}
                                                    fullWidth
                                                    variant="contained"
                                                    color="default">
                                                    cancel
                                                </Button>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Button fullWidth type="submit" variant="contained" color="primary">
                                                    {accept ? "Accept Reporting Schedule" : "Declined Reporting Schedule"}
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </form>
                                )}
                            </Form>
                        </DialogContentText>
                    </DialogContent>
                </Dialog>
            </>
        );
    }
}

class CompletionDialog extends Component {
    state = {
        OrgThanksDialogOpen: false,
        is_after: false,
        q1_true: false
    };

    saveRequest(values) {
        const { ormCompletionRequestCreate, returnDialog, proposal } = this.props;
        const { is_after } = this.state;

        if (is_after && !values.q1)
            values.legislative_amendment = true;

        ormCompletionRequestCreate({
            proposal_id: proposal.id,
            status: "New",
            ...values
        }).then(response => {
            returnDialog();
            this.setState({ OrgThanksDialogOpen: true });
        });
    }

    errorValidator = values => {
        const { is_after, q1_true } = this.state;
        const validateRequired = name => {
            return (!name && name !== false) ? "Required" : null;
        };
        var valObj = {};
        if (is_after) {
            if (q1_true) {
                valObj = {
                    timeline: validateRequired(values.timeline),
                    why: validateRequired(values.why),
                    q1: validateRequired(values.q1),
                    q2: validateRequired(values.q2),
                    q3: validateRequired(values.q3),
                };
            } else {
                valObj = {
                    timeline: validateRequired(values.timeline),
                    why: validateRequired(values.why),
                    q1: validateRequired(values.q1),
                    q2: validateRequired(values.q2),
                    q3: null
                };
            }
        } else {
            valObj = {
                timeline: validateRequired(values.timeline),
                why: validateRequired(values.why),
                q1: null,
                q2: null,
                q3: null
            };
        }

        return valObj;
    };

    calulateAppropriationDate(date) {
        if (date) {
            var temp = new Date(date);
            if (temp.getMonth() + 1 <= 6) {
                return new Date("6/30/" + temp.getFullYear());
            } else {
                return new Date("6/30/" + (temp.getFullYear() + 1));
            }
        } else {
            return "";
        }
    }

    render() {
        const { classes, open, returnDialog, formkey, help_text, plan, proposal } = this.props;
        const { OrgThanksDialogOpen, is_after, q1_true } = this.state;

        return (
            <>
                <Dialog role="main" aria-label="Request to Change Completion Date" open={open}>
                    <Toolbar>
                        <Typography variant="h1" className={classes.flex}>
                            Request to Change Completion Date
                        </Typography>
                        <IconButton aria-label="Close Dialog" onClick={() => returnDialog()}>
                            <Close />
                        </IconButton>
                    </Toolbar>
                    <DialogContent>
                        <DialogContentText>
                            <Form
                                dontValidateOnMount={true}
                                key={formkey}
                                validateOnSubmit={true}
                                getApi={el => (this.formApi = el)}
                                validateError={this.errorValidator}
                                onSubmit={values => this.saveRequest(values)}>
                                {formApi => (
                                    <form onSubmit={formApi.submitForm}>
                                        <Grid container spacing={8}>
                                            <Grid item xs={12}>
                                                <div class="insborder">
                                                    <Typography>
                                                        <div style={{marginTop:-16}}
                                                            dangerouslySetInnerHTML={{
                                                                __html: help_text
                                                            }}
                                                        />
                                                    </Typography>
                                                </div>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <MonthPicker
                                                    field="timeline"
                                                    label="Select the new completion date."
                                                    eventHandle={val => {
                                                        var app_end_date = proposal.app_end_date ? new Date(proposal.app_end_date) : this.calulateAppropriationDate(plan.timeline)
                                                        if (new Date (val) > app_end_date)
                                                            this.setState({is_after: true});
                                                        else
                                                            this.setState({is_after: false, q1_true: false});
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField maxWords="75" multiline label="Why is the change needed?" field="why" fullWidth />
                                            </Grid>
                                            {is_after && (
                                                <>
                                                <Grid item xs={12}>
                                                    <Typography>
                                                        Warning: The date you have selected is after the appropriation end date. 
                                                        Answer the following questions or change the completion end date. 
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <RadioGroup
                                                        field="q1"
                                                        name="q1"
                                                        label="I only need more time to complete the project and any costs after the appropriation end date will be covered with other funds as described in the Non-ENRTF Funds Contributed section of the budget."
                                                        fullWidth
                                                        options={[
                                                            { label: "Yes", value: "true" },
                                                            { label: "No", value: "false" }
                                                        ]}
                                                        alignment={true}>
                                                    </RadioGroup>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <RadioGroup
                                                        field="q2"
                                                        name="q2"
                                                        label="I have exhausted all other options and talked with LCCMR staff. I need to extend the availability of funds and completion date to complete the project. I need to request a legislative extension to carry forward the availability of funds to complete the project."
                                                        fullWidth
                                                        options={[
                                                            { label: "Yes", value: "true" },
                                                            { label: "No", value: "false" }
                                                        ]}
                                                        eventHandle={val => {
                                                            if (val === "true" || val === true)
                                                                this.setState({q1_true: true});
                                                            else
                                                                this.setState({q1_true: false});
                                                        }}
                                                        alignment={true}>
                                                    </RadioGroup>
                                                </Grid>
                                                {q1_true && (
                                                    <Grid item xs={12}>
                                                        <TextField multiline maxWords="100" label="If an extension is not passed by the legislature, what parts of the project will not be completed?" field="q3" fullWidth />
                                                    </Grid>
                                                )}
                                                </>
                                            )}
                                            <Grid item xs={6}>
                                                <Button fullWidth onClick={() => returnDialog()}>
                                                    Cancel Request
                                                </Button>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Button fullWidth type="submit" variant="contained" color="primary">
                                                    Save and Submit Request
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </form>
                                )}
                            </Form>
                        </DialogContentText>
                    </DialogContent>
                </Dialog>
                <Dialog role="main" aria-label="Thank You for Your Submission" open={OrgThanksDialogOpen}>
                    <DialogTitle>Thank You for Your Submission</DialogTitle>
                    <DialogContent>
                        <Typography>
                            An email has been sent to LCCMR staff for review.   
                        </Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => {
                                window.location.reload();
                            }}
                            color="primary">
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            </>
        );
    }
}

NarrativeTab = connect(
    (state, ownProps) => ({
        tab: getTab(state, ownProps),
        status_tab: getStatusTab(state, ownProps),
        authState: state.auth
    }),
    {
        ...CompletionRequest.actions
    }
)(NarrativeTab);

export default withStyles(styles)(withRouter(NarrativeTab));
