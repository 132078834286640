import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Form } from "react-form";
import { connect } from "react-redux";

import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import { withStyles } from "@material-ui/core";

import TextField from "../../common/TextField";
import Select from "../../common/Select";
import Switch from "../../common/Switch";
import EnhancedTableHead from "../../common/EnhancedTableHead";
import TableCell from "../../common/TableCell";
import { createSelector } from "../../common/orm";
import WarningDialog from "../../common/WarningDialog";

import {
    DraftWorkPlan,
    FinalProposalContract,
    FinalProposalExpenditure,
    FinalProposalAcquisition,
    FinalProposalTravel,
    FinalProposalPrinting,
    FinalProposalExpense,
    FinalProposalNonENRTFFund
} from "../models";
import { WorkPlanTab, StatusUpdateTab } from "../../home/models";

const getProposalPersonnel = createSelector(
    (state, ownProps) => ownProps.plan.id,
    (state, ownProps) => ownProps.match.path === "/draft/workplan/:id",
    (session, id, isDraft) => {
        if (isDraft) {
            return session.DraftProposalPersonnel.filter({ proposal: id })
                .orderBy("id")
                .toModelArray();
        } else {
            return session.FinalProposalPersonnel.filter({ proposal: id })
                .orderBy("id")
                .toModelArray();
        }
    }
);
const getProposalContract = createSelector(
    (state, ownProps) => ownProps.plan.id,
    (state, ownProps) => ownProps.match.path === "/draft/workplan/:id",
    (session, id, isDraft) => {
        if (isDraft) {
            return session.DraftProposalContract.filter({ proposal: id })
                .orderBy("id")
                .toModelArray();
        } else {
            return session.FinalProposalContract.filter({ proposal: id })
                .orderBy("id")
                .toModelArray();
        }
    }
);
const getProposalExpenditure = createSelector(
    (state, ownProps) => ownProps.plan.id,
    (state, ownProps) => ownProps.match.path === "/draft/workplan/:id",
    (session, id, isDraft) => {
        if (isDraft) {
            return session.DraftProposalExpenditure.filter({ proposal: id })
                .orderBy("id")
                .toModelArray();
        } else {
            return session.FinalProposalExpenditure.filter({ proposal: id })
                .orderBy("id")
                .toModelArray();
        }
    }
);
const getProposalAcquisition = createSelector(
    (state, ownProps) => ownProps.plan.id,
    (state, ownProps) => ownProps.match.path === "/draft/workplan/:id",
    (session, id, isDraft) => {
        if (isDraft) {
            return session.DraftProposalAcquisition.filter({ proposal: id })
                .orderBy("id")
                .toModelArray();
        } else {
            return session.FinalProposalAcquisition.filter({ proposal: id })
                .orderBy("id")
                .toModelArray();
        }
    }
);
const getProposalTravel = createSelector(
    (state, ownProps) => ownProps.plan.id,
    (state, ownProps) => ownProps.match.path === "/draft/workplan/:id",
    (session, id, isDraft) => {
        if (isDraft) {
            return session.DraftProposalTravel.filter({ proposal: id })
                .orderBy("id")
                .toModelArray();
        } else {
            return session.FinalProposalTravel.filter({ proposal: id })
                .orderBy("id")
                .toModelArray();
        }
    }
);
const getProposalPrinting = createSelector(
    (state, ownProps) => ownProps.plan.id,
    (state, ownProps) => ownProps.match.path === "/draft/workplan/:id",
    (session, id, isDraft) => {
        if (isDraft) {
            return session.DraftProposalPrinting.filter({ proposal: id })
                .orderBy("id")
                .toModelArray();
        } else {
            return session.FinalProposalPrinting.filter({ proposal: id })
                .orderBy("id")
                .toModelArray();
        }
    }
);
const getProposalExpense = createSelector(
    (state, ownProps) => ownProps.plan.id,
    (state, ownProps) => ownProps.match.path === "/draft/workplan/:id",
    (session, id, isDraft) => {
        if (isDraft) {
            return session.DraftProposalExpense.filter({ proposal: id })
                .orderBy("id")
                .toModelArray();
        } else {
            return session.FinalProposalExpense.filter({ proposal: id })
                .orderBy("id")
                .toModelArray();
        }
    }
);
const getProposalNonENRTFFund = createSelector(
    (state, ownProps) => ownProps.plan.id,
    (state, ownProps) => ownProps.match.path === "/draft/workplan/:id",
    (session, id, isDraft) => {
        if (isDraft) {
            return session.DraftProposalNonENRTFFund.filter({ proposal: id })
                .orderBy("id")
                .toModelArray();
        } else {
            return session.FinalProposalNonENRTFFund.filter({ proposal: id })
                .orderBy("id")
                .toModelArray();
        }
    }
);

const status_options = [
    { value: "Potential", label: "Potential" },
    { value: "Pending", label: "Pending" },
    { value: "Secured", label: "Secured" }
];
const getTab = WorkPlanTab.selectMessage(6);
const getStatusUpdateTabs = StatusUpdateTab.selectMessage(2);

const styles = theme => ({
    rightAlign: {
        textAlign: "right"
    },
    button: {
        marginRight: theme.spacing.unit * 2
    },
    totalRow: {
        borderTop: "1px solid rgba(224, 224, 224, 1);",
        borderBottom: "1px solid rgba(224, 224, 224, 1);",
        height: 48
    },
    categoryTotalCell: {
        borderBottom: "none",
        textAlign: "right"
    },
    totalCell: {
        borderBottom: "none",
        textAlign: "right",
        width: 60
    },
    stndCell: {
        minWidth: "10%"
    },
    medCell: {
        minWidth: "25%"
    },
    wideCell: {
        minWidth: "45%"
    },
    amountCell: {
        width: 60,
        textAlign: "right"
    },
    gridPaddingRight: {
        paddingRight: 20
    },
    gridPaddingTop: {
        paddingTop: 40
    },
    label: {
        padding: "15px 10px"
    },
    centerAlign: {
        textAlign: "center"
    },
    categoryTitle: {
        backgroundColor: "#ddd",
        color: "black"
    },
    bold: {
        fontWeight: "bold"
    }
});

const columnDataIneligibles = [
    { id: "category", numeric: false, label: "Category" },
    { id: "name", numeric: false, label: "Name / Entity" },
    { id: "type", numeric: false, label: "Subcategory or Type" },
    { id: "description", numeric: false, label: "Description" },
    {
        id: "justification",
        numeric: false,
        label: "Justification Ineligible Expense or Classified Staff Request",
    }
];

class Budget_SummaryTab extends Component {
    state = {
        WarningDialogCheck: true,
    };
    typingTimer = [];

    componentDidMount() {
        const { handleUnsavedFields } = this.props;
        document.title = "Work Plan: Budget Summary ";
        this.props.onRef(this);
        const _this = this;
        // FIXME: react-forms calls formDidUpdate when (if) validation is ran right away
        // making it appear there is a edited field even if there isn't
        // Reset fields to false to not require validation when mounted
        setTimeout(function() {
            handleUnsavedFields(false);
            if (_this.props.appContainer.current) {
                _this.props.appContainer.current.scrollTop();
            }
        }, 1);
    }

    componentWillUnmount() {
        this.props.onRef(undefined);
    }

    applyOther = (value, element) => {
        const { ormParentUpdateLocalOnly } = this.props;
        const { id } = this.props.plan;
        ormParentUpdateLocalOnly({
            id: id,
            [element]: value
        });
        this.setState({ foo: "bar" });
    };

    buildListObj = (list, title) => {
        var newObj = {
            total: 0.0,
            spent_total: 0.0,
            totalString: "",
            title: title,
            list: [...list]
        };

        list.map(x => {
            newObj.total += x.amount;
            newObj.spent_total += parseInt(x.amount_spent);
            if (x.masterCategory == null) {
                x.masterCategory = title;
            }
            x.amountString = this.formatMoney(x.amount);
            return x;
        });

        newObj.totalString = "$" + this.formatMoney(newObj.total);
        newObj.totalAmountString = "$" + this.formatMoney(newObj.spent_total);

        return newObj;
    };

    filterType = (list, type) => {
        return list.filter(function(el) {
            return el.type === type;
        });
    };

    filterNotType = (list, type) => {
        return list.filter(function(el) {
            return el.type !== type;
        });
    };

    filterCategory = (list, category) => {
        return list.filter(function(el) {
            return el.category === category;
        });
    };

    filterNotCategory = (list, category) => {
        return list.filter(function(el) {
            return el.category !== category;
        });
    };

    filterSource = (list, source) => {
        return list.filter(function(el) {
            return el.source === source;
        });
    };

    filterIneligible = list => {
        return list.filter(function(el) {
            return el.ineligible_expenses === true || el.classified === true;
        });
    };

    calcTotal = list => {
        var total = 0.0;
        var total_spent = 0.0;
        list.forEach(x => {
            if (x.amount) total += x.amount;
            if (x.amount_spent) total_spent += parseInt(x.amount_spent);
        });
        return [total, total_spent];
    };

    formatMoney = amount => {
        var amountnum = parseFloat(amount);
        var amountstring = amountnum.toFixed(0);
        if (amountnum >= 1000) {
            amountstring = amountstring.substring(0, amountstring.length - 3) + "," + amountstring.substring(amountstring.length - 3);
        }
        if (amountnum >= 1000000) {
            amountstring = amountstring.substring(0, amountstring.length - 7) + "," + amountstring.substring(amountstring.length - 7);
        }
        return amountstring;
    };

    handleWarningClose = () => {
        if (this.state.WarningDialogCheck) {
            this.setState({ WarningDialogCheck: false });
        }
    };
    updateObject = (val, element, id, ormUpdate, key) => {
        if (this.typingTimer[key]) clearTimeout(this.typingTimer[key]);
        this.typingTimer[key] = setTimeout(function() {
            ormUpdate({
                id: id,
                [element]: val
            });
        }, 500);
    };

    render() {
        const {
            classes,
            proposalpersonnel,
            proposalcontracts,
            proposalexpenditures,
            proposalacquisitions,
            proposaltravels,
            proposalprintings,
            proposalexpenses,
            proposalfunds,
            ActionMenu,
            history,
            handleNext,
            is_read_only,
            plan,
            ormParentUpdateLocalOnly,
            last_step,
            show_parcel,
            ormParentUpdate,
            ormFinalProposalContractUpdate,
            ormFinalProposalExpenditureUpdate,
            ormFinalProposalAcquisitionUpdate,
            ormFinalProposalTravelUpdate,
            ormFinalProposalPrintingUpdate,
            ormFinalProposalExpenseUpdate,
            ormFinalProposalNonENRTFFundUpdate,
            tab,
            statusUpdateTabs,
            proposal,
            authState
        } = this.props;
        const { id } = this.props.plan;
        
        var is_dnr = false;
        if (authState && authState.user && (authState.user.is_read_only || authState.user.role === "Staff")) is_dnr = true;

        const columnDataSummarys = [
            { id: "category", numeric: false, label: "Category" },
            { id: "name", numeric: false, label: "Name / Entity" },
            { id: "type", numeric: false, label: "Subcategory / Type" },
            { id: "description", numeric: false, label: "Description" },
            { id: "purpose", numeric: false, label: "Purpose" },
            { id: "ineligible", numeric: false, label: "Generally Ineligible" },
            { id: "benefits", numeric: false, label: "% Benefits" },
            { id: "fte", numeric: false, label: "FTE" },
            { id: "classified", numeric: false, label: "Classified?" },
            { id: "amount", numeric: false, label: "$ Amount" }
        ];
        const columnDataFunds = [
            { id: "source", numeric: false, label: "Source/Type" },
            { id: "specific_source", numeric: false, label: "Specific Source" },
            { id: "description", numeric: false, label: "Description" },
            { id: "status", numeric: false, label: "Status" },
            { id: "amount", numeric: false, label: "$ Amount" }
        ];
        if (proposal.status === "Final Work Plan Approved" || proposal.status === "Final Abstract Due" || proposal.status === "Final Abstract Submitted" || proposal.status === "Final Abstract Revisions Needed" || proposal.status === "Project Completed") {
            columnDataSummarys.push({ id: "amount_spent", numeric: false, label: "$ Amount Spent" });
            columnDataSummarys.push({
                id: "amount_remaining",
                numeric: false,
                label: "$ Amount Remaining",
            });
            columnDataFunds.push({ id: "amount_spent", numeric: false, label: "$ Amount Spent" });
            columnDataFunds.push({
                id: "amount_remaining",
                numeric: false,
                label: "$ Amount Remaining",
            });
        }

        var full_list = [
            ...proposalpersonnel,
            ...proposalcontracts,
            ...proposalexpenditures,
            ...proposalacquisitions,
            ...proposaltravels,
            ...proposalprintings,
            ...proposalexpenses
        ];

        var expenditures_filter_cap = this.filterCategory(proposalexpenditures, "Capital Expenditure");
        var expenditures_filter_equip = this.filterNotCategory(proposalexpenditures, "Capital Expenditure");

        var travels_filter_inmn = this.filterType(proposaltravels, "Travel In Minnesota");
        var travels_filter_outmn = this.filterType(proposaltravels, "Travel Outside Minnesota");

        var funds_filter_state = this.filterSource(proposalfunds, "State");
        var funds_filter_nonstate = this.filterSource(proposalfunds, "Non-State");

        var personnel = this.buildListObj(proposalpersonnel, "Personnel");
        var contracts = this.buildListObj(proposalcontracts, "Services and Subawards");
        var equip_expenditures = this.buildListObj(expenditures_filter_equip, "Equipment, Tools, and Supplies");
        var cap_expenditures = this.buildListObj(expenditures_filter_cap, "Capital Expenditures");
        var acquisitions = this.buildListObj(proposalacquisitions, "Acquisition and Stewardship");
        var inmn_travels = this.buildListObj(travels_filter_inmn, "Travel In Minnesota");
        var outmn_travels = this.buildListObj(travels_filter_outmn, "Travel Outside Minnesota");
        var printings = this.buildListObj(proposalprintings, "Printing and Publication");
        var other_expenses = this.buildListObj(proposalexpenses, "Other");

        var [summaryTotal, summarySpentTotal] = this.calcTotal(full_list);
        summarySpentTotal += parseInt(plan.personnel_amount_spent);
        var totalIsThousand = summaryTotal % 1000 === 0;
        var summaryTotalString = "$" + this.formatMoney(summaryTotal);
        var summarySpentTotalString = "$" + this.formatMoney(summarySpentTotal);

        var warningDialogOpen = false;
        if (!totalIsThousand && !is_read_only && this.state.WarningDialogCheck) warningDialogOpen = true;

        var ineligible_full_list = this.filterIneligible(full_list);
        var ineligibles = this.buildListObj(ineligible_full_list, "Classified Staff or Generally Ineligible Expenses");

        var state_funds = this.buildListObj(funds_filter_state, "State");
        var nonstate_funds = this.buildListObj(funds_filter_nonstate, "Non-State");

        var [nonenrtffundsTotal, nonenrtffundsSpentTotal] = this.calcTotal(proposalfunds);
        var nonenrtffundsTotalString = "$" + this.formatMoney(nonenrtffundsTotal);
        var nonenrtffundsSpentTotalString = "$" + this.formatMoney(nonenrtffundsSpentTotal);

        var total_project_cost = "$" + this.formatMoney(summaryTotal + nonenrtffundsTotal); 

        var is_staff = false;
        if (authState && authState.user && authState.user.role === "Staff") {
            is_staff = true;
        }

        return (
            <div>
                <Grid container spacing={16} style={{ marginTop: 0 }}>
                    <Grid item xs={12}>
                        <Typography variant="titleAction">Budget Summary</Typography>
                        {ActionMenu}
                    </Grid>
                    <Grid item xs={12} lg={12} xl={12}>
                        <div class="insborder">
                            <Typography>Budget Summary</Typography>
                            <Typography>
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: (proposal.status !== "Final Work Plan Approved" && proposal.status !== "Final Abstract Due" && proposal.status !== "Final Abstract Submitted" && proposal.status !== "Final Abstract Revisions Needed" && proposal.status !== "Project Completed")
                                            ? tab.sub_tabs.find(tht => tht.tab_name === "Summary").tab_help_texts.find(tht => tht.label === "Budget Summary")
                                                  .text
                                            : statusUpdateTabs.sub_tabs
                                                  .find(tht => tht.tab_name === "Summary")
                                                  .tab_help_texts.find(tht => tht.label === "Budget Summary").text
                                    }}
                                />
                            </Typography>
                        </div>
                    </Grid>
                    <Grid container spacing={12} className={classes.gridPaddingTop}>
                        <Grid item xs={12} lg={12} xl={12} className={classes.gridPaddingRight}>
                            <Table className={classes.table}>
                                <EnhancedTableHead stickyHeader columnData={columnDataSummarys} />
                                <TableBody>
                                    <TableRow>
                                        <TableCell colSpan={columnDataSummarys.length} className={classes.categoryTitle}>
                                            <b>{personnel.title}</b>
                                        </TableCell>
                                    </TableRow>
                                    {personnel.list.map(n => {
                                        return (
                                            <TableRow key={personnel.title + "_" + n.id.toString()}>
                                                <TableCell></TableCell>
                                                <TableCell>{n.position_name}</TableCell>
                                                <TableCell></TableCell>
                                                <TableCell>{n.project_role}</TableCell>
                                                <TableCell></TableCell>
                                                <TableCell className={classes.centerAlign}>{n.ineligible_expenses ? "X" : ""}</TableCell>
                                                <TableCell>
                                                    {n.benefits_pct < 1.0 ? parseInt(n.benefits_pct * 100) + "%" : parseInt(n.benefits_pct) + "%"}
                                                </TableCell>
                                                <TableCell>{n.fte}</TableCell>
                                                <TableCell className={classes.centerAlign}>{n.classified ? "X" : ""}</TableCell>
                                                <TableCell className={classes.amountCell}>{n.amount ? n.amountString : ""}</TableCell>
                                            </TableRow>
                                        );
                                    })}
                                    {personnel.list.length < 1 && (
                                        <TableRow>
                                            <TableCell colSpan={columnDataSummarys.length} className={classes.centerAlign}>
                                                <caption style={{display:"inline"}}>No Records</caption>
                                            </TableCell>
                                        </TableRow>
                                    )}
                                    <TableRow className={classes.totalRow}>
                                        <TableCell colSpan={9} className={classes.categoryTotalCell}>
                                            <b>{personnel.title + " Sub Total"}</b>
                                        </TableCell>
                                        <TableCell className={classes.totalCell}>
                                            <b>{personnel.totalString}</b>
                                        </TableCell>
                                        {(proposal.status === "Final Work Plan Approved" || proposal.status === "Final Abstract Due" || proposal.status === "Final Abstract Submitted" || proposal.status === "Final Abstract Revisions Needed" || proposal.status === "Project Completed") && personnel.list.length > 0 && (
                                            <>
                                                <TableCell className={classes.totalCell}>
                                                    <Form key={"personnel"} defaultValues={plan}>
                                                        {formApi => (
                                                            <form onSubmit={formApi.submitForm}>
                                                                <TextField
                                                                    field="personnel_amount_spent"
                                                                    useTextFormat
                                                                    numberRange
                                                                    max={personnel.total}
                                                                    tableFormat
                                                                    style={{ width: 150 }}
                                                                    eventHandle={(e, field_name) => {
                                                                        if (!e) e = 0;
                                                                        this.updateObject(e, field_name, plan.id, ormParentUpdate, "personnel");
                                                                    }}
                                                                    disabled={
                                                                        (plan.reporting_status !== "Update Due" && plan.reporting_status !== "Final Update Due" &&
                                                                        plan.reporting_status !== "Additional Update In Progress" &&
                                                                        plan.reporting_status !== "Update Revisions Needed" &&
                                                                        plan.reporting_status !== "Final Update Revisions Needed" &&
                                                                        plan.status !== "Amendment In Progress" &&
                                                                        plan.status !== "Amendment Revisions Needed" &&
                                                                        plan.reporting_status !== "Additional Update Revisions Needed") || 
                                                                        is_dnr
                                                                    }
                                                                    label=""
                                                                />
                                                            </form>
                                                        )}
                                                    </Form>
                                                </TableCell>
                                                <TableCell className={classes.totalCell}>
                                                    <b>{"$" + this.formatMoney(personnel.total - parseInt(plan.personnel_amount_spent))}</b>
                                                </TableCell>
                                            </>
                                        )}
                                    </TableRow>
                                    <TableRow>
                                        <TableCell colSpan={columnDataSummarys.length} className={classes.categoryTitle}>
                                            <b>{contracts.title}</b>
                                        </TableCell>
                                    </TableRow>
                                    {contracts.list.map(n => {
                                        return (
                                            <TableRow key={contracts.title + "_" + n.id.toString()}>
                                                <TableCell></TableCell>
                                                <TableCell>{n.entity}</TableCell>
                                                <TableCell>{n.project_role}</TableCell>
                                                <TableCell>{n.description}</TableCell>
                                                <TableCell></TableCell>
                                                <TableCell className={classes.centerAlign}>{n.ineligible_expenses ? "X" : ""}</TableCell>
                                                <TableCell></TableCell>
                                                <TableCell>{n.fte}</TableCell>
                                                <TableCell></TableCell>
                                                <TableCell className={classes.amountCell}>{n.amount ? n.amountString : ""}</TableCell>
                                                {(proposal.status === "Final Work Plan Approved" || proposal.status === "Final Abstract Due" || proposal.status === "Final Abstract Submitted" || proposal.status === "Final Abstract Revisions Needed" || proposal.status === "Project Completed") && (
                                                    <>
                                                        <TableCell className={classes.totalCell}>
                                                            <Form key={"contracts" + n.id} defaultValues={n}>
                                                                {formApi => (
                                                                    <form onSubmit={formApi.submitForm}>
                                                                        <TextField
                                                                            field="amount_spent"
                                                                            useTextFormat
                                                                            numberRange
                                                                            max={n.amount}
                                                                            tableFormat
                                                                            style={{ width: 150 }}
                                                                            eventHandle={(e, field_name) => {
                                                                                if (!e) e = 0;
                                                                                this.updateObject(
                                                                                    e,
                                                                                    field_name,
                                                                                    n.id,
                                                                                    ormFinalProposalContractUpdate,
                                                                                    "contracts" + n.id
                                                                                );
                                                                            }}
                                                                            disabled={
                                                                                (plan.reporting_status !== "Update Due" && plan.reporting_status !== "Final Update Due" &&
                                                                                plan.reporting_status !== "Additional Update In Progress" &&
                                                                                plan.reporting_status !== "Update Revisions Needed" &&
                                                                                plan.reporting_status !== "Final Update Revisions Needed" &&
                                                                                plan.status !== "Amendment In Progress" &&
                                                                                plan.status !== "Amendment Revisions Needed" &&
                                                                                plan.reporting_status !== "Additional Update Revisions Needed") || 
                                                                                is_dnr
                                                                            }
                                                                            label=""
                                                                        />
                                                                    </form>
                                                                )}
                                                            </Form>
                                                        </TableCell>
                                                        <TableCell className={classes.totalCell}>
                                                            {n.amount ? this.formatMoney(n.amount - parseInt(n.amount_spent)) : ""}
                                                        </TableCell>
                                                    </>
                                                )}
                                            </TableRow>
                                        );
                                    })}
                                    {contracts.list.length < 1 && (
                                        <TableRow>
                                            <TableCell colSpan={columnDataSummarys.length} className={classes.centerAlign}>
                                                <caption style={{display:"inline"}}>No Records</caption>
                                            </TableCell>
                                        </TableRow>
                                    )}
                                    <TableRow className={classes.totalRow}>
                                        <TableCell colSpan={9} className={classes.categoryTotalCell}>
                                            <b>{contracts.title + " Sub Total"}</b>
                                        </TableCell>
                                        <TableCell className={classes.totalCell}>
                                            <b>{contracts.totalString}</b>
                                        </TableCell>
                                        {(proposal.status === "Final Work Plan Approved" || proposal.status === "Final Abstract Due" || proposal.status === "Final Abstract Submitted" || proposal.status === "Final Abstract Revisions Needed" || proposal.status === "Project Completed") && (
                                            <>
                                                <TableCell className={classes.totalCell}>
                                                    <b>{contracts.totalAmountString}</b>
                                                </TableCell>
                                                <TableCell className={classes.totalCell}>
                                                    <b>{contracts.total ? "$" + this.formatMoney(contracts.total - parseInt(contracts.spent_total)) : ""}</b>
                                                </TableCell>
                                            </>
                                        )}
                                    </TableRow>

                                    <TableRow>
                                        <TableCell colSpan={columnDataSummarys.length} className={classes.categoryTitle}>
                                            <b>{equip_expenditures.title}</b>
                                        </TableCell>
                                    </TableRow>
                                    {equip_expenditures.list.map(n => {
                                        return (
                                            <TableRow key={equip_expenditures.title + "_" + n.id.toString()}>
                                                <TableCell></TableCell>
                                                <TableCell></TableCell>
                                                <TableCell>{n.category}</TableCell>
                                                <TableCell>{n.description}</TableCell>
                                                <TableCell>{n.purpose}</TableCell>
                                                <TableCell className={classes.centerAlign}>{n.ineligible_expenses ? "X" : ""}</TableCell>
                                                <TableCell></TableCell>
                                                <TableCell></TableCell>
                                                <TableCell></TableCell>
                                                <TableCell className={classes.amountCell}>{n.amount ? n.amountString : ""}</TableCell>
                                                {(proposal.status === "Final Work Plan Approved" || proposal.status === "Final Abstract Due" || proposal.status === "Final Abstract Submitted" || proposal.status === "Final Abstract Revisions Needed" || proposal.status === "Project Completed") && (
                                                    <>
                                                        <TableCell className={classes.totalCell}>
                                                            <Form key={"equipment" + n.id} defaultValues={n}>
                                                                {formApi => (
                                                                    <form onSubmit={formApi.submitForm}>
                                                                        <TextField
                                                                            field="amount_spent"
                                                                            useTextFormat
                                                                            numberRange
                                                                            max={n.amount}
                                                                            tableFormat
                                                                            style={{ width: 150 }}
                                                                            eventHandle={(e, field_name) => {
                                                                                if (!e) e = 0;
                                                                                this.updateObject(
                                                                                    e,
                                                                                    field_name,
                                                                                    n.id,
                                                                                    ormFinalProposalExpenditureUpdate,
                                                                                    "equipment" + n.id
                                                                                );
                                                                            }}
                                                                            disabled={
                                                                                (plan.reporting_status !== "Update Due" && plan.reporting_status !== "Final Update Due" &&
                                                                                plan.reporting_status !== "Additional Update In Progress" &&
                                                                                plan.reporting_status !== "Update Revisions Needed" &&
                                                                                plan.reporting_status !== "Final Update Revisions Needed" &&
                                                                                plan.status !== "Amendment In Progress" &&
                                                                                plan.status !== "Amendment Revisions Needed" &&
                                                                                plan.reporting_status !== "Additional Update Revisions Needed") || 
                                                                                is_dnr
                                                                            }
                                                                            label=""
                                                                        />
                                                                    </form>
                                                                )}
                                                            </Form>
                                                        </TableCell>
                                                        <TableCell className={classes.totalCell}>
                                                            {n.amount ? this.formatMoney(n.amount - parseInt(n.amount_spent)) : ""}
                                                        </TableCell>
                                                    </>
                                                )}
                                            </TableRow>
                                        );
                                    })}
                                    {equip_expenditures.list.length < 1 && (
                                        <TableRow>
                                            <TableCell colSpan={columnDataSummarys.length} className={classes.centerAlign}>
                                                <caption style={{display:"inline"}}>No Records</caption>
                                            </TableCell>
                                        </TableRow>
                                    )}
                                    <TableRow className={classes.totalRow}>
                                        <TableCell colSpan={9} className={classes.categoryTotalCell}>
                                            <b>{equip_expenditures.title + " Sub Total"}</b>
                                        </TableCell>
                                        <TableCell className={classes.totalCell}>
                                            <b>{equip_expenditures.totalString}</b>
                                        </TableCell>
                                        {(proposal.status === "Final Work Plan Approved" || proposal.status === "Final Abstract Due" || proposal.status === "Final Abstract Submitted" || proposal.status === "Final Abstract Revisions Needed" || proposal.status === "Project Completed") && (
                                            <>
                                                <TableCell className={classes.totalCell}>
                                                    <b>{equip_expenditures.totalAmountString}</b>
                                                </TableCell>
                                                <TableCell className={classes.totalCell}>
                                                    <b>
                                                        {equip_expenditures.total
                                                            ? "$" + this.formatMoney(equip_expenditures.total - parseInt(equip_expenditures.spent_total))
                                                            : ""}
                                                    </b>
                                                </TableCell>
                                            </>
                                        )}
                                    </TableRow>

                                    <TableRow>
                                        <TableCell colSpan={columnDataSummarys.length} className={classes.categoryTitle}>
                                            <b>{cap_expenditures.title}</b>
                                        </TableCell>
                                    </TableRow>
                                    {cap_expenditures.list.map(n => {
                                        return (
                                            <TableRow key={cap_expenditures.title + "_" + n.id.toString()}>
                                                <TableCell></TableCell>
                                                <TableCell></TableCell>
                                                <TableCell>{n.category}</TableCell>
                                                <TableCell>{n.description}</TableCell>
                                                <TableCell>{n.purpose}</TableCell>
                                                <TableCell className={classes.centerAlign}>{n.ineligible_expenses ? "X" : ""}</TableCell>
                                                <TableCell></TableCell>
                                                <TableCell></TableCell>
                                                <TableCell></TableCell>
                                                <TableCell className={classes.amountCell}>{n.amount ? n.amountString : ""}</TableCell>
                                                {(proposal.status === "Final Work Plan Approved" || proposal.status === "Final Abstract Due" || proposal.status === "Final Abstract Submitted" || proposal.status === "Final Abstract Revisions Needed" || proposal.status === "Project Completed") && (
                                                    <>
                                                        <TableCell className={classes.totalCell}>
                                                            <Form key={"capital" + n.id} defaultValues={n}>
                                                                {formApi => (
                                                                    <form onSubmit={formApi.submitForm}>
                                                                        <TextField
                                                                            field="amount_spent"
                                                                            useTextFormat
                                                                            numberRange
                                                                            max={n.amount}
                                                                            tableFormat
                                                                            style={{ width: 150 }}
                                                                            eventHandle={(e, field_name) => {
                                                                                if (!e) e = 0;
                                                                                this.updateObject(
                                                                                    e,
                                                                                    field_name,
                                                                                    n.id,
                                                                                    ormFinalProposalExpenditureUpdate,
                                                                                    "capital" + n.id
                                                                                );
                                                                            }}
                                                                            disabled={
                                                                                (plan.reporting_status !== "Update Due" && plan.reporting_status !== "Final Update Due" &&
                                                                                plan.reporting_status !== "Additional Update In Progress" &&
                                                                                plan.reporting_status !== "Update Revisions Needed" &&
                                                                                plan.reporting_status !== "Final Update Revisions Needed" &&
                                                                                plan.status !== "Amendment In Progress" &&
                                                                                plan.status !== "Amendment Revisions Needed" &&
                                                                                plan.reporting_status !== "Additional Update Revisions Needed") || 
                                                                                is_dnr
                                                                            }
                                                                            label=""
                                                                        />
                                                                    </form>
                                                                )}
                                                            </Form>
                                                        </TableCell>
                                                        <TableCell className={classes.totalCell}>
                                                            {n.amount ? this.formatMoney(n.amount - parseInt(n.amount_spent)) : ""}
                                                        </TableCell>
                                                    </>
                                                )}
                                            </TableRow>
                                        );
                                    })}
                                    {cap_expenditures.list.length < 1 && (
                                        <TableRow>
                                            <TableCell colSpan={columnDataSummarys.length} className={classes.centerAlign}>
                                                <caption style={{display:"inline"}}>No Records</caption>
                                            </TableCell>
                                        </TableRow>
                                    )}
                                    <TableRow className={classes.totalRow}>
                                        <TableCell colSpan={9} className={classes.categoryTotalCell}>
                                            <b>{cap_expenditures.title + " Sub Total"}</b>
                                        </TableCell>
                                        <TableCell className={classes.totalCell}>
                                            <b>{cap_expenditures.totalString}</b>
                                        </TableCell>
                                        {(proposal.status === "Final Work Plan Approved" || proposal.status === "Final Abstract Due" || proposal.status === "Final Abstract Submitted" || proposal.status === "Final Abstract Revisions Needed" || proposal.status === "Project Completed") && (
                                            <>
                                                <TableCell className={classes.totalCell}>
                                                    <b>{cap_expenditures.totalAmountString}</b>
                                                </TableCell>
                                                <TableCell className={classes.totalCell}>
                                                    <b>
                                                        {cap_expenditures.total
                                                            ? "$" + this.formatMoney(cap_expenditures.total - parseInt(cap_expenditures.spent_total))
                                                            : ""}
                                                    </b>
                                                </TableCell>
                                            </>
                                        )}
                                    </TableRow>

                                    <TableRow>
                                        <TableCell colSpan={columnDataSummarys.length} className={classes.categoryTitle}>
                                            <b>{acquisitions.title}</b>
                                        </TableCell>
                                    </TableRow>
                                    {acquisitions.list.map(n => {
                                        return (
                                            <TableRow key={acquisitions.title + "_" + n.id.toString()}>
                                                <TableCell></TableCell>
                                                <TableCell></TableCell>
                                                <TableCell>{n.category}</TableCell>
                                                <TableCell>
                                                    {n.description +
                                                        (n.acres ? " Acres: " + Number.parseFloat(n.acres).toFixed(1) + " " : "") +
                                                        (n.parcels ? " Parcels: " + n.parcels + " " : "") +
                                                        (n.miles ? " Miles: " + Number.parseFloat(n.miles).toFixed(1) : "")}
                                                </TableCell>
                                                <TableCell></TableCell>
                                                <TableCell className={classes.centerAlign}>{n.ineligible_expenses ? "X" : ""}</TableCell>
                                                <TableCell></TableCell>
                                                <TableCell></TableCell>
                                                <TableCell></TableCell>
                                                <TableCell className={classes.amountCell}>{n.amount ? n.amountString : ""}</TableCell>
                                                {(proposal.status === "Final Work Plan Approved" || proposal.status === "Final Abstract Due" || proposal.status === "Final Abstract Submitted" || proposal.status === "Final Abstract Revisions Needed" || proposal.status === "Project Completed") && (
                                                    <>
                                                        <TableCell className={classes.totalCell}>
                                                            <Form key={"acquisition" + n.id} defaultValues={n}>
                                                                {formApi => (
                                                                    <form onSubmit={formApi.submitForm}>
                                                                        <TextField
                                                                            field="amount_spent"
                                                                            useTextFormat
                                                                            numberRange
                                                                            max={n.amount}
                                                                            tableFormat
                                                                            style={{ width: 150 }}
                                                                            eventHandle={(e, field_name) => {
                                                                                if (!e) e = 0;
                                                                                this.updateObject(
                                                                                    e,
                                                                                    field_name,
                                                                                    n.id,
                                                                                    ormFinalProposalAcquisitionUpdate,
                                                                                    "acquisition" + n.id
                                                                                );
                                                                            }}
                                                                            disabled={
                                                                                (plan.reporting_status !== "Update Due" && plan.reporting_status !== "Final Update Due" &&
                                                                                plan.reporting_status !== "Additional Update In Progress" &&
                                                                                plan.reporting_status !== "Update Revisions Needed" &&
                                                                                plan.reporting_status !== "Final Update Revisions Needed" &&
                                                                                plan.status !== "Amendment In Progress" &&
                                                                                plan.status !== "Amendment Revisions Needed" &&
                                                                                plan.reporting_status !== "Additional Update Revisions Needed") || 
                                                                                is_dnr
                                                                            }
                                                                            label=""
                                                                        />
                                                                    </form>
                                                                )}
                                                            </Form>
                                                        </TableCell>
                                                        <TableCell className={classes.totalCell}>
                                                            {n.amount ? this.formatMoney(n.amount - parseInt(n.amount_spent)) : ""}
                                                        </TableCell>
                                                    </>
                                                )}
                                            </TableRow>
                                        );
                                    })}
                                    {acquisitions.list.length < 1 && (
                                        <TableRow>
                                            <TableCell colSpan={columnDataSummarys.length} className={classes.centerAlign}>
                                                <caption style={{display:"inline"}}>No Records</caption>
                                            </TableCell>
                                        </TableRow>
                                    )}
                                    <TableRow className={classes.totalRow}>
                                        <TableCell colSpan={9} className={classes.categoryTotalCell}>
                                            <b>{acquisitions.title + " Sub Total"}</b>
                                        </TableCell>
                                        <TableCell className={classes.totalCell}>
                                            <b>{acquisitions.totalString}</b>
                                        </TableCell>
                                        {(proposal.status === "Final Work Plan Approved" || proposal.status === "Final Abstract Due" || proposal.status === "Final Abstract Submitted" || proposal.status === "Final Abstract Revisions Needed" || proposal.status === "Project Completed") && (
                                            <>
                                                <TableCell className={classes.totalCell}>
                                                    <b>{acquisitions.totalAmountString}</b>
                                                </TableCell>
                                                <TableCell className={classes.totalCell}>
                                                    <b>
                                                        {acquisitions.total
                                                            ? "$" + this.formatMoney(acquisitions.total - parseInt(acquisitions.spent_total))
                                                            : ""}
                                                    </b>
                                                </TableCell>
                                            </>
                                        )}
                                    </TableRow>
                                    <TableRow>
                                        <TableCell colSpan={columnDataSummarys.length} className={classes.categoryTitle}>
                                            <b>{inmn_travels.title}</b>
                                        </TableCell>
                                    </TableRow>
                                    {inmn_travels.list.map(n => {
                                        return (
                                            <TableRow key={inmn_travels.title + "_" + n.id.toString()}>
                                                <TableCell></TableCell>
                                                <TableCell></TableCell>
                                                <TableCell>{n.category}</TableCell>
                                                <TableCell>{n.description}</TableCell>
                                                <TableCell>{n.purpose}</TableCell>
                                                <TableCell className={classes.centerAlign}>{n.ineligible_expenses ? "X" : ""}</TableCell>
                                                <TableCell></TableCell>
                                                <TableCell></TableCell>
                                                <TableCell></TableCell>
                                                <TableCell className={classes.amountCell}>{n.amount ? n.amountString : ""}</TableCell>
                                                {(proposal.status === "Final Work Plan Approved" || proposal.status === "Final Abstract Due" || proposal.status === "Final Abstract Submitted" || proposal.status === "Final Abstract Revisions Needed" || proposal.status === "Project Completed") && (
                                                    <>
                                                        <TableCell className={classes.totalCell}>
                                                            <Form key={"travel" + n.id} defaultValues={n}>
                                                                {formApi => (
                                                                    <form onSubmit={formApi.submitForm}>
                                                                        <TextField
                                                                            field="amount_spent"
                                                                            useTextFormat
                                                                            numberRange
                                                                            max={n.amount}
                                                                            tableFormat
                                                                            style={{ width: 150 }}
                                                                            eventHandle={(e, field_name) => {
                                                                                if (!e) e = 0;
                                                                                this.updateObject(
                                                                                    e,
                                                                                    field_name,
                                                                                    n.id,
                                                                                    ormFinalProposalTravelUpdate,
                                                                                    "travel" + n.id
                                                                                );
                                                                            }}
                                                                            disabled={
                                                                                (plan.reporting_status !== "Update Due" && plan.reporting_status !== "Final Update Due" &&
                                                                                plan.reporting_status !== "Additional Update In Progress" &&
                                                                                plan.reporting_status !== "Update Revisions Needed" &&
                                                                                plan.reporting_status !== "Final Update Revisions Needed" &&
                                                                                plan.status !== "Amendment In Progress" &&
                                                                                plan.status !== "Amendment Revisions Needed" &&
                                                                                plan.reporting_status !== "Additional Update Revisions Needed") || 
                                                                                is_dnr
                                                                            }
                                                                            label=""
                                                                        />
                                                                    </form>
                                                                )}
                                                            </Form>
                                                        </TableCell>
                                                        <TableCell className={classes.totalCell}>
                                                            {n.amount ? this.formatMoney(n.amount - parseInt(n.amount_spent)) : ""}
                                                        </TableCell>
                                                    </>
                                                )}
                                            </TableRow>
                                        );
                                    })}
                                    {inmn_travels.list.length < 1 && (
                                        <TableRow>
                                            <TableCell colSpan={columnDataSummarys.length} className={classes.centerAlign}>
                                                <caption style={{display:"inline"}}>No Records</caption>
                                            </TableCell>
                                        </TableRow>
                                    )}
                                    <TableRow className={classes.totalRow}>
                                        <TableCell colSpan={9} className={classes.categoryTotalCell}>
                                            <b>{inmn_travels.title + " Sub Total"}</b>
                                        </TableCell>
                                        <TableCell className={classes.totalCell}>
                                            <b>{inmn_travels.totalString}</b>
                                        </TableCell>
                                        {(proposal.status === "Final Work Plan Approved" || proposal.status === "Final Abstract Due" || proposal.status === "Final Abstract Submitted" || proposal.status === "Final Abstract Revisions Needed" || proposal.status === "Project Completed") && (
                                            <>
                                                <TableCell className={classes.totalCell}>
                                                    <b>{inmn_travels.totalAmountString}</b>
                                                </TableCell>
                                                <TableCell className={classes.totalCell}>
                                                    <b>
                                                        {inmn_travels.total
                                                            ? "$" + this.formatMoney(inmn_travels.total - parseInt(inmn_travels.spent_total))
                                                            : ""}
                                                    </b>
                                                </TableCell>
                                            </>
                                        )}
                                    </TableRow>

                                    <TableRow>
                                        <TableCell colSpan={columnDataSummarys.length} className={classes.categoryTitle}>
                                            <b>{outmn_travels.title}</b>
                                        </TableCell>
                                    </TableRow>
                                    {outmn_travels.list.map(n => {
                                        return (
                                            <TableRow key={outmn_travels.title + "_" + n.id.toString()}>
                                                <TableCell></TableCell>
                                                <TableCell></TableCell>
                                                <TableCell>{n.category}</TableCell>
                                                <TableCell>{n.description}</TableCell>
                                                <TableCell>{n.purpose}</TableCell>
                                                <TableCell className={classes.centerAlign}>{n.ineligible_expenses ? "X" : ""}</TableCell>
                                                <TableCell></TableCell>
                                                <TableCell></TableCell>
                                                <TableCell></TableCell>
                                                <TableCell className={classes.amountCell}>{n.amount ? n.amountString : ""}</TableCell>
                                                {(proposal.status === "Final Work Plan Approved" || proposal.status === "Final Abstract Due" || proposal.status === "Final Abstract Submitted" || proposal.status === "Final Abstract Revisions Needed" || proposal.status === "Project Completed") && (
                                                    <>
                                                        <TableCell className={classes.totalCell}>
                                                            <Form key={"outtravel" + n.id} defaultValues={n}>
                                                                {formApi => (
                                                                    <form onSubmit={formApi.submitForm}>
                                                                        <TextField
                                                                            field="amount_spent"
                                                                            useTextFormat
                                                                            numberRange
                                                                            max={n.amount}
                                                                            tableFormat
                                                                            style={{ width: 150 }}
                                                                            eventHandle={(e, field_name) => {
                                                                                if (!e) e = 0;
                                                                                this.updateObject(
                                                                                    e,
                                                                                    field_name,
                                                                                    n.id,
                                                                                    ormFinalProposalTravelUpdate,
                                                                                    "outtravel" + n.id
                                                                                );
                                                                            }}
                                                                            disabled={
                                                                                (plan.reporting_status !== "Update Due" && plan.reporting_status !== "Final Update Due" &&
                                                                                plan.reporting_status !== "Additional Update In Progress" &&
                                                                                plan.reporting_status !== "Update Revisions Needed" &&
                                                                                plan.reporting_status !== "Final Update Revisions Needed" &&
                                                                                plan.status !== "Amendment In Progress" &&
                                                                                plan.status !== "Amendment Revisions Needed" &&
                                                                                plan.reporting_status !== "Additional Update Revisions Needed") || 
                                                                                is_dnr
                                                                            }
                                                                            label=""
                                                                        />
                                                                    </form>
                                                                )}
                                                            </Form>
                                                        </TableCell>
                                                        <TableCell className={classes.totalCell}>
                                                            {n.amount ? this.formatMoney(n.amount - parseInt(n.amount_spent)) : ""}
                                                        </TableCell>
                                                    </>
                                                )}
                                            </TableRow>
                                        );
                                    })}
                                    {outmn_travels.list.length < 1 && (
                                        <TableRow>
                                            <TableCell colSpan={columnDataSummarys.length} className={classes.centerAlign}>
                                                <caption style={{display:"inline"}}>No Records</caption>
                                            </TableCell>
                                        </TableRow>
                                    )}
                                    <TableRow className={classes.totalRow}>
                                        <TableCell colSpan={9} className={classes.categoryTotalCell}>
                                            <b>{outmn_travels.title + " Sub Total"}</b>
                                        </TableCell>
                                        <TableCell className={classes.totalCell}>
                                            <b>{outmn_travels.totalString}</b>
                                        </TableCell>
                                        {(proposal.status === "Final Work Plan Approved" || proposal.status === "Final Abstract Due" || proposal.status === "Final Abstract Submitted" || proposal.status === "Final Abstract Revisions Needed" || proposal.status === "Project Completed") && (
                                            <>
                                                <TableCell className={classes.totalCell}>
                                                    <b>{outmn_travels.totalAmountString}</b>
                                                </TableCell>
                                                <TableCell className={classes.totalCell}>
                                                    <b>
                                                        {outmn_travels.total
                                                            ? "$" + this.formatMoney(outmn_travels.total - parseInt(outmn_travels.spent_total))
                                                            : ""}
                                                    </b>
                                                </TableCell>
                                            </>
                                        )}
                                    </TableRow>

                                    <TableRow>
                                        <TableCell colSpan={columnDataSummarys.length} className={classes.categoryTitle}>
                                            <b>{printings.title}</b>
                                        </TableCell>
                                    </TableRow>
                                    {printings.list.map(n => {
                                        return (
                                            <TableRow key={printings.title + "_" + n.id.toString()}>
                                                <TableCell></TableCell>
                                                <TableCell></TableCell>
                                                <TableCell>{n.category}</TableCell>
                                                <TableCell>{n.description}</TableCell>
                                                <TableCell>{n.purpose}</TableCell>
                                                <TableCell className={classes.centerAlign}>{n.ineligible_expenses ? "X" : ""}</TableCell>
                                                <TableCell></TableCell>
                                                <TableCell></TableCell>
                                                <TableCell></TableCell>
                                                <TableCell className={classes.amountCell}>{n.amount ? n.amountString : ""}</TableCell>
                                                {(proposal.status === "Final Work Plan Approved" || proposal.status === "Final Abstract Due" || proposal.status === "Final Abstract Submitted" || proposal.status === "Final Abstract Revisions Needed" || proposal.status === "Project Completed") && (
                                                    <>
                                                        <TableCell className={classes.totalCell}>
                                                            <Form key={"printing" + n.id} defaultValues={n}>
                                                                {formApi => (
                                                                    <form onSubmit={formApi.submitForm}>
                                                                        <TextField
                                                                            field="amount_spent"
                                                                            useTextFormat
                                                                            numberRange
                                                                            max={n.amount}
                                                                            tableFormat
                                                                            style={{ width: 150 }}
                                                                            eventHandle={(e, field_name) => {
                                                                                if (!e) e = 0;
                                                                                this.updateObject(
                                                                                    e,
                                                                                    field_name,
                                                                                    n.id,
                                                                                    ormFinalProposalPrintingUpdate,
                                                                                    "printing" + n.id
                                                                                );
                                                                            }}
                                                                            disabled={
                                                                                (plan.reporting_status !== "Update Due" && plan.reporting_status !== "Final Update Due" &&
                                                                                plan.reporting_status !== "Additional Update In Progress" &&
                                                                                plan.reporting_status !== "Update Revisions Needed" &&
                                                                                plan.reporting_status !== "Final Update Revisions Needed" &&
                                                                                plan.status !== "Amendment In Progress" &&
                                                                                plan.status !== "Amendment Revisions Needed" &&
                                                                                plan.reporting_status !== "Additional Update Revisions Needed") || 
                                                                                is_dnr
                                                                            }
                                                                            label=""
                                                                        />
                                                                    </form>
                                                                )}
                                                            </Form>
                                                        </TableCell>
                                                        <TableCell className={classes.totalCell}>
                                                            {n.amount ? this.formatMoney(n.amount - parseInt(n.amount_spent)) : ""}
                                                        </TableCell>
                                                    </>
                                                )}
                                            </TableRow>
                                        );
                                    })}
                                    {printings.list.length < 1 && (
                                        <TableRow>
                                            <TableCell colSpan={columnDataSummarys.length} className={classes.centerAlign}>
                                                <caption style={{display:"inline"}}>No Records</caption>
                                            </TableCell>
                                        </TableRow>
                                    )}
                                    <TableRow className={classes.totalRow}>
                                        <TableCell colSpan={9} className={classes.categoryTotalCell}>
                                            <b>{printings.title + " Sub Total"}</b>
                                        </TableCell>
                                        <TableCell className={classes.totalCell}>
                                            <b>{printings.totalString}</b>
                                        </TableCell>
                                        {(proposal.status === "Final Work Plan Approved" || proposal.status === "Final Abstract Due" || proposal.status === "Final Abstract Submitted" || proposal.status === "Final Abstract Revisions Needed" || proposal.status === "Project Completed") && (
                                            <>
                                                <TableCell className={classes.totalCell}>
                                                    <b>{printings.totalAmountString}</b>
                                                </TableCell>
                                                <TableCell className={classes.totalCell}>
                                                    <b>{printings.total ? "$" + this.formatMoney(printings.total - parseInt(printings.spent_total)) : ""}</b>
                                                </TableCell>
                                            </>
                                        )}
                                    </TableRow>

                                    <TableRow>
                                        <TableCell colSpan={columnDataSummarys.length} className={classes.categoryTitle}>
                                            <b>{other_expenses.title}</b>
                                        </TableCell>
                                    </TableRow>
                                    {other_expenses.list.map(n => {
                                        return (
                                            <TableRow key={other_expenses.title + "_" + n.id.toString()}>
                                                <TableCell></TableCell>
                                                <TableCell></TableCell>
                                                <TableCell></TableCell>
                                                <TableCell>{n.description}</TableCell>
                                                <TableCell>{n.purpose}</TableCell>
                                                <TableCell className={classes.centerAlign}>{n.ineligible_expenses ? "X" : ""}</TableCell>
                                                <TableCell></TableCell>
                                                <TableCell></TableCell>
                                                <TableCell></TableCell>
                                                <TableCell className={classes.amountCell}>{n.amount ? n.amountString : ""}</TableCell>
                                                {(proposal.status === "Final Work Plan Approved" || proposal.status === "Final Abstract Due" || proposal.status === "Final Abstract Submitted" || proposal.status === "Final Abstract Revisions Needed" || proposal.status === "Project Completed") && (
                                                    <>
                                                        <TableCell className={classes.totalCell}>
                                                            <Form key={"other" + n.id} defaultValues={n}>
                                                                {formApi => (
                                                                    <form onSubmit={formApi.submitForm}>
                                                                        <TextField
                                                                            field="amount_spent"
                                                                            useTextFormat
                                                                            numberRange
                                                                            max={n.amount}
                                                                            tableFormat
                                                                            style={{ width: 150 }}
                                                                            eventHandle={(e, field_name) => {
                                                                                if (!e) e = 0;
                                                                                this.updateObject(
                                                                                    e,
                                                                                    field_name,
                                                                                    n.id,
                                                                                    ormFinalProposalExpenseUpdate,
                                                                                    "other" + n.id
                                                                                );
                                                                            }}
                                                                            disabled={
                                                                                (plan.reporting_status !== "Update Due" && plan.reporting_status !== "Final Update Due" &&
                                                                                plan.reporting_status !== "Additional Update In Progress" &&
                                                                                plan.reporting_status !== "Update Revisions Needed" &&
                                                                                plan.reporting_status !== "Final Update Revisions Needed" &&
                                                                                plan.status !== "Amendment In Progress" &&
                                                                                plan.status !== "Amendment Revisions Needed" &&
                                                                                plan.reporting_status !== "Additional Update Revisions Needed") || 
                                                                                is_dnr
                                                                            }
                                                                            label=""
                                                                        />
                                                                    </form>
                                                                )}
                                                            </Form>
                                                        </TableCell>
                                                        <TableCell className={classes.totalCell}>
                                                            {n.amount ? this.formatMoney(n.amount - parseInt(n.amount_spent)) : ""}
                                                        </TableCell>
                                                    </>
                                                )}
                                            </TableRow>
                                        );
                                    })}
                                    {other_expenses.list.length < 1 && (
                                        <TableRow>
                                            <TableCell colSpan={columnDataSummarys.length} className={classes.centerAlign}>
                                                <caption style={{display:"inline"}}>No Records</caption>
                                            </TableCell>
                                        </TableRow>
                                    )}
                                    <TableRow className={classes.totalRow}>
                                        <TableCell colSpan={9} className={classes.categoryTotalCell}>
                                            <b>{other_expenses.title + " Sub Total"}</b>
                                        </TableCell>
                                        <TableCell className={classes.totalCell}>
                                            <b>{other_expenses.totalString}</b>
                                        </TableCell>
                                        {(proposal.status === "Final Work Plan Approved" || proposal.status === "Final Abstract Due" || proposal.status === "Final Abstract Submitted" || proposal.status === "Final Abstract Revisions Needed" || proposal.status === "Project Completed") && (
                                            <>
                                                <TableCell className={classes.totalCell}>
                                                    <b>{other_expenses.totalAmountString}</b>
                                                </TableCell>
                                                <TableCell className={classes.totalCell}>
                                                    <b>
                                                        {other_expenses.total
                                                            ? "$" + this.formatMoney(other_expenses.total - parseInt(other_expenses.spent_total))
                                                            : ""}
                                                    </b>
                                                </TableCell>
                                            </>
                                        )}
                                    </TableRow>

                                    <TableRow>
                                        <TableCell colSpan={columnDataSummarys.length} className={classes.categoryTitle}>
                                            <b>Total</b>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow className={classes.totalRow}>
                                        <TableCell colSpan={9} className={classes.categoryTotalCell}>
                                            <b>Total Project Budget</b>
                                        </TableCell>
                                        <TableCell className={classes.totalCell}>
                                            <b>{summaryTotalString}</b>
                                        </TableCell>
                                        {(proposal.status === "Final Work Plan Approved" || proposal.status === "Final Abstract Due" || proposal.status === "Final Abstract Submitted" || proposal.status === "Final Abstract Revisions Needed" || proposal.status === "Project Completed") && (
                                            <>
                                                <TableCell className={classes.totalCell}>
                                                    <b>{summarySpentTotalString}</b>
                                                </TableCell>
                                                <TableCell className={classes.totalCell}>
                                                    <b>{summaryTotal ? "$" + this.formatMoney(summaryTotal - summarySpentTotal) : ""}</b>
                                                </TableCell>
                                            </>
                                        )}
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </Grid>
                    </Grid>
                    <Grid container spacing={12} className={classes.gridPaddingTop}>
                        <Grid item xs={12} lg={12} xl={12} className={classes.gridPaddingRight}>
                            <Typography className={classes.label}>
                                <b>{ineligibles.title}</b>
                            </Typography>
                            <Table className={classes.table}>
                                <EnhancedTableHead stickyHeader columnData={columnDataIneligibles} />
                                <TableBody>
                                    {ineligibles.list.map(n => {
                                        return (
                                            <TableRow key={ineligibles.title + "_" + n.id.toString()}>
                                                <TableCell className={classes.stndCell}>{n.masterCategory}</TableCell>
                                                <TableCell className={classes.stndCell}>
                                                    {n.position_name}
                                                    {n.entity}
                                                </TableCell>
                                                <TableCell className={classes.stndCell}>
                                                    {n.masterCategory === "Personnel" ? null : n.project_role}
                                                    {n.masterCategory === "Capital Expenditure" ? n.masterCategory : null}
                                                    {n.category}
                                                </TableCell>
                                                <TableCell className={classes.medCell}>
                                                    {n.project_role && n.description ? null : n.project_role}
                                                    {n.description}
                                                </TableCell>
                                                <TableCell className={classes.wideCell}>
                                                    {n.justify_ineligible_expenses}
                                                    {n.ineligible_expenses_reasons}
                                                    {plan.ml_year < 2022 && (proposal.status !== "Final Work Plan Approved" && proposal.status !== "Final Abstract Due" && proposal.status !== "Final Abstract Submitted" && proposal.status !== "Final Abstract Revisions Needed" && proposal.status !== "Project Completed") && (
                                                        <span style={{ fontWeight: "bold", display: "block" }}>
                                                            {n.single_source === true ? "This is a single source contract." : ""}
                                                        </span>
                                                    )}
                                                    <span style={{ fontWeight: "bold", display: "block" }}>
                                                        {typeof n.classified_reasons == "string" && n.classified_reasons.length > 0 ? "Classified : " : ""}
                                                    </span>
                                                    {typeof n.classified_reasons == "string" && n.classified_reasons.length > 0 ? n.classified_reasons : null}
                                                    <span style={{ fontWeight: "bold", display: "block" }}>
                                                        {typeof n.explanation == "string" && n.explanation.length > 0 ? "Additional Explanation : " : ""}
                                                    </span>
                                                    {typeof n.explanation == "string" && n.explanation.length > 0 ? n.explanation : null}
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                    {ineligibles.list.length < 1 && (
                                        <TableRow>
                                            <TableCell colSpan={10} className={classes.centerAlign}>
                                                <caption style={{display:"inline"}}>No Records</caption>
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </Grid>
                    </Grid>
                    <Grid container spacing={12} className={classes.gridPaddingTop}>
                        <Grid item xs={12} lg={9} xl={7} className={classes.gridPaddingRight}>
                            <Typography className={classes.label}>
                                <b>Non-ENRTF Funds Contributed</b>
                            </Typography>
                            <Table className={classes.table}>
                                <EnhancedTableHead stickyHeader columnData={columnDataFunds} />
                                <TableBody>
                                    <TableRow>
                                        <TableCell colSpan={columnDataFunds.length} className={classes.categoryTitle}>
                                            <b>{state_funds.title}</b>
                                        </TableCell>
                                    </TableRow>
                                    {state_funds.list.map(n => {
                                        return (
                                            <TableRow key={state_funds.title + "_" + n.id.toString()}>
                                                <TableCell>{n.type}</TableCell>
                                                <TableCell>{n.specific_source}</TableCell>
                                                <TableCell>{n.description}</TableCell>
                                                <TableCell>
                                                    {!is_staff && !is_dnr && (proposal.status === "Final Work Plan Approved" || proposal.status === "Final Abstract Due" || proposal.status === "Final Abstract Submitted" || proposal.status === "Final Abstract Revisions Needed" || proposal.status === "Project Completed") &&
                                                    (plan.reporting_status === "Update Due" || plan.reporting_status === "Final Update Due" ||
                                                        plan.reporting_status === "Additional Update In Progress" ||
                                                        plan.status === "Amendment In Progress" ||
                                                        plan.status === "Amendment Revisions Needed" ||
                                                        plan.reporting_status === "Update Revisions Needed" || plan.reporting_status === "Final Update Revisions Needed" ||
                                                        plan.reporting_status === "Additional Update Revisions Needed") ? (
                                                        <Form key={n.id + "statestatus"} defaultValues={n}>
                                                            {formApi => (
                                                                <form onSubmit={formApi.submitForm}>
                                                                    <Select
                                                                        style={{ width: 200 }}
                                                                        tableFormat
                                                                        options={status_options}
                                                                        eventHandle={(e, field_name) => {
                                                                            this.updateObject(
                                                                                e,
                                                                                field_name,
                                                                                n.id,
                                                                                ormFinalProposalNonENRTFFundUpdate,
                                                                                n.id + "statestatus"
                                                                            );
                                                                        }}
                                                                        field="status"
                                                                        label=""
                                                                        fullWidth
                                                                    />
                                                                </form>
                                                            )}
                                                        </Form>
                                                    ) : (
                                                        n.status
                                                    )}
                                                </TableCell>
                                                <TableCell className={classes.amountCell}>{n.amount ? n.amountString : ""}</TableCell>
                                                {(proposal.status === "Final Work Plan Approved" || proposal.status === "Final Abstract Due" || proposal.status === "Final Abstract Submitted" || proposal.status === "Final Abstract Revisions Needed" || proposal.status === "Project Completed") && (
                                                    <>
                                                        <TableCell className={classes.totalCell}>
                                                            <Form key={"state" + n.id} defaultValues={n}>
                                                                {formApi => (
                                                                    <form onSubmit={formApi.submitForm}>
                                                                        <TextField
                                                                            field="amount_spent"
                                                                            useTextFormat
                                                                            numberRange
                                                                            max={n.amount}
                                                                            tableFormat
                                                                            style={{ width: 150 }}
                                                                            eventHandle={(e, field_name) => {
                                                                                if (!e) e = 0;
                                                                                this.updateObject(
                                                                                    e,
                                                                                    field_name,
                                                                                    n.id,
                                                                                    ormFinalProposalNonENRTFFundUpdate,
                                                                                    "state" + n.id
                                                                                );
                                                                            }}
                                                                            disabled={
                                                                                is_staff || is_dnr || (
                                                                                plan.reporting_status !== "Update Due" &&
                                                                                plan.reporting_status !== "Final Update Due" &&
                                                                                plan.reporting_status !== "Additional Update In Progress" &&
                                                                                plan.status !== "Amendment In Progress" &&
                                                                                plan.status !== "Amendment Revisions Needed" &&
                                                                                plan.reporting_status !== "Update Revisions Needed" &&
                                                                                plan.reporting_status !== "Final Update Revisions Needed" &&
                                                                                plan.reporting_status !== "Additional Update Revisions Needed"
                                                                                )}
                                                                            label=""
                                                                        />
                                                                    </form>
                                                                )}
                                                            </Form>
                                                        </TableCell>
                                                        <TableCell className={classes.totalCell}>
                                                            {n.amount ? this.formatMoney(n.amount - parseInt(n.amount_spent)) : ""}
                                                        </TableCell>
                                                    </>
                                                )}
                                            </TableRow>
                                        );
                                    })}
                                    {state_funds.list.length < 1 && (
                                        <TableRow>
                                            <TableCell colSpan={columnDataFunds.length} className={classes.centerAlign}>
                                                <caption style={{display:"inline"}}>No Records</caption>
                                            </TableCell>
                                        </TableRow>
                                    )}
                                    <TableRow className={classes.totalRow}>
                                        <TableCell colSpan={4} className={classes.categoryTotalCell}>
                                            <b>{state_funds.title + " Sub Total"}</b>
                                        </TableCell>
                                        <TableCell className={classes.totalCell}>
                                            <b>{state_funds.totalString}</b>
                                        </TableCell>
                                        {(proposal.status === "Final Work Plan Approved" || proposal.status === "Final Abstract Due" || proposal.status === "Final Abstract Submitted" || proposal.status === "Final Abstract Revisions Needed" || proposal.status === "Project Completed") && (
                                            <>
                                                <TableCell className={classes.totalCell}>
                                                    <b>{state_funds.totalAmountString}</b>
                                                </TableCell>
                                                <TableCell className={classes.totalCell}>
                                                    <b>
                                                        {state_funds.total ? "$" + this.formatMoney(state_funds.total - parseInt(state_funds.spent_total)) : ""}
                                                    </b>
                                                </TableCell>
                                            </>
                                        )}
                                    </TableRow>
                                    <TableRow>
                                        <TableCell colSpan={columnDataFunds.length} className={classes.categoryTitle}>
                                            <b>{nonstate_funds.title}</b>
                                        </TableCell>
                                    </TableRow>
                                    {nonstate_funds.list.map(n => {
                                        return (
                                            <TableRow key={nonstate_funds.title + "_" + n.id.toString()}>
                                                <TableCell>{n.type}</TableCell>
                                                <TableCell>{n.specific_source}</TableCell>
                                                <TableCell>{n.description}</TableCell>
                                                <TableCell>
                                                    {!is_staff && !is_dnr && (proposal.status === "Final Work Plan Approved" || proposal.status === "Final Abstract Due" || proposal.status === "Final Abstract Submitted" || proposal.status === "Final Abstract Revisions Needed" || proposal.status === "Project Completed") &&
                                                    (plan.reporting_status === "Update Due" || plan.reporting_status === "Final Update Due" ||
                                                        plan.reporting_status === "Additional Update In Progress" ||
                                                        plan.status === "Amendment In Progress" ||
                                                        plan.status === "Amendment Revisions Needed" ||
                                                        plan.reporting_status === "Update Revisions Needed" || plan.reporting_status === "Final Update Revisions Needed" ||
                                                        plan.reporting_status === "Additional Update Revisions Needed") ? (
                                                        <Form key={n.id + "nonstatestatus"} defaultValues={n}>
                                                            {formApi => (
                                                                <form onSubmit={formApi.submitForm}>
                                                                    <Select
                                                                        style={{ width: 200 }}
                                                                        tableFormat
                                                                        options={status_options}
                                                                        eventHandle={(e, field_name) => {
                                                                            this.updateObject(
                                                                                e,
                                                                                field_name,
                                                                                n.id,
                                                                                ormFinalProposalNonENRTFFundUpdate,
                                                                                n.id + "nonstatestatus"
                                                                            );
                                                                        }}
                                                                        field="status"
                                                                        label=""
                                                                        fullWidth
                                                                    />
                                                                </form>
                                                            )}
                                                        </Form>
                                                    ) : (
                                                        n.status
                                                    )}
                                                </TableCell>
                                                <TableCell className={classes.amountCell}>{n.amount ? n.amountString : ""}</TableCell>
                                                {(proposal.status === "Final Work Plan Approved" || proposal.status === "Final Abstract Due" || proposal.status === "Final Abstract Submitted" || proposal.status === "Final Abstract Revisions Needed" || proposal.status === "Project Completed") && (
                                                    <>
                                                        <TableCell className={classes.totalCell}>
                                                            <Form key={"nonstate" + n.id} defaultValues={n}>
                                                                {formApi => (
                                                                    <form onSubmit={formApi.submitForm}>
                                                                        <TextField
                                                                            field="amount_spent"
                                                                            useTextFormat
                                                                            numberRange
                                                                            max={n.amount}
                                                                            tableFormat
                                                                            style={{ width: 150 }}
                                                                            eventHandle={(e, field_name) => {
                                                                                if (!e) e = 0;
                                                                                this.updateObject(
                                                                                    e,
                                                                                    field_name,
                                                                                    n.id,
                                                                                    ormFinalProposalNonENRTFFundUpdate,
                                                                                    "nonstate" + n.id
                                                                                );
                                                                            }}
                                                                            disabled={
                                                                                is_staff || is_dnr || (
                                                                                plan.reporting_status !== "Update Due" &&
                                                                                plan.reporting_status !== "Final Update Due" &&
                                                                                plan.reporting_status !== "Additional Update In Progress" &&
                                                                                plan.status !== "Amendment In Progress" &&
                                                                                plan.status !== "Amendment Revisions Needed" &&
                                                                                plan.reporting_status !== "Update Revisions Needed" &&
                                                                                plan.reporting_status !== "Final Update Revisions Needed" &&
                                                                                plan.reporting_status !== "Additional Update Revisions Needed"
                                                                                )}
                                                                            label=""
                                                                        />
                                                                    </form>
                                                                )}
                                                            </Form>
                                                        </TableCell>
                                                        <TableCell className={classes.totalCell}>
                                                            {n.amount ? this.formatMoney(n.amount - parseInt(n.amount_spent)) : ""}
                                                        </TableCell>
                                                    </>
                                                )}
                                            </TableRow>
                                        );
                                    })}
                                    {nonstate_funds.list.length < 1 && (
                                        <TableRow>
                                            <TableCell colSpan={columnDataFunds.length} className={classes.centerAlign}>
                                                <caption style={{display:"inline"}}>No Records</caption>
                                            </TableCell>
                                        </TableRow>
                                    )}
                                    <TableRow className={classes.totalRow}>
                                        <TableCell colSpan={4} className={classes.categoryTotalCell}>
                                            <b>{nonstate_funds.title + " Sub Total"}</b>
                                        </TableCell>
                                        <TableCell className={classes.totalCell}>
                                            <b>{nonstate_funds.totalString}</b>
                                        </TableCell>
                                        {(proposal.status === "Final Work Plan Approved" || proposal.status === "Final Abstract Due" || proposal.status === "Final Abstract Submitted" || proposal.status === "Final Abstract Revisions Needed" || proposal.status === "Project Completed") && (
                                            <>
                                                <TableCell className={classes.totalCell}>
                                                    <b>{nonstate_funds.totalAmountString}</b>
                                                </TableCell>
                                                <TableCell className={classes.totalCell}>
                                                    <b>
                                                        {nonstate_funds.total
                                                            ? "$" + this.formatMoney(nonstate_funds.total - parseInt(nonstate_funds.spent_total))
                                                            : ""}
                                                    </b>
                                                </TableCell>
                                            </>
                                        )}
                                    </TableRow>
                                    <TableRow className={classes.totalRow}>
                                        <TableCell colSpan={4} className={classes.categoryTotalCell}>
                                            <b>Total</b>
                                        </TableCell>
                                        <TableCell className={classes.totalCell}>
                                            <b>{nonenrtffundsTotalString}</b>
                                        </TableCell>
                                        {(proposal.status === "Final Work Plan Approved" || proposal.status === "Final Abstract Due" || proposal.status === "Final Abstract Submitted" || proposal.status === "Final Abstract Revisions Needed" || proposal.status === "Project Completed") && (
                                            <>
                                                <TableCell className={classes.totalCell}>
                                                    <b>{nonenrtffundsSpentTotalString}</b>
                                                </TableCell>
                                                <TableCell className={classes.totalCell}>
                                                    <b>{nonenrtffundsTotal ? "$" + this.formatMoney(nonenrtffundsTotal - nonenrtffundsSpentTotal) : ""}</b>
                                                </TableCell>
                                            </>
                                        )}
                                    </TableRow>
                                </TableBody>
                            </Table>
                            {plan.ml_year >= 2025 && (
                                <Form key={"accurate"} defaultValues={plan}>
                                {formApi => (
                                    <form onSubmit={formApi.submitForm}>
                                        <>
                                        <div style={{float:"right"}}>
                                        <br/>
                                        <Typography style={{marginRight: 16}}>
                                            <b>Total Project Cost: {total_project_cost}</b>
                                        </Typography>
                                        </div>
                                        <div style={{float:"right", marginRight:40}}>
                                        <br/>
                                        <br/>
                                        <br/>
                                        <Switch 
                                        eventHandle={(e, field_name) => {
                                            this.updateObject(e, field_name, plan.id, ormParentUpdate, "accurate");
                                        }} field="accurate" label="This amount accurately reflects total project cost."/>
                                        </div>
                                        </>
                                </form>
                                )}
                                </Form>
                            )}
                        </Grid>
                    </Grid>

                    <Grid container spacing={8} className={classes.gridPaddingTop}>
                        {(proposal.status === "Final Work Plan Approved" || proposal.status === "Final Abstract Due" || proposal.status === "Final Abstract Submitted" || proposal.status === "Final Abstract Revisions Needed") && (
                            <Grid item xs={12} className={classes.rightAlign}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => {
                                        ormParentUpdateLocalOnly({
                                            id: id,
                                            activeStep: last_step,
                                            activeSubStep: 0
                                        });
                                    }}
                                    className={classes.button}>
                                    Jump to Written Status Report
                                </Button>
                                {!show_parcel && (
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => {
                                            ormParentUpdateLocalOnly({
                                                id: id,
                                                activeStep: 7,
                                                activeSubStep: 0
                                            });
                                        }}
                                        className={classes.button}>
                                        Jump to Acquisition or Restoration Status Report
                                    </Button>
                                )}
                            </Grid>
                        )}
                        <Grid item xs={12} className={classes.rightAlign}>
                            <Button variant="raised" disabled={is_read_only} onClick={() => history.push("/dashboard/")} className={classes.button}>
                                Save Draft and Return to Dashboard
                            </Button>
                            <Button variant="raised" disabled={is_read_only} color="primary" className={classes.button} onClick={() => handleNext()}>
                                Save and Proceed to Next Step
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
                <WarningDialog
                    noActions={true}
                    open={warningDialogOpen}
                    onClose={this.handleWarningClose}
                    htmlText={true}
                    title="Total ENRTF Funds Requested Warning"
                    text={
                        "The Total ENRTF Funds Requested Amount is not a round thousand dollars.<br/> Currently the total is <b>" +
                        summaryTotal +
                        "</b>. Please adjust your budget items so that the total request is to a round thousand dollar amount. " +
                        "You may adjust any item or combination of items in your budget."
                    }
                />
            </div>
        );
    }
}

Budget_SummaryTab = connect(
    (state, ownProps) => ({
        proposalpersonnel: getProposalPersonnel(state, ownProps),
        proposalcontracts: getProposalContract(state, ownProps),
        proposalexpenditures: getProposalExpenditure(state, ownProps),
        proposalacquisitions: getProposalAcquisition(state, ownProps),
        proposaltravels: getProposalTravel(state, ownProps),
        proposalprintings: getProposalPrinting(state, ownProps),
        proposalexpenses: getProposalExpense(state, ownProps),
        proposalfunds: getProposalNonENRTFFund(state, ownProps),
        tab: getTab(state, ownProps),
        statusUpdateTabs: getStatusUpdateTabs(state, ownProps),
        authState: state.auth
    }),
    {
        ...DraftWorkPlan.actions,
        ...FinalProposalContract.actions,
        ...FinalProposalExpenditure.actions,
        ...FinalProposalAcquisition.actions,
        ...FinalProposalTravel.actions,
        ...FinalProposalPrinting.actions,
        ...FinalProposalExpense.actions,
        ...FinalProposalNonENRTFFund.actions
    }
)(Budget_SummaryTab);

export default withStyles(styles)(withRouter(Budget_SummaryTab));
