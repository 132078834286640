import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Form } from "react-form";
import { connect } from "react-redux";

import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import MuiTooltip from "@material-ui/core/Tooltip";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import ViewIcon from "@material-ui/icons/RemoveRedEye";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from "@material-ui/icons/Close";
import { withStyles } from "@material-ui/core";

import RadioGroup from "../../common/RadioGroup";
import HelpLabel from "../../common/HelpLabel";
import EnhancedTableHead from "../../common/EnhancedTableHead";
import TableCell from "../../common/TableCell";
import Select from "../../common/Select";
import TextField from "../../common/TextField";
import Subquestion from "../../common/Subquestion";
import Checkbox from "../../common/Checkbox";
import WarningDialog from "../../common/WarningDialog";

import { getValue, setValue } from "../../../api/utils";
import { createSelector } from "../../common/orm";

import { MAKE_OPTIONS } from "../../../api/constants";
import { MAKE_OPTIONS_YESNO } from "../../../api/constants";
import { ProposalTravel } from "../models";
import { ProposalTab } from "../../home/models";

const getProposalTravels = createSelector(
    (state, ownProps) => parseInt(ownProps.match.params["id"]),
    (session, id) => {
        return session.ProposalTravel.filter({ proposal: id })
            .orderBy("id")
            .toModelArray();
    }
);
const getTab = ProposalTab.selectMessage(12);

const styles = theme => ({
    rightAlign: {
        textAlign: "right"
    },
    hide: {
        display: "none"
    },
    closeButton: {
        position: "absolute",
        right: 8,
        top: 8
    },
    button: {
        marginRight: theme.spacing.unit * 2
    },
    categoryTotalRow: {
        borderBottom: "none",
        height: 30
    },
    totalRow: {
        borderTop: "1px solid rgba(224, 224, 224, 1);",
        borderBottom: "1px solid rgba(224, 224, 224, 1);",
        height: 48
    },
    categoryTotalCell: {
        borderBottom: "none",
        textAlign: "right"
    },
    totalCell: {
        borderBottom: "none",
        textAlign: "right",
        width: 60
    },
    dialogTitleMargin: {
        marginRight: "48px"
    },
    amountCell: {
        width: 60,
        textAlign: "right"
    },
    centerAlign: {
        textAlign: "center"
    },
    nowrap: {
        whiteSpace: "nowrap"
    },
    deleteWidth: {
        minWidth: 34,
        width: 34,
        marginRight: 8
    }
});

const columnDataTravels = [
    { id: "actions", numeric: false, label: "Actions" },
    { id: "type", numeric: false, label: "Type" },
    { id: "category", numeric: false, label: "Category" },
    { id: "description", numeric: false, label: "Description" },
    { id: "purpose", numeric: false, label: "Purpose" },
    { id: "ineligible", numeric: false, label: "Generally Ineligible" },
    { id: "amount", numeric: false, label: "$ Amount" }
];

class BudgetTravelTab extends Component {
    state = {
        TravelDialogObj: null,
        WarningDialogObj: null
    };

    componentDidMount() {
        const { handleUnsavedFields } = this.props;
        document.title = "Proposal: Budget Travel and Conferences";
        this.props.onRef(this);
        const _this = this;
        // FIXME: react-forms calls formDidUpdate when (if) validation is ran right away
        // making it appear there is a edited field even if there isn't
        // Reset fields to false to not require validation when mounted
        setTimeout(function() {
            handleUnsavedFields(false);
            _this.props.appContainer.current.scrollTop();
        }, 1);
    }

    componentWillUnmount() {
        this.props.onRef(undefined);
    }

    errorValidator = values => {
        const { proposal } = this.props;
        var valObj = {};

        const isRequired = path => {
            let val = getValue(values, path);
            setValue(valObj, path, val || val === false ? null : "Required");
        };

        const isRequiredCheckbox = path => {
            let val = getValue(values, path);
            setValue(valObj, path, val ? null : "Required");
        };

        const isValidValue = path => {
            let val = getValue(values, path);
            if (!val && val !== 0) {
                setValue(valObj, path, "Required");
                return;
            }
            setValue(valObj, path, val >= 0 ? null : "Value must be >= 0.");
        };

        isRequired("type");
        isRequired("category");
        isValidValue("amount");

        if (
            proposal.org_data &&
            proposal.org_data.organization_name &&
            proposal.org_data.organization_name.includes("U of MN")
        ) {
            isRequiredCheckbox("understand_umn_policy");
            setValue(valObj, "understand_commissioner_plan", null);
        } else {
            isRequiredCheckbox("understand_commissioner_plan");
            setValue(valObj, "understand_umn_policy", null);
        }

        isRequired("ineligible_expenses");
        if (values.ineligible_expenses) {
            isRequired("justify_ineligible_expenses");
        } else {
            setValue(valObj, "justify_ineligible_expenses", null);
        }

        return valObj;
    };

    saveTravel(values) {
        const { id } = this.props.proposal;
        const { ormProposalTravelCreate, ormProposalTravelUpdate } = this.props;

        if (!values.ineligible_expenses) {
            values["justify_ineligible_expenses"] = null;
        }

        if (values.id) {
            ormProposalTravelUpdate({
                ...values
            });
            this.props.calcFundingTotal();
        } else {
            ormProposalTravelCreate({
                ...values,
                proposal: id
            }).then(response => {
                this.props.calcFundingTotal();
            });
        }
        this.setState({ TravelDialogObj: null });
    }

    deleteRecord = () => {
        this.props.ormProposalTravelPromiseDelete(this.state.WarningDialogObj.id).then(id => {
            this.props.calcFundingTotal();
        });
        this.setState({ WarningDialogObj: null });
    };

    cancelDeleteRecord = () => {
        this.setState({ WarningDialogObj: null });
    };

    applyOther = (value, element) => {
        const { ormProposalUpdateLocalOnly } = this.props;
        const { id } = this.props.proposal;
        ormProposalUpdateLocalOnly({
            id: id,
            [element]: value
        });
        this.setState({ foo: "bar" });
    };

    deleteTravel = n => {
        const { ormProposalTravelDelete } = this.props;
        ormProposalTravelDelete(n.id);
    };

    handleTypeUpdate = (e, formApi) => {
        formApi.setValue("type", e.target.value);

        if (e.target.value === "Travel Outside Minnesota") {
            formApi.setValue("ineligible_expenses", true);
        } else {
            formApi.setValue("ineligible_expenses", false);
        }
    };

    formatMoney = amount => {
        var amountnum = parseFloat(amount);
        var amountstring = amountnum.toFixed(2);
        if (amountnum >= 1000) {
            amountstring =
                amountstring.substring(0, amountstring.length - 6) +
                "," +
                amountstring.substring(amountstring.length - 6);
        }
        if (amountnum >= 1000000) {
            amountstring =
                amountstring.substring(0, amountstring.length - 10) +
                "," +
                amountstring.substring(amountstring.length - 10);
        }
        return amountstring;
    };

    render() {
        const { classes, proposal, proposaltravels, ActionMenu, history, handleNext, handlePrev, tab } = this.props;
        const is_read_only =
            (proposal.status !== "Draft In Progress" &&
                proposal.status !== "Draft Feedback Received" &&
                proposal.status !== "Revisions Needed") ||
            proposal.secondary_status === "Withdrawal Requested" ||
            proposal.secondary_status === "Withdrawn" ||
            (this.props.authState &&
                this.props.authState.user &&
                (this.props.authState.user.role === "Staff" || this.props.authState.user.role === "Member" || this.props.authState.user.is_read_only));

        const { TravelDialogObj, WarningDialogObj } = this.state;

        var total = 0.0;
        var cat1Total = 0.0;
        var cat2Total = 0.0;
        var cat3Total = 0.0;

        var interact_string = TravelDialogObj && TravelDialogObj["id"] ? "Edit" : "Add";
        if (is_read_only) {
            interact_string = "View";
        }

        proposaltravels.map(x => (total += x.amount));
        proposaltravels.forEach(x => {
            if (x.category === "Miles/Meals/Lodging") {
                cat1Total += x.amount;
            }
        });
        proposaltravels.forEach(x => {
            if (x.category === "Conference Registration Miles/Meals/Lodging") {
                cat2Total += x.amount;
            }
        });
        proposaltravels.forEach(x => {
            if (x.category === "Other") {
                cat3Total += x.amount;
            }
        });

        total = "$" + this.formatMoney(total);
        cat1Total = "$" + this.formatMoney(cat1Total);
        cat2Total = "$" + this.formatMoney(cat2Total);
        cat3Total = "$" + this.formatMoney(cat3Total);

        var isOrgUofMN = false;
        if (
            proposal.org_data &&
            proposal.org_data.organization_name &&
            proposal.org_data.organization_name.includes("U of MN")
        ) {
            isOrgUofMN = true;
        }

        return (
            <Grid container spacing={16} style={{ marginTop: 0 }}>
                <Grid item xs={12}>
                    <Typography variant="titleAction">Travel and Conference Details</Typography>
                    {ActionMenu}
                </Grid>
                <Grid item xs={12}>
                    <div class="insborder">
                        <Typography>
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: tab.sub_tabs
                                        .find(tht => tht.tab_name === "Travel and Conferences")
                                        .tab_help_texts.find(tht => tht.label === "Instructions and Requirements:").text
                                }}
                            />
                        </Typography>
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <Button
                        variant="contained"
                        type="submit"
                        color="primary"
                        disabled={is_read_only}
                        fullWidth
                        onClick={() => this.setState({ TravelDialogObj: { id: null, ineligible_expenses: false } })}>
                        <AddCircleOutlineIcon />
                        &nbsp;&nbsp; Add New Travel or Conference
                    </Button>
                    <br />
                    <br />
                    <Table className={classes.table}>
                        <EnhancedTableHead stickyHeader columnData={columnDataTravels} />
                        <TableBody>
                            {proposaltravels.map(n => {
                                return (
                                    <TableRow key={n.id}>
                                        <TableCell className={classes.nowrap}>
                                            <MuiTooltip title="Edit">
                                                <Button
                                                    className={is_read_only ? classes.hide : classes.deleteWidth}
                                                    aria-label="Edit"
                                                    onClick={() => this.setState({ TravelDialogObj: n })}>
                                                    <EditIcon color="primary" />
                                                </Button>
                                            </MuiTooltip>
                                            <MuiTooltip title="Delete">
                                                <Button
                                                    className={is_read_only ? classes.hide : classes.deleteWidth}
                                                    aria-label="Delete"
                                                    onClick={() => this.setState({ WarningDialogObj: n })}>
                                                    <DeleteIcon color="primary" />
                                                </Button>
                                            </MuiTooltip>
                                            <MuiTooltip title="View">
                                                <Button
                                                    className={!is_read_only ? classes.hide : classes.deleteWidth}
                                                    aria-label="View"
                                                    onClick={() => this.setState({ TravelDialogObj: n })}>
                                                    <ViewIcon color="primary" />
                                                </Button>
                                            </MuiTooltip>
                                        </TableCell>
                                        <TableCell>{n.type}</TableCell>
                                        <TableCell>{n.category}</TableCell>
                                        <TableCell>{n.description}</TableCell>
                                        <TableCell>{n.purpose}</TableCell>
                                        <TableCell className={classes.centerAlign}>
                                            {n.ineligible_expenses ? "X" : ""}
                                        </TableCell>
                                        <TableCell className={classes.amountCell}>
                                            {n.amount ? this.formatMoney(n.amount) : ""}
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                            {proposaltravels.length < 1 && (
                                <TableRow>
                                    <TableCell colSpan={7} className={classes.centerAlign}>
                                        <caption style={{display:"inline"}}>No Records</caption>
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                        {proposaltravels.length >= 1 && (
                            <TableBody>
                                <TableRow className={classes.categoryTotalRow}>
                                    <TableCell colSpan={6} className={classes.categoryTotalCell}>
                                        Miles/Meals/Lodging Total
                                    </TableCell>
                                    <TableCell className={classes.totalCell}>{cat1Total}</TableCell>
                                </TableRow>
                                <TableRow className={classes.categoryTotalRow}>
                                    <TableCell colSpan={6} className={classes.categoryTotalCell}>
                                        Conference Total
                                    </TableCell>
                                    <TableCell className={classes.totalCell}>{cat2Total}</TableCell>
                                </TableRow>
                                <TableRow className={classes.categoryTotalRow}>
                                    <TableCell colSpan={6} className={classes.categoryTotalCell}>
                                        Other Total
                                    </TableCell>
                                    <TableCell className={classes.totalCell}>{cat3Total}</TableCell>
                                </TableRow>
                                <TableRow className={classes.totalRow}>
                                    <TableCell colSpan={6} className={classes.categoryTotalCell}>
                                        <b>Total</b>
                                    </TableCell>
                                    <TableCell className={classes.totalCell}>
                                        <b>{total}</b>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        )}
                    </Table>
                </Grid>
                <Grid item xs={12} className={classes.rightAlign}>
                    <Button
                        variant="contained"
                        type="submit"
                        style={{ float: "left" }}
                        disabled={is_read_only}
                        onClick={() => handlePrev()}
                        className={classes.button}>
                        Save and Return to Previous Step
                    </Button>
                    <Button
                        variant="raised"
                        disabled={is_read_only}
                        onClick={() => history.push("/dashboard/")}
                        className={classes.button}>
                        Save Draft and Return to Dashboard
                    </Button>
                    <Button
                        variant="raised"
                        disabled={is_read_only}
                        color="primary"
                        className={classes.button}
                        onClick={() => handleNext()}>
                        Save and Proceed to Next Step
                    </Button>
                </Grid>
                <Dialog
                    fullWidth
                    maxWidth="sm"
                    open={TravelDialogObj}
                    onClose={() => this.setState({ TravelDialogObj: null })}>
                    <Form
                        defaultValues={TravelDialogObj}
                        validateOnSubmit={true}
                        dontValidateOnMount={true}
                        validateError={values => this.errorValidator(values)}
                        onSubmit={values => this.saveTravel(values)}>
                        {formApi => (
                            <form onSubmit={formApi.submitForm}>
                                <DialogTitle
                                    className={classes.dialogTitleMargin}
                                    disableTypography
                                    id="form-dialog-title">
                                    <Typography variant="h3">{interact_string} Travel and Conferences</Typography>
                                    <IconButton
                                        aria-label="Close Dialog"
                                        className={classes.closeButton}
                                        onClick={() => this.setState({ TravelDialogObj: null })}>
                                        <CloseIcon />
                                    </IconButton>
                                </DialogTitle>
                                <DialogContent>
                                    <Grid container spacing={8}>
                                        <Grid item xs={12}>
                                            <Select
                                                field="type"
                                                label="Type"
                                                fullWidth
                                                disabled={is_read_only}
                                                onChange={e => this.handleTypeUpdate(e, formApi)}
                                                options={MAKE_OPTIONS([
                                                    "Travel In Minnesota",
                                                    "Travel Outside Minnesota"
                                                ])}
                                            />
                                            <Select
                                                field="category"
                                                label="Category"
                                                fullWidth
                                                disabled={is_read_only}
                                                options={MAKE_OPTIONS([
                                                    "Miles/Meals/Lodging",
                                                    "Conference Registration Miles/Meals/Lodging",
                                                    "Other"
                                                ])}
                                            />
                                            <TextField
                                                field="description"
                                                fullWidth
                                                disabled={is_read_only}
                                                label="Description (provide quantity – e.g., number of trips, number of miles, number of people, rate)"
                                            />

                                            <TextField
                                                field="purpose"
                                                fullWidth
                                                disabled={is_read_only}
                                                label="Purpose (Explain purpose of item/s)"
                                            />
                                        </Grid>

                                        <Grid item xs={4}>
                                            <TextField
                                                field="amount"
                                                fullWidth
                                                disabled={is_read_only}
                                                useTextFormat
                                                label="Amount"
                                                margin="normal"
                                            />
                                        </Grid>

                                        <Grid item xs={12}>
                                            {isOrgUofMN ? (
                                                <Checkbox
                                                    field="understand_umn_policy"
                                                    fullWidth
                                                    disabled={is_read_only}>
                                                    <HelpLabel
                                                        inputLabel="Do you understand that travel expenses are only approved if they follow the University of Minnesota travel policy?"
                                                        showLabel={true}
                                                        htmlText={true}
                                                        helpText={
                                                            tab.sub_tabs
                                                                .find(tht => tht.tab_name === "Travel and Conferences")
                                                                .tab_help_texts.find(
                                                                    tht =>
                                                                        tht.label ===
                                                                        "Do you understand that travel expenses are only approved if they follow the University of Minnesota travel policy?"
                                                                ).text
                                                        }
                                                    />
                                                </Checkbox>
                                            ) : (
                                                <Checkbox
                                                    field="understand_commissioner_plan"
                                                    fullWidth
                                                    disabled={is_read_only}>
                                                    <HelpLabel
                                                        inputLabel="Do you understand that travel expenses are only approved if they follow the Commissioner's Plan promulgated by the Commissioner of Management of Budget?"
                                                        showLabel={true}
                                                        htmlText={true}
                                                        helpText={
                                                            tab.sub_tabs
                                                                .find(tht => tht.tab_name === "Travel and Conferences")
                                                                .tab_help_texts.find(
                                                                    tht =>
                                                                        tht.label ===
                                                                        "Do you understand that travel expenses are only approved if they follow the Commissioner's Plan promulgated by the Commissioner of Management of Budget?"
                                                                ).text
                                                        }
                                                    />
                                                </Checkbox>
                                            )}

                                            <RadioGroup
                                                field="ineligible_expenses"
                                                name="ineligible_expenses"
                                                label=""
                                                fullWidth
                                                disabled={is_read_only}
                                                options={MAKE_OPTIONS_YESNO([
                                                    { label: "Yes", value: "true", disabled: is_read_only },
                                                    { label: "No", value: "false", disabled: is_read_only }
                                                ])}
                                                alignment={true}>
                                                <HelpLabel
                                                    inputLabel="Generally Ineligible Expenses Justification"
                                                    showLabel={true}
                                                    htmlText={true}
                                                    helpText={
                                                        tab.sub_tabs
                                                            .find(tht => tht.tab_name === "Travel and Conferences")
                                                            .tab_help_texts.find(
                                                                tht =>
                                                                    tht.label ===
                                                                    "Generally Ineligible Expenses Justification"
                                                            ).text
                                                    }
                                                />
                                            </RadioGroup>
                                            {formApi.values.ineligible_expenses && (
                                                <Subquestion
                                                    component={
                                                        <TextField
                                                            field="justify_ineligible_expenses"
                                                            multiline
                                                            fullWidth
                                                            disabled={is_read_only}
                                                            label=""
                                                        />
                                                    }
                                                />
                                            )}
                                        </Grid>
                                    </Grid>
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={() => this.setState({ TravelDialogObj: null })}>Cancel</Button>
                                    <Button color="primary" variant="contained" type="submit" disabled={is_read_only}>
                                        Save
                                    </Button>
                                </DialogActions>
                            </form>
                        )}
                    </Form>
                </Dialog>

                <WarningDialog
                    confirmText={"Yes"}
                    confirmAction={this.deleteRecord}
                    cancelText={"No"}
                    cancelAction={this.cancelDeleteRecord}
                    open={!!WarningDialogObj}
                    title="Delete Contract record"
                    text={"Are you sure you want to delete this Contract record?"}
                />
            </Grid>
        );
    }
}

BudgetTravelTab = connect(
    (state, ownProps) => ({
        proposaltravels: getProposalTravels(state, ownProps),
        tab: getTab(state, ownProps),
        authState: state.auth
    }),
    {
        ...ProposalTravel.actions
    }
)(BudgetTravelTab);

export default withStyles(styles)(withRouter(BudgetTravelTab));
