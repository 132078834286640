import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Form } from "react-form";
import { connect } from "react-redux";
import classNames from "classnames";

import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import MuiTooltip from "@material-ui/core/Tooltip";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

import { withStyles } from "@material-ui/core";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import CloseIcon from "@material-ui/icons/Close";
import ViewIcon from "@material-ui/icons/RemoveRedEye";

import { ProposalExpenditure } from "../models";
import TextField from "../../common/TextField";
import RadioGroup from "../../common/RadioGroup";
import HelpLabel from "../../common/HelpLabel";
import Subquestion from "../../common/Subquestion";
import Select from "../../common/Select";
import EnhancedTableHead from "../../common/EnhancedTableHead";
import TableCell from "../../common/TableCell";
import Checkbox from "../../common/Checkbox";
import { getValue, setValue } from "../../../api/utils";
import { MAKE_OPTIONS_YESNO } from "../../../api/constants";
import WarningDialog from "../../common/WarningDialog";
import { createSelector } from "../../common/orm";
import { ProposalTab } from "../../home/models";

const category_options = [
    { value: "Capital Expenditure", label: "Capital Expenditure" },
    { value: "Equipment", label: "Equipment" },
    { value: "Tools and Supplies", label: "Tools and Supplies" }
];

const getExpenditures = createSelector(
    (state, ownProps) => parseInt(ownProps.match.params["id"]),
    (session, id) => {
        return session.ProposalExpenditure.filter({ proposal: id })
            .orderBy("id")
            .toModelArray();
    }
);
const getTab = ProposalTab.selectMessage(12);

const styles = theme => ({
    rightAlign: {
        textAlign: "right"
    },
    hide: {
        display: "none"
    },
    button: {
        marginRight: theme.spacing.unit * 2
    },
    dialogTitleMargin: {
        marginRight: "48px"
    },
    closeButton: {
        position: "absolute",
        right: 8,
        top: 8
    },
    nowrap: {
        whiteSpace: "nowrap"
    },
    deleteWidth: {
        minWidth: 34,
        width: 34,
        marginRight: 8
    },
    tableCellAlignRight: {
        textAlign: "right"
    },
    tableCellAlignCenter: {
        textAlign: "center"
    },
    tableCellNoBorder: {
        border: "none"
    },
    tableCellBold: {
        fontWeight: "Bold",
        color: "#000000"
    },
    centerAlign: {
        textAlign: "center"
    }
});

class Budget_ExpendituresTab extends Component {
    state = {
        ExpenditureDialogObj: null,
        WarningDialogObj: null,
        field_disabled: false
    };

    handleClose = () => {
        this.setState({ ExpenditureDialogObj: null });
    };

    componentDidMount() {
        const { handleUnsavedFields } = this.props;
        document.title = "Proposal: Budget Capital, Equipment, Tools, and Supplies - LCCMR";
        this.props.onRef(this);
        const _this = this;
        // FIXME: react-forms calls formDidUpdate when (if) validation is ran right away
        // making it appear there is a edited field even if there isn't
        // Reset fields to false to not require validation when mounted
        setTimeout(function() {
            handleUnsavedFields(false);
            _this.props.appContainer.current.scrollTop();
        }, 1);
    }

    componentWillUnmount() {
        this.props.onRef(undefined);
    }

    errorValidator = values => {
        var valObj = {};

        const isRequired = path => {
            let val = getValue(values, path);
            setValue(valObj, path, val || val === false ? null : "Required");
        };

        const isRequiredCheckbox = path => {
            let val = getValue(values, path);
            setValue(valObj, path, val ? null : "Required");
        };

        const isValidValue = path => {
            let val = getValue(values, path);
            if (!val && val !== 0) {
                setValue(valObj, path, "Required");
                return;
            }
            setValue(valObj, path, val >= 0 ? null : "Value must be >= 0.");
        };

        isRequired("category");
        isRequired("description");
        isRequired("purpose");
        isValidValue("amount");
        if (values.category === "Capital Expenditure") {
            isRequired("explanation");
            isRequiredCheckbox("understand_repayment");
        } else {
            setValue(valObj, "explanation", null);
            setValue(valObj, "understand_repayment", null);
        }
        isRequired("ineligible_expenses");
        if (values.category === "Capital Expenditure" && (values.ineligible_expenses === false || !values.ineligible_expenses)) {
            setValue(valObj, "ineligible_expenses", "Must be set to Yes for Capital Expenditure");
        }
        if (values.ineligible_expenses || values.category === "Capital Expenditure") {
            isRequired("justify_ineligible_expenses");
        } else {
            setValue(valObj, "justify_ineligible_expenses", null);
        }

        return valObj;
    };

    saveExpenditure(values) {
        const { id } = this.props.proposal;
        const { ormProposalExpenditureCreate, ormProposalExpenditureUpdate } = this.props;

        if (!values.ineligible_expenses) {
            values["justify_ineligible_expenses"] = null;
        }

        if (values.id) {
            ormProposalExpenditureUpdate({
                ...values
            });
            this.props.calcFundingTotal();
        } else {
            ormProposalExpenditureCreate({
                ...values,
                proposal: id
            }).then(response => {
                this.props.calcFundingTotal();
            });
        }
        this.setState({ ExpenditureDialogObj: null });
    }

    deleteRecord = () => {
        this.props.ormProposalExpenditurePromiseDelete(this.state.WarningDialogObj.id).then(id => {
            this.props.calcFundingTotal();
        });
        this.setState({ WarningDialogObj: null });
    };

    cancelDeleteRecord = () => {
        this.setState({ WarningDialogObj: null });
    };

    getTableData = expenditures => {
        var table_data = [];

        expenditures.forEach(c => {
            let newObj = { ...c };
            newObj["ineligible_expenses"] = c.ineligible_expenses ? "X" : "";
            table_data.push(newObj);
        });

        return table_data;
    };

    handleCategory = (val, formApi) => {
        if (val && val === "Capital Expenditure") {
            formApi.setValue("ineligible_expenses", true);
            this.setState({field_disabled: true});
        } else {
            this.setState({field_disabled: false});
        }
    };

    render() {
        const { classes, expenditures, ActionMenu, handleNext, handlePrev, history, proposal, tab } = this.props;
        const { ExpenditureDialogObj, WarningDialogObj, field_disabled } = this.state;
        const is_read_only =
            (proposal.status !== "Draft In Progress" &&
                proposal.status !== "Draft Feedback Received" &&
                proposal.status !== "Revisions Needed") ||
            proposal.secondary_status === "Withdrawal Requested" ||
            proposal.secondary_status === "Withdrawn" ||
            (this.props.authState &&
                this.props.authState.user &&
                (this.props.authState.user.role === "Staff" || this.props.authState.user.role === "Member" || this.props.authState.user.is_read_only));

        var interact_string = ExpenditureDialogObj && ExpenditureDialogObj["id"] ? "Edit" : "Add";
        if (is_read_only) {
            interact_string = "View";
        }

        const column_data = [
            { id: "actions", numeric: false, label: "Actions", width: "20px" },
            {
                id: "category",
                numeric: false,
                label: "Category",
            },
            {
                id: "description",
                numeric: false,
                label: "Description",
            },
            {
                id: "purpose",
                numeric: false,
                label: "Purpose",
            },
            {
                id: "ineligible_expenses",
                numeric: false,
                label: "Generally Ineligible",
            },
            {
                id: "amount",
                numeric: true,
                label: "$ Amount",
            }
        ];

        const table_data = this.getTableData(expenditures);

        let amtTotal = 0;
        table_data.forEach(d => {
            amtTotal += d.amount;
        });

        return (
            <Grid container spacing={16} style={{ marginTop: 0 }}>
                <Grid item xs={12}>
                    <Typography variant="titleAction">
                        Capital Expenditures, Equipment, Tools, and Supplies Details
                    </Typography>

                    {ActionMenu}
                </Grid>
                <Grid item xs={12}>
                    <div class="insborder">
                        <Typography>
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: tab.sub_tabs
                                        .find(tht => tht.tab_name === "Capital, Equipment, Tools, and Supplies")
                                        .tab_help_texts.find(tht => tht.label === "Instructions and Requirements").text
                                }}
                            />
                        </Typography>
                    </div>
                </Grid>

                <Grid item xs={12}>
                    <Button
                        fullWidth
                        variant="raised"
                        color="primary"
                        disabled={is_read_only}
                        onClick={() =>
                            this.setState({
                                ExpenditureDialogObj: { id: null, ineligible_expenses: false }
                            })
                        }>
                        <AddCircleIcon />
                        &nbsp;&nbsp;&nbsp;Add New Capital Expenditure, Equipment, Tools, and Supplies
                    </Button>
                </Grid>

                <Grid item xs={12}>
                    <Table className={classes.table}>
                        <EnhancedTableHead stickyHeader columnData={column_data} />
                        <TableBody>
                            {table_data.map(d => {
                                let pObj = expenditures.filter(o => o.id === d.id)[0];
                                return (
                                    <TableRow key={d.id}>
                                        <TableCell className={classes.nowrap}>
                                            <MuiTooltip title="Edit">
                                                <Button
                                                    className={is_read_only ? classes.hide : classes.deleteWidth}
                                                    aria-label="Edit"
                                                    onClick={n => this.setState({ ExpenditureDialogObj: pObj })}>
                                                    <EditIcon color="primary" />
                                                </Button>
                                            </MuiTooltip>
                                            <MuiTooltip title="Delete">
                                                <Button
                                                    className={is_read_only ? classes.hide : classes.deleteWidth}
                                                    aria-label="Delete"
                                                    onClick={() => this.setState({ WarningDialogObj: pObj })}>
                                                    <DeleteIcon color="primary" />
                                                </Button>
                                            </MuiTooltip>
                                            <MuiTooltip title="View">
                                                <Button
                                                    className={!is_read_only ? classes.hide : classes.deleteWidth}
                                                    aria-label="View"
                                                    onClick={n => this.setState({ ExpenditureDialogObj: pObj })}>
                                                    <ViewIcon color="primary" />
                                                </Button>
                                            </MuiTooltip>
                                        </TableCell>
                                        <TableCell>{d.category}</TableCell>
                                        <TableCell>{d.description}</TableCell>
                                        <TableCell>{d.purpose}</TableCell>
                                        <TableCell className={classes.tableCellAlignCenter}>
                                            {d.ineligible_expenses}
                                        </TableCell>
                                        <TableCell className={classes.tableCellAlignRight}>
                                            {d.amount.toLocaleString()}
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                            {table_data.length > 0 && (
                                <TableRow key={"total"}>
                                    <TableCell className={classes.tableCellNoBorder}></TableCell>
                                    <TableCell className={classes.tableCellNoBorder}></TableCell>
                                    <TableCell className={classes.tableCellNoBorder}></TableCell>
                                    <TableCell className={classes.tableCellNoBorder}></TableCell>
                                    <TableCell
                                        className={classNames(
                                            classes.tableCellBold,
                                            classes.tableCellNoBorder,
                                            classes.tableCellAlignRight
                                        )}>
                                        Total Amount
                                    </TableCell>
                                    <TableCell
                                        className={classNames(
                                            classes.tableCellAlignRight,
                                            classes.tableCellBold,
                                            classes.tableCellNoBorder
                                        )}>
                                        $&nbsp;{amtTotal.toLocaleString()}
                                    </TableCell>
                                </TableRow>
                            )}
                            {table_data.length < 1 && (
                                <TableRow>
                                    <TableCell colSpan={7} className={classes.centerAlign}>
                                        <caption style={{display:"inline"}}>No Records</caption>
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </Grid>

                <Grid item xs={12} className={classes.rightAlign}>
                    <Button
                        variant="contained"
                        type="submit"
                        style={{ float: "left" }}
                        disabled={is_read_only}
                        onClick={() => handlePrev()}
                        className={classes.button}>
                        Save and Return to Previous Step
                    </Button>
                    <Button
                        variant="raised"
                        disabled={is_read_only}
                        onClick={() => history.push("/dashboard/")}
                        className={classes.button}>
                        Save Draft and Return to Dashboard
                    </Button>
                    <Button
                        variant="raised"
                        disabled={is_read_only}
                        color="primary"
                        className={classes.button}
                        onClick={() => handleNext()}>
                        Save and Proceed to Next Step
                    </Button>
                </Grid>

                <Dialog
                    open={ExpenditureDialogObj ? true : false}
                    onClose={this.handleClose}
                    disableBackdropClick={true}>
                    <Form
                        defaultValues={ExpenditureDialogObj}
                        validateOnSubmit={true}
                        dontValidateOnMount={true}
                        validateError={values => this.errorValidator(values)}
                        onSubmit={values => this.saveExpenditure(values)}>
                        {formApi => (
                            <form onSubmit={formApi.submitForm}>
                                <DialogTitle
                                    className={classes.dialogTitleMargin}
                                    disableTypography
                                    id="form-dialog-title">
                                    <Typography variant="h3">
                                        {interact_string} Capital Expenditure, Equipment, Tools, and Supplies Details
                                    </Typography>
                                    <IconButton
                                        aria-label="Close Dialog"
                                        className={classes.closeButton}
                                        onClick={this.handleClose}>
                                        <CloseIcon />
                                    </IconButton>
                                </DialogTitle>
                                <DialogContent>
                                    <Grid container spacing={8}>
                                        <Grid item xs={12}>
                                            <Select
                                                field="category"
                                                fullWidth
                                                disabled={is_read_only}
                                                label="Category"
                                                margin="normal"
                                                options={category_options}
                                                eventHandle={val => this.handleCategory(val, formApi)}
                                            />
                                        </Grid>

                                        <Grid item xs={12}>
                                            <TextField
                                                field="description"
                                                fullWidth
                                                disabled={is_read_only}
                                                label="Description (i.e., Item or category of items including quantity)"
                                                margin="normal"
                                            />
                                        </Grid>

                                        <Grid item xs={12}>
                                            <TextField
                                                field="purpose"
                                                fullWidth
                                                disabled={is_read_only}
                                                label="Purpose (Explain purpose of item/s)"
                                                margin="normal"
                                            />
                                        </Grid>

                                        <Grid item xs={4}>
                                            <TextField
                                                field="amount"
                                                useTextFormat
                                                fullWidth
                                                disabled={is_read_only}
                                                label="Amount"
                                                margin="normal"
                                            />
                                        </Grid>

                                        {formApi.values.category === "Capital Expenditure" && (
                                            <>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        field="explanation"
                                                        multiline
                                                        fullWidth
                                                        disabled={is_read_only}
                                                        label="Provide an explanation as to how the capital equipment purchased with the appropriation will continue to be used for the same program through its useful life"
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Checkbox
                                                        fullWidth
                                                        disabled={is_read_only}
                                                        field="understand_repayment">
                                                        <HelpLabel
                                                            inputLabel="I understand and acknowledge ENRTF repayment requirements if the use of capital equipment changes."
                                                            showLabel={true}
                                                            htmlText={true}
                                                            helpText={
                                                                tab.sub_tabs
                                                                    .find(
                                                                        tht =>
                                                                            tht.tab_name ===
                                                                            "Capital, Equipment, Tools, and Supplies"
                                                                    )
                                                                    .tab_help_texts.find(
                                                                        tht =>
                                                                            tht.label ===
                                                                            "I understand and acknowledge ENRTF repayment requirements if the use of capital equipment changes."
                                                                    ).text
                                                            }
                                                        />
                                                    </Checkbox>
                                                </Grid>
                                            </>
                                        )}

                                        <Grid item xs={12}>
                                            <RadioGroup
                                                field="ineligible_expenses"
                                                name="ineligible_expenses"
                                                label=""
                                                fullWidth
                                                disabled={is_read_only}
                                                options={MAKE_OPTIONS_YESNO([
                                                    { label: "Yes", value: "true", disabled: is_read_only || field_disabled },
                                                    { label: "No", value: "false", disabled: is_read_only || field_disabled }
                                                ])}
                                                alignment={true}>
                                                <HelpLabel
                                                    inputLabel="Generally Ineligible Expenses Justification"
                                                    showLabel={true}
                                                    htmlText={true}
                                                    helpText={
                                                        tab.sub_tabs
                                                            .find(
                                                                tht =>
                                                                    tht.tab_name ===
                                                                    "Capital, Equipment, Tools, and Supplies"
                                                            )
                                                            .tab_help_texts.find(
                                                                tht =>
                                                                    tht.label ===
                                                                    "Generally Ineligible Expenses Justification"
                                                            ).text
                                                    }
                                                />
                                            </RadioGroup>
                                            {formApi.values.ineligible_expenses && (
                                                <Subquestion
                                                    component={
                                                        <TextField
                                                            field="justify_ineligible_expenses"
                                                            multiline
                                                            fullWidth
                                                            disabled={is_read_only}
                                                            label=""
                                                        />
                                                    }
                                                />
                                            )}
                                        </Grid>
                                    </Grid>
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={this.handleClose}>Cancel</Button>
                                    <Button color="primary" variant="contained" disabled={is_read_only} type="submit">
                                        Save
                                    </Button>
                                </DialogActions>
                            </form>
                        )}
                    </Form>
                </Dialog>

                <WarningDialog
                    confirmText={"Yes"}
                    confirmAction={this.deleteRecord}
                    cancelText={"No"}
                    cancelAction={this.cancelDeleteRecord}
                    open={!!WarningDialogObj}
                    title="Delete Expenditure record"
                    text={"Are you sure you want to delete this Expenditure record?"}
                />
            </Grid>
        );
    }
}

Budget_ExpendituresTab = connect(
    (state, ownProps) => ({
        expenditures: getExpenditures(state, ownProps),
        tab: getTab(state, ownProps),
        authState: state.auth
    }),
    {
        ...ProposalExpenditure.actions
    }
)(Budget_ExpendituresTab);

export default withStyles(styles)(withRouter(Budget_ExpendituresTab));
