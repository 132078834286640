import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Form } from "react-form";
import { connect } from "react-redux";
import classNames from "classnames";

import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import MuiTooltip from "@material-ui/core/Tooltip";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

import { withStyles } from "@material-ui/core";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import CloseIcon from "@material-ui/icons/Close";
import ViewIcon from "@material-ui/icons/RemoveRedEye";

import { DraftProposalContract, FinalProposalContract } from "../models";
import TextField from "../../common/TextField";
import RadioGroup from "../../common/RadioGroup";
import HelpLabel from "../../common/HelpLabel";
import Subquestion from "../../common/Subquestion";
import Checkbox from "../../common/Checkbox";
import Select from "../../common/Select";
import EnhancedTableHead from "../../common/EnhancedTableHead";
import TableCell from "../../common/TableCell";
import { getValue, setValue } from "../../../api/utils";
import { MAKE_OPTIONS_YESNO } from "../../../api/constants";
import WarningDialog from "../../common/WarningDialog";
import { createSelector } from "../../common/orm";
import { WorkPlanTab } from "../../home/models";

const getContracts = createSelector(
    (state, ownProps) => ownProps.plan.id,
    (state, ownProps) => ownProps.match.path === "/draft/workplan/:id",
    (session, id, isDraft) => {
        if (isDraft) {
            return session.DraftProposalContract.filter({ proposal: id })
                .orderBy("id")
                .toModelArray();
        } else {
            return session.FinalProposalContract.filter({ proposal: id })
                .orderBy("id")
                .toModelArray();
        }
    }
);

const getTab = WorkPlanTab.selectMessage(6);

const styles = theme => ({
    rightAlign: {
        textAlign: "right"
    },
    hide: {
        display: "none"
    },
    button: {
        marginRight: theme.spacing.unit * 2
    },
    dialogTitleMargin: {
        marginRight: "48px"
    },
    closeButton: {
        position: "absolute",
        right: 8,
        top: 8
    },
    paddingLeft: {
        paddingLeft: "40px"
    },
    nowrap: {
        whiteSpace: "nowrap"
    },
    deleteWidth: {
        minWidth: 34,
        width: 34,
        marginRight: 8
    },
    tableCellNoBorder: {
        border: "none"
    },
    tableCellBold: {
        fontWeight: "Bold",
        color: "#000000"
    },
    centerAlign: {
        textAlign: "center"
    },
    flex: {
        flex: 1,
        fontWeight: 500,
        fontSize: "1.15rem",
        color: "#495057",
        lineHeight: 1.2
    },
});

class Budget_ContractsTab extends Component {
    state = {
        ContractDialogObj: null,
        WarningDialogObj: null,
        ormPropPrefix: this.props.match.path === "/draft/workplan/:id" ? "Draft" : "Final"
    };

    handleClose = () => {
        this.setState({ ContractDialogObj: null });
    };

    componentDidMount() {
        const { handleUnsavedFields } = this.props;
        document.title = "Work Plan: Budget Services and Subawards - LCCMR";
        this.props.onRef(this);
        const _this = this;
        // FIXME: react-forms calls formDidUpdate when (if) validation is ran right away
        // making it appear there is a edited field even if there isn't
        // Reset fields to false to not require validation when mounted
        setTimeout(function() {
            handleUnsavedFields(false);
            if (_this.props.appContainer.current) {
                _this.props.appContainer.current.scrollTop();
            }
        }, 1);
    }

    componentWillUnmount() {
        this.props.onRef(undefined);
    }

    errorValidator = values => {
        const { plan } = this.props;
        var valObj = {};

        const isRequired = path => {
            let val = getValue(values, path);
            setValue(valObj, path, val || val === false ? null : "Required");
        };

        const isRequiredCheckbox = path => {
            let val = getValue(values, path);
            setValue(valObj, path, val ? null : "Required");
        };

        const isValidValue = (path, path2=null) => {
            let val = getValue(values, path);
            let val2 = null;
            if (path2)
                val2 = getValue(values, path2);
            if (!val && val !== 0) {
                setValue(valObj, path, "Required");
                return;
            }
            if (val2 && val2 !== undefined && val2 > val) {
                setValue(valObj, path, "The amount can not be smaller than the amount already spent.");
                return;
            }
            setValue(valObj, path, val >= 0 ? null : "Value must be >= 0.");
        };

        isRequired("entity");
        isRequired("project_role");
        isRequired("description");
        //isRequired("single_source");
        if (plan.ml_year >= 2022)
            isRequiredCheckbox("understand_service");
        if (plan.ml_year >= 2022 && values.project_role === "Subaward") {
            isRequired("annual_fte");
            isRequired("years");
        } else {
            valObj["annual_fte"] = null;
            valObj["years"] = null;
        }
        isRequired("ineligible_expenses");
        isValidValue("amount", "amount_spent");
        if (values.ineligible_expenses) {
            isRequired("justify_ineligible_expenses");
        } else {
            setValue(valObj, "justify_ineligible_expenses", null);
        }

        return valObj;
    };

    saveContract(values) {
        const { id } = this.props.plan;

        if (!values.ineligible_expenses) {
            values["justify_ineligible_expenses"] = null;
        }

        values["fte"] = this.calculateFte(values);

        if (values.id) {
            this.props[`orm${this.state.ormPropPrefix}ProposalContractUpdate`]({
                ...values
            });
            this.props.calcFundingTotal();
        } else {
            this.props[`orm${this.state.ormPropPrefix}ProposalContractCreate`]({
                ...values,
                proposal: id
            }).then(response => {
                this.props.calcFundingTotal();
            });
        }
        this.setState({ ContractDialogObj: null });
    }

    deleteRecord = () => {
        this.props[`orm${this.state.ormPropPrefix}ProposalContractPromiseDelete`](this.state.WarningDialogObj.id).then(id => {
            this.props.calcFundingTotal();
        });
        this.setState({ WarningDialogObj: null });
    };

    cancelDeleteRecord = () => {
        this.setState({ WarningDialogObj: null });
    };

    getTableData = contracts => {
        var table_data = [];

        contracts.forEach(c => {
            let newObj = { ...c };
            newObj["ineligible_expenses"] = c.ineligible_expenses ? "X" : "";
            newObj["fte"] = this.calculateFte(newObj);
            table_data.push(newObj);
        });

        return table_data;
    };

    calculateFte = values => {
        return parseFloat((values.annual_fte * values.years).toFixed(2));
    };

    handleSource = (val, formApi) => {
        if (val) {
            formApi.setValue("ineligible_expenses", true);
        }
    };

    render() {
        const { classes, contracts, ActionMenu, handleNext, handlePrev, history, is_read_only, tab, plan, proposal } = this.props;
        const { ContractDialogObj, WarningDialogObj } = this.state;

        var interact_string = ContractDialogObj && ContractDialogObj["id"] ? "Edit" : "Add";
        if (is_read_only) {
            interact_string = "View";
        }

        const project_role_options = [
            { value: "Subaward", label: "Subaward" },
            {
                value: plan.ml_year < 2022 ? "Professional or Technical Service Contract" : "Service Contract",
                label: plan.ml_year < 2022 ? "Professional or Technical Service Contract" : "Service Contract"
            },
            {
                value: "Internal services or fees (uncommon)",
                label: "Internal services or fees (uncommon)"
            }
        ];

        const AcquisitionOptions = [
            { value: "Subaward", label: "Subaward" },
            {
                value: plan.ml_year < 2022 ? "Professional or Technical Service Contract" : "Service Contract",
                label: plan.ml_year < 2022 ? "Professional or Technical Service Contract" : "Service Contract"
            },
            {
                value: "Internal services or fees (uncommon)",
                label: "Internal services or fees (uncommon)"
            },
            {
                value: plan.ml_year < 2022 ? "Acquisition costs" : "Acquisition services",
                label: plan.ml_year < 2022 ? "Acquisition costs" : "Acquisition services"
            },
        ];

        const column_data = [
            { id: "actions", numeric: false, label: "Actions", width: "20px" },
            {
                id: "entity",
                numeric: false,
                label: "Entity",
            },
            {
                id: "project_role",
                numeric: false,
                label: plan.ml_year < 2022 ? "Contract or Service Type" : "Agreement Type",
            },
            {
                id: "description",
                numeric: false,
                label: plan.ml_year < 2022 ? "Description of Service Provided" : "Description of Work Provided",
            },
            {
                id: "ineligible_expenses",
                numeric: false,
                label: "Generally Ineligible",
            },
            {
                id: "annual_fte",
                numeric: true,
                label: "FTE/Year",
            },
            { id: "years", numeric: true, label: "# of Years" },
            {
                id: "fte",
                numeric: true,
                label: "Total FTE",
                align: "center"
            },
            {
                id: "amount",
                numeric: true,
                label: "Approx. $ Amount",
            }
        ];

        const table_data = this.getTableData(contracts);

        let fteTotal = 0;
        let amtTotal = 0;
        table_data.forEach(d => {
            fteTotal += d.fte;
            amtTotal += d.amount;
        });
        fteTotal = Math.round((fteTotal + Number.EPSILON) * 100) / 100;

        var onlyAcquistion = false;
        if (this.props.plan.land_rights.length > 0) {
            onlyAcquistion = true;
        }

        return (
            <Grid container spacing={16} style={{ marginTop: 0 }}>
                <Grid item xs={12}>
                    <Typography variant="titleAction">{plan.ml_year < 2022 ? "Professional/Technical/Services Contracts Details" : "Services and Subawards"}</Typography>
                    {ActionMenu}
                </Grid>

                <Grid item xs={12}>
                    <div class="insborder">
                        <Typography gutterBottom>Instructions and Requirements</Typography>
                        <Typography>
                            {plan.ml_year < 2022 ? (
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: tab.sub_tabs
                                            .find(tht => tht.tab_name === "Services and Subawards")
                                            .tab_help_texts.find(tht => tht.label === "Instructions and Requirements 2020-2021").text
                                    }}
                                />
                            ) : (
                                <div
                                dangerouslySetInnerHTML={{
                                    __html: tab.sub_tabs
                                        .find(tht => tht.tab_name === "Services and Subawards")
                                        .tab_help_texts.find(tht => tht.label === "Instructions and Requirements 2022+").text
                                }}
                                />
                            )}
                        </Typography>
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <Button
                        fullWidth
                        variant="raised"
                        disabled={is_read_only}
                        color="primary"
                        onClick={() =>
                            this.setState({
                                ContractDialogObj: {
                                    id: null,
                                    single_source: false,
                                    ineligible_expenses: false,
                                    justify_ineligible_expenses: null
                                }
                            })
                        }>
                        <AddCircleIcon />
                        &nbsp;&nbsp;&nbsp;{plan.ml_year < 2022 ? "Add New Contract or Service" : "Add New Service or Subaward"}
                    </Button>
                </Grid>

                <Grid item xs={12}>
                    <Table className={classes.table}>
                        <EnhancedTableHead stickyHeader columnData={column_data} />
                        <TableBody>
                            {table_data.map(d => {
                                let pObj = contracts.filter(o => o.id === d.id)[0];
                                return (
                                    <TableRow key={d.id}>
                                        <TableCell className={classes.nowrap}>
                                            <MuiTooltip title="Edit">
                                                <Button
                                                    className={is_read_only ? classes.hide : classes.deleteWidth}
                                                    disabled={is_read_only}
                                                    aria-label="Edit"
                                                    onClick={() => this.setState({ ContractDialogObj: pObj })}>
                                                    <EditIcon color="primary" />
                                                </Button>
                                            </MuiTooltip>
                                            <MuiTooltip title="Delete">
                                                <Button
                                                    className={is_read_only ? classes.hide : classes.deleteWidth}
                                                    disabled={is_read_only}
                                                    aria-label="Delete"
                                                    onClick={() => this.setState({ WarningDialogObj: pObj })}>
                                                    <DeleteIcon color="primary" />
                                                </Button>
                                            </MuiTooltip>
                                            <MuiTooltip title="View">
                                                <Button
                                                    className={!is_read_only ? classes.hide : classes.deleteWidth}
                                                    aria-label="View"
                                                    onClick={() => this.setState({ ContractDialogObj: pObj })}>
                                                    <ViewIcon color="primary" />
                                                </Button>
                                            </MuiTooltip>
                                        </TableCell>
                                        <TableCell>{d.entity}</TableCell>
                                        <TableCell>{d.project_role}</TableCell>
                                        <TableCell>{d.description}</TableCell>
                                        <TableCell className={classes.centerAlign}>{d.ineligible_expenses}</TableCell>
                                        <TableCell className={classes.centerAlign}>{d.annual_fte}</TableCell>
                                        <TableCell className={classes.centerAlign}>{d.years}</TableCell>
                                        <TableCell className={classes.centerAlign}>{d.fte}</TableCell>
                                        <TableCell className={classes.rightAlign}>{d.amount.toLocaleString()}</TableCell>
                                    </TableRow>
                                );
                            })}
                            {table_data.length > 0 && (
                                <TableRow key={"total"}>
                                    <TableCell className={classes.tableCellNoBorder}></TableCell>
                                    <TableCell className={classes.tableCellNoBorder}></TableCell>
                                    <TableCell className={classes.tableCellNoBorder}></TableCell>
                                    <TableCell className={classes.tableCellNoBorder}></TableCell>
                                    <TableCell className={classes.tableCellNoBorder}></TableCell>
                                    <TableCell className={classNames(classes.tableCellNoBorder, classes.tableCellBold)}>Total FTE</TableCell>
                                    <TableCell className={classNames(classes.rightAlign, classes.tableCellBold, classes.tableCellNoBorder)}>
                                        {fteTotal}
                                    </TableCell>
                                    <TableCell className={classNames(classes.tableCellNoBorder, classes.tableCellBold)}>Total Contract Amount</TableCell>
                                    <TableCell className={classNames(classes.rightAlign, classes.tableCellBold, classes.tableCellNoBorder)}>
                                        $&nbsp;{amtTotal.toLocaleString()}
                                    </TableCell>
                                </TableRow>
                            )}
                            {table_data.length < 1 && (
                                <TableRow>
                                    <TableCell colSpan={9} className={classes.centerAlign}>
                                        <caption style={{display:"inline"}}>No Records</caption>
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </Grid>

                <Grid item xs={12} className={classes.rightAlign}>
                    <Button
                        variant="contained"
                        type="submit"
                        style={{ float: "left" }}
                        disabled={is_read_only}
                        onClick={() => handlePrev()}
                        className={classes.button}>
                        Save and Return to Previous Step
                    </Button>
                    <Button variant="raised" disabled={is_read_only} onClick={() => history.push("/dashboard/")} className={classes.button}>
                        Save Draft and Return to Dashboard
                    </Button>
                    <Button variant="raised" disabled={is_read_only} color="primary" className={classes.button} onClick={() => handleNext()}>
                        Save and Proceed to Next Step
                    </Button>
                </Grid>

                <Dialog role="main" aria-label={interact_string + plan.ml_year < 2022 ? " Service / Contract Detail" : " Service / Subaward Detail"} open={ContractDialogObj ? true : false} onClose={this.handleClose} disableBackdropClick={true}>
                    <Form
                        defaultValues={ContractDialogObj}
                        validateOnSubmit={true}
                        dontValidateOnMount={true}
                        validateError={values => this.errorValidator(values)}
                        onSubmit={values => this.saveContract(values)}>
                        {formApi => (
                            <form onSubmit={formApi.submitForm}>
                                <DialogTitle className={classes.dialogTitleMargin} disableTypography id="form-dialog-title">
                                    <Typography variant="h1" className={classes.flex}>{interact_string} {plan.ml_year < 2022 ? "Service / Contract Detail" : "Service / Subaward Detail"}</Typography>
                                    <IconButton aria-label="Close Dialog" className={classes.closeButton} onClick={this.handleClose}>
                                        <CloseIcon />
                                    </IconButton>
                                </DialogTitle>
                                <DialogContent>
                                    <Grid container spacing={8}>
                                        <Grid item xs={12}>
                                            <TextField field="entity" fullWidth disabled={is_read_only} margin="normal">
                                                <HelpLabel
                                                    inputLabel="Entity"
                                                    showLabel={true}
                                                    htmlText={true}
                                                    helpText={
                                                        tab.sub_tabs
                                                            .find(tht => tht.tab_name === "Services and Subawards")
                                                            .tab_help_texts.find(tht => tht.label === "Entity").text
                                                    }
                                                />
                                            </TextField>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Select
                                                field="project_role"
                                                fullWidth
                                                disabled={is_read_only}
                                                margin="normal"
                                                options={onlyAcquistion ? AcquisitionOptions : project_role_options}>
                                                <HelpLabel
                                                    inputLabel={plan.ml_year < 2022 ? "Contract or Service Type" : "Agreement Type"}
                                                    showLabel={true}
                                                    htmlText={true}
                                                    helpText={plan.ml_year < 2022 ? "Select the option that best reflects the contract or service type provided by the entity and work being done." : "Select the option that best reflects the agreement type given the type of entity and work being done."}
                                                />
                                            </Select>
                                        </Grid>

                                        <TextField
                                            field="description"
                                            multiline
                                            fullWidth
                                            disabled={is_read_only}
                                            maxWords="50"
                                        >
                                            <HelpLabel
                                                inputLabel="Explain what work the entity will be providing or contributing to the project."
                                                showLabel={true}
                                                htmlText={true}
                                                helpText={
                                                    tab.sub_tabs
                                                        .find(tht => tht.tab_name === "Services and Subawards")
                                                        .tab_help_texts.find(tht => tht.label === "Explain what work the entity will be providing or contributing to the project.").text
                                                }  
                                            />
                                        </TextField>
                                        
                                        {plan.ml_year < 2022 && proposal.status !== "Final Work Plan Approved" && proposal.status !== "Final Abstract Due" && proposal.status !== "Final Abstract Submitted" && proposal.status !== "Final Abstract Revisions Needed" && proposal.status !== "Project Completed" && (
                                            <Grid item xs={12}>
                                                <RadioGroup
                                                    field="single_source"
                                                    name="single_source"
                                                    label=""
                                                    fullWidth
                                                    disabled={is_read_only}
                                                    options={MAKE_OPTIONS_YESNO([
                                                        { label: "Yes", value: "true", disabled: is_read_only },
                                                        { label: "No", value: "false", disabled: is_read_only }
                                                    ])}
                                                    alignment={true}
                                                    eventHandle={val => this.handleSource(val, formApi)}>
                                                    Is this a single source contract?
                                                </RadioGroup>
                                            </Grid>
                                        )}
                                        <Grid item xs={4}>
                                            <TextField field="annual_fte" useFloatFormat fullWidth disabled={is_read_only} margin="normal">
                                                <HelpLabel
                                                    inputLabel="FTE/Year"
                                                    showLabel={true}
                                                    htmlText={true}
                                                    helpText={
                                                        tab.sub_tabs
                                                            .find(tht => tht.tab_name === "Services and Subawards")
                                                            .tab_help_texts.find(tht => tht.label === "FTE/Year").text
                                                    }
                                                />
                                            </TextField>
                                        </Grid>

                                        <Grid item xs={4}>
                                            <TextField useFloatFormat field="years" fullWidth disabled={is_read_only} label="# of Years" margin="normal" />
                                        </Grid>

                                        <Grid item xs={4}>
                                            <TextField
                                                disabled={true}
                                                field="fte"
                                                fullWidth
                                                useFloatFormat
                                                label="Total FTE"
                                                margin="normal"
                                                value={this.calculateFte(formApi.values)}
                                            />
                                        </Grid>

                                        <Grid item xs={4}>
                                            <TextField field="amount" fullWidth disabled={is_read_only} useTextFormat label={plan.ml_year < 2022 ? "Contract Amount" : "Amount"} margin="normal" />
                                        </Grid>
                                        {plan.ml_year >= 2022 && (
                                            <Grid item xs={12}>
                                                <Typography>
                                                    Do you understand that a named service contract does not constitute a funder-designated 
                                                    subrecipient or approval of a sole-source contract? In other words, a service contract 
                                                    entity is only approved if it has been selected according to the contracting rules 
                                                    identified in state law and policy for organizations that receive ENRTF funds through 
                                                    direct appropriations, or in the <a href="https://files.dnr.state.mn.us/assistance/grants/passthrough/attachment-d-reimbursement-manual.pdf" rel="noopener noreferrer" target="_blank">DNR's reimbursement manual</a> for non-state organizations. 
                                                    These rules may include competitive bidding and prevailing wage requirements.
                                                </Typography>
                                                <Checkbox fullWidth label="Yes, I understand" disabled={is_read_only} field="understand_service" />
                                            </Grid>
                                        )}
                                        <Grid item xs={12}>
                                            <RadioGroup
                                                field="ineligible_expenses"
                                                name="ineligible_expenses"
                                                fullWidth
                                                disabled={is_read_only}
                                                options={MAKE_OPTIONS_YESNO([
                                                    { label: "Yes", value: "true", disabled: is_read_only },
                                                    { label: "No", value: "false", disabled: is_read_only }
                                                ])}
                                                alignment={true}>
                                                <HelpLabel
                                                    inputLabel="Generally Ineligible Expenses Justification"
                                                    showLabel={true}
                                                    htmlText={true}
                                                    helpText={
                                                        tab.sub_tabs
                                                            .find(tht => tht.tab_name === "Services and Subawards")
                                                            .tab_help_texts.find(tht => tht.label === "Generally Ineligible Expenses Justification").text
                                                    }
                                                />
                                            </RadioGroup>
                                            {formApi.values.ineligible_expenses && (
                                                <Subquestion
                                                    component={
                                                        <TextField field="justify_ineligible_expenses" multiline fullWidth disabled={is_read_only} label="" />
                                                    }
                                                />
                                            )}
                                        </Grid>
                                    </Grid>
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={this.handleClose}>Cancel</Button>
                                    <Button color="primary" variant="contained" disabled={is_read_only} type="submit">
                                        Save
                                    </Button>
                                </DialogActions>
                            </form>
                        )}
                    </Form>
                </Dialog>

                <WarningDialog
                    confirmText={"Yes"}
                    confirmAction={this.deleteRecord}
                    cancelText={"No"}
                    cancelAction={this.cancelDeleteRecord}
                    open={!!WarningDialogObj}
                    title="Delete Contract record"
                    text={"Are you sure you want to delete this Contract record?"}
                />
            </Grid>
        );
    }
}

Budget_ContractsTab = connect(
    (state, ownProps) => ({
        contracts: getContracts(state, ownProps),
        tab: getTab(state, ownProps),
        authState: state.auth
    }),
    {
        ...DraftProposalContract.actions,
        ...FinalProposalContract.actions
    }
)(Budget_ContractsTab);

export default withStyles(styles)(withRouter(Budget_ContractsTab));
