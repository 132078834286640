import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { Form } from "react-form";
import { connect } from "react-redux";

import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import MuiTooltip from "@material-ui/core/Tooltip";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";

import CloseIcon from "@material-ui/icons/Close";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";

import HelpLabel from "../../common/HelpLabel";
import TextField from "../../common/TextField";
import CustomTableCell from "../../common/TableCell";
import EnhancedTableHead from "../../common/EnhancedTableHead";
import Select from "../../common/Select";
import Snackbar from "../../common/Snackbar";
import Switch from "../../common/Switch";
import TablePaginationActionsWrapped from "../../common/Paginator";
import WarningDialog from "../../common/WarningDialog";

import { StaffComment } from "../models";
import { createSelector } from "../../common/orm";
import { ProposalReview, ProposalTab } from "../../home/models";

const getStaff = createSelector(schema => {
    return schema.User.filter(x => x.role === "Staff").toRefArray();
});

const getReview = createSelector(
    (state, ownProps) => ownProps.proposal.id,
    (schema, proposal) => {
        return schema.ProposalReview.filter(x => x.proposal === proposal).first();
    }
);

const getComments = createSelector(
    (state, ownProps) => ownProps.proposal.id,
    (state, ownProps) => ownProps.draftPage,
    (schema, proposal, draftPage) => {
        return schema.StaffComment.filter(x => x.proposal === proposal && x.draft === draftPage)
            .toModelArray()
            .map(x => {
                return {
                    user_: x.user,
                    uid: x.ref.user,
                    ...x.ref
                };
            });
    }
);

const getTab = ProposalTab.selectMessage(15);

const options = [
    {
        label: "Applicant",
        value: "Applicant"
    },
    {
        label: "Member",
        value: "Member"
    },
    {
        label: "Staff",
        value: "Staff"
    }
];

const styles = theme => ({
    deleteWidth: {
        minWidth: 34,
        width: 34,
        marginRight: 8
    },
    rightAlign: {
        textAlign: "right"
    },
    button: {
        marginRight: theme.spacing.unit * 2
    },
    flex: {
        flex: 1,
        fontWeight: 500,
        fontSize: "1.15rem",
        color: "#495057",
        lineHeight: 1.2
    },
    centerAlign: {
        textAlign: "center"
    },
    nowrap: {
        whiteSpace: "nowrap"
    },
    dialogTitleMargin: {
        marginRight: "48px"
    },
    closeButton: {
        position: "absolute",
        right: 8,
        top: 8
    }
});

const columnData = [
    { id: "actions", numeric: false, label: "Actions", width: "20px" },
    { id: "id", numeric: true, label: "Comment ID", allowSort: true },
    { id: "step", numeric: true, label: "Step", allowSort: true },
    { id: "comment_for", numeric: false, label: "Comment For", allowSort: true },
    { id: "date", numeric: false, label: "Date Created", allowSort: true, date: true },
    { id: "uid", numeric: true, label: "Created By", allowSort: true },
    { id: "comment", numeric: false, label: "Comment", allowSort: true }
];

class Final_Comments extends Component {
    state = {
        dont: false,
        page: 0,
        rowsPerPage: 25,
        order: "desc",
        orderBy: "",
        CommentDialogObj: null,
        WarningDialogObj: null,
        error: false,
        message: ""
    };

    componentDidMount() {
        const { draftPage, handleUnsavedFields } = this.props;
        document.title =
            "Proposal: " +
            (draftPage ? "Draft Proposal Review Comments" : "Final Proposal Review Comments") +
            " - LCCMR";
        this.props.onRef(this);
        const _this = this;
        // FIXME: react-forms calls formDidUpdate when (if) validation is ran right away
        // making it appear there is a edited field even if there isn't
        // Reset fields to false to not require validation when mounted
        setTimeout(function() {
            handleUnsavedFields(false);
            _this.props.appContainer.current.scrollTop();
        }, 1);
    }

    componentDidUpdate() {
        //resets form to include id after creation of score
        if (this.state.dont) {
            this.setState({ dont: false });
        }
    }

    componentWillUnmount() {
        this.props.onRef(undefined);
    }

    submit = values => {
        const {
            authState,
            getStaffKeys,
            proposal,
            ormProposalReviewCreate,
            ormProposalReviewCreateLocalOnly,
            ormProposalReviewUpdate
        } = this.props;

        const staff = getStaffKeys();

        fetch(`api/db/proposalreviews?format=json&t=${Date.now()}&proposal=${proposal.id}`, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Token " + authState.user.auth_token
            }
        })
            .then(result => {
                if (!result.ok) {
                    return result.text().then(text => {
                        throw new Error(text);
                    });
                } else {
                    return result.json();
                }
            })
            .then(data => {
                if (data.length === 0) {
                    if (!values.id) {
                        ormProposalReviewCreate({
                            proposal_id: proposal.id,
                            ...staff,
                            ...values
                        }).then(id => {
                            this.setState({ dont: true });
                        });
                    }
                } else if (data.length === 1) {
                    if (values.id) {
                        ormProposalReviewUpdate({
                            ...values
                        });
                    } else {
                        ormProposalReviewCreateLocalOnly({
                            ...data[0],
                            proposal: data[0].proposal_id
                        });
                        this.setState({
                            error: true,
                            message: "Existing review was found! Please re-enter data!",
                            dont: true
                        });
                    }
                } else {
                    this.setState({ error: true, message: "Error!" });
                }
            })
            .catch(e => {
                console.log(e);
                this.setState({ error: true, message: "Error!" });
            });
    };

    handleRequestSort = (event, property) => {
        const orderBy = property;
        let order = "desc";

        if (this.state.orderBy === property && this.state.order === "desc") {
            order = "asc";
        }

        this.props.comments.sort(function(a, b) {
            var date = columnData.find(cD => cD.id === orderBy).date;
            var numeric = columnData.find(cD => cD.id === orderBy).numeric;
            var bool = columnData.find(cD => cD.id === orderBy).bool;
            if (date) {
                if (order === "desc") return new Date(b[orderBy]) < new Date(a[orderBy]) ? -1 : 1;
                else return new Date(a[orderBy]) < new Date(b[orderBy]) ? -1 : 1;
            } else if (bool) {
                if (order === "desc") return b[orderBy] - a[orderBy];
                else return a[orderBy] - b[orderBy];
            } else if (numeric) {
                if (order === "desc") return parseFloat(b[orderBy]) < parseFloat(a[orderBy]) ? -1 : 1;
                else return parseFloat(a[orderBy]) < parseFloat(b[orderBy]) ? -1 : 1;
            } else {
                if (order === "desc")
                    return (b[orderBy] || "").toUpperCase() < (a[orderBy] || "").toUpperCase() ? -1 : 1;
                else return (a[orderBy] || "").toUpperCase() < (b[orderBy] || "").toUpperCase() ? -1 : 1;
            }
        });

        this.setState({ order, orderBy });
    };

    cancelDeleteRecord = () => {
        this.setState({ WarningDialogObj: false });
    };

    deleteRecord = () => {
        this.props.ormStaffCommentDelete(this.state.WarningDialogObj.id);
        this.setState({ WarningDialogObj: null });
    };

    cancelDeleteRecord = () => {
        this.setState({ WarningDialogObj: null });
    };

    handleClose = () => {
        this.setState({ CommentDialogObj: null });
    };

    saveComment = values => {
        this.props.ormStaffCommentUpdate({
            ...values
        });
        this.handleClose();
    };

    handleChangePage = (event, page) => {
        this.setState({ page });
    };

    handleChangeRowsPerPage = event => {
        this.setState({ rowsPerPage: event.target.value, page: 0 });
    };

    defaultValues = (values, proposal) => {
        var defaultValues = {};
        defaultValues = { ...values };
        defaultValues["major_contributors"] = proposal.major_contributors ? proposal.major_contributors : "";
        return defaultValues;
    };

    render() {
        const {
            classes,
            settings,
            getSteps,
            allStaff,
            comments,
            draftPage,
            proposal,
            review,
            ActionMenu,
            tab
        } = this.props;
        const { dont, order, orderBy, page, rowsPerPage, CommentDialogObj, WarningDialogObj } = this.state;

        const keys = [
            {
                key: "staff_1",
                slot: 1
            },
            {
                key: "staff_2",
                slot: 2
            },
            {
                key: "staff_3",
                slot: 3
            },
            {
                key: "staff_4",
                slot: 4
            },
            {
                key: "staff_5",
                slot: 5
            },
            {
                key: "staff_6",
                slot: 6
            },
            {
                key: "staff_7",
                slot: 7
            },
            {
                key: "staff_8",
                slot: 8
            },
            {
                key: "staff_9",
                slot: 9
            },
            {
                key: "staff_10",
                slot: 10
            },
            {
                key: "staff_11",
                slot: 11
            },
            {
                key: "staff_12",
                slot: 12
            }
        ];

        var arr = [];

        if (review) {
            keys.forEach(x => {
                arr[x.slot] = {
                    slot: x.slot,
                    user: review ? review[x.key] : null
                };
            });
        } else {
            const staff = this.props.getStaffKeys();
            keys.forEach(x => {
                const user = allStaff.find(z => z.id === staff[x.key]);
                arr[x.slot] = {
                    slot: x.slot,
                    user: user ? user : null
                };
            });
        }

        const draftOpen = proposal && proposal.status !== "Draft Submitted" ? true : false;
        const finalReview =
            (proposal && proposal.primary_phase === "Draft Proposal") || (settings && settings.staff_eval_open)
                ? true
                : false;

        return (
            <>
                {!dont && (
                    <Form
                        validateOnSubmit={true}
                        validateError={this.errorValidator}
                        dontValidateOnMount={true}
                        defaultValues={this.defaultValues(review, proposal)}
                        onSubmit={this.submit}>
                        {formApi => (
                            <form onSubmit={formApi.submitForm}>
                                <Grid container spacing={16} style={{ marginTop: 0 }}>
                                    <Grid item xs={12} lg={10} xl={7}>
                                        <Typography variant="titleAction">
                                            Staff Review and Comments for {draftPage ? " Draft " : " Final "}Proposal
                                        </Typography>
                                        {ActionMenu}
                                    </Grid>
                                    <Grid item xs={12} lg={10} xl={7}>
                                        <Typography>
                                            Staff Lead for this Proposal:{" "}
                                            {proposal.staff_lead_label}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} lg={10} xl={7}>
                                        <Typography variant="titleAction">Comments Complete</Typography>
                                        <Grid container>
                                            {arr.map((x, index) => {
                                                return (
                                                    <Grid item xs={3}>
                                                        {draftPage && (
                                                            <Switch
                                                                disabled={draftOpen}
                                                                field={`ds${index}_comments`}
                                                                label={`S${index}(${
                                                                    x.user
                                                                        ? x.user.first_name + " " + x.user.last_name
                                                                        : "Not Set"
                                                                })`}
                                                            />
                                                        )}
                                                        {!draftPage && (
                                                            <Switch
                                                                disabled={finalReview}
                                                                field={`s${index}_comments`}
                                                                label={`S${index}(${
                                                                    x.user
                                                                        ? x.user.first_name + " " + x.user.last_name
                                                                        : "Not Set"
                                                                })`}
                                                            />
                                                        )}
                                                    </Grid>
                                                );
                                            })}
                                        </Grid>
                                    </Grid>
                                    {draftPage && (
                                        <Grid item xs={12} lg={10} xl={7}>
                                            <TextField
                                                field="draft_staff_comments"
                                                disabled={draftOpen}
                                                fullWidth
                                                multiline>
                                                <HelpLabel
                                                    showLabel={true}
                                                    inputLabel="Comments for LCCMR staff"
                                                    htmlText={true}
                                                    helpText={
                                                        tab.sub_tabs
                                                            .find(
                                                                tht => tht.tab_name === "Draft Proposal Review Comments"
                                                            )
                                                            .tab_help_texts.find(
                                                                tht => tht.label === "Comments for LCCMR staff"
                                                            ).text
                                                    }
                                                />
                                            </TextField>
                                            <TextField
                                                field="draft_applicant_comments"
                                                disabled={draftOpen}
                                                fullWidth
                                                multiline>
                                                <HelpLabel
                                                    showLabel={true}
                                                    inputLabel="Comments for Applicants"
                                                    htmlText={true}
                                                    helpText={
                                                        tab.sub_tabs
                                                            .find(
                                                                tht => tht.tab_name === "Draft Proposal Review Comments"
                                                            )
                                                            .tab_help_texts.find(
                                                                tht => tht.label === "Comments for LCCMR staff"
                                                            ).text
                                                    }
                                                />
                                            </TextField>
                                        </Grid>
                                    )}
                                    {!draftPage && (
                                        <Grid item xs={12} lg={10} xl={7}>
                                            <TextField
                                                disabled={finalReview}
                                                field="final_staff_comments"
                                                fullWidth
                                                multiline>
                                                <HelpLabel
                                                    showLabel={true}
                                                    inputLabel="Comments #1 for LCCMR staff (i.e., internal)"
                                                    htmlText={true}
                                                    helpText={
                                                        tab.sub_tabs
                                                            .find(
                                                                tht => tht.tab_name === "Final Proposal Review Comments"
                                                            )
                                                            .tab_help_texts.find(
                                                                tht =>
                                                                    tht.label ===
                                                                    "Comments #1 for LCCMR staff (i.e., internal)"
                                                            ).text
                                                    }
                                                />
                                            </TextField>
                                            <TextField
                                                disabled={finalReview}
                                                field="final_staff_comments2"
                                                fullWidth
                                                multiline>
                                                <HelpLabel
                                                    showLabel={true}
                                                    inputLabel="Comments #2 for LCCMR staff (i.e., internal)"
                                                    htmlText={true}
                                                    helpText={
                                                        tab.sub_tabs
                                                            .find(
                                                                tht => tht.tab_name === "Final Proposal Review Comments"
                                                            )
                                                            .tab_help_texts.find(
                                                                tht =>
                                                                    tht.label ===
                                                                    "Comments #2 for LCCMR staff (i.e., internal)"
                                                            ).text
                                                    }
                                                />
                                            </TextField>
                                            <TextField
                                                disabled={finalReview}
                                                field="final_member_comments"
                                                fullWidth
                                                multiline>
                                                <HelpLabel
                                                    showLabel={true}
                                                    inputLabel="Comments #1 for Members (i.e., internal)"
                                                    htmlText={true}
                                                    helpText={
                                                        tab.sub_tabs
                                                            .find(
                                                                tht => tht.tab_name === "Final Proposal Review Comments"
                                                            )
                                                            .tab_help_texts.find(
                                                                tht =>
                                                                    tht.label ===
                                                                    "Comments #1 for Members (i.e., internal)"
                                                            ).text
                                                    }
                                                />
                                            </TextField>
                                            <TextField
                                                disabled={finalReview}
                                                field="final_member_comments2"
                                                fullWidth
                                                multiline>
                                                <HelpLabel
                                                    showLabel={true}
                                                    inputLabel="Comments #2 for Members (i.e., external)"
                                                    htmlText={true}
                                                    helpText={
                                                        tab.sub_tabs
                                                            .find(
                                                                tht => tht.tab_name === "Final Proposal Review Comments"
                                                            )
                                                            .tab_help_texts.find(
                                                                tht =>
                                                                    tht.label ===
                                                                    "Comments #2 for Members (i.e., external)"
                                                            ).text
                                                    }
                                                />
                                            </TextField>
                                            <TextField disabled={finalReview} field="internal_pm" fullWidth multiline>
                                                <HelpLabel
                                                    showLabel={true}
                                                    inputLabel="Comments #1 for Project Manager (i.e., internal)"
                                                    htmlText={true}
                                                    helpText={
                                                        tab.sub_tabs
                                                            .find(
                                                                tht => tht.tab_name === "Final Proposal Review Comments"
                                                            )
                                                            .tab_help_texts.find(
                                                                tht =>
                                                                    tht.label ===
                                                                    "Comments #1 for Project Manager (i.e., internal)"
                                                            ).text
                                                    }
                                                />
                                            </TextField>
                                            <TextField disabled={finalReview} field="external_pm" fullWidth multiline>
                                                <HelpLabel
                                                    showLabel={true}
                                                    inputLabel="Comments #2 for Project Manager (i.e., external)"
                                                    htmlText={true}
                                                    helpText={
                                                        tab.sub_tabs
                                                            .find(
                                                                tht => tht.tab_name === "Final Proposal Review Comments"
                                                            )
                                                            .tab_help_texts.find(
                                                                tht =>
                                                                    tht.label ===
                                                                    "Comments #2 for Project Manager (i.e., external)"
                                                            ).text
                                                    }
                                                />
                                            </TextField>
                                        </Grid>
                                    )}
                                    <Grid item xs={12} lg={10} xl={7}>                                                
                                        <TextField
                                            className={classes.box}
                                            label="Provide the name(s) and organization(s) of additional individuals assisting in the completion of this proposal"
                                            field="major_contributors"
                                            multiline
                                            fullWidth
                                            disabled
                                        />
                                    </Grid>
                                    <Grid item xs={12} lg={10} xl={7}>
                                        <Table className={classes.table}>
                                            <EnhancedTableHead
                                                columnData={columnData}
                                                stickyHeader
                                                order={order}
                                                orderBy={orderBy}
                                                onRequestSort={this.handleRequestSort}
                                            />
                                            <TableBody>
                                                {comments
                                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                    .map((n, index) => {
                                                        return (
                                                            <TableRow key={n.id + "term"}>
                                                                <CustomTableCell className={classes.nowrap}>
                                                                    <MuiTooltip title="Edit">
                                                                        <Button
                                                                            disabled={
                                                                                (draftPage && draftOpen) ||
                                                                                (!draftPage && finalReview)
                                                                            }
                                                                            className={classes.deleteWidth}
                                                                            aria-label="Delete"
                                                                            onClick={() =>
                                                                                this.setState({ CommentDialogObj: n })
                                                                            }>
                                                                            <EditIcon color="primary" />
                                                                        </Button>
                                                                    </MuiTooltip>
                                                                    <MuiTooltip title="Delete">
                                                                        <Button
                                                                            disabled={
                                                                                (draftPage && draftOpen) ||
                                                                                (!draftPage && finalReview)
                                                                            }
                                                                            className={classes.deleteWidth}
                                                                            aria-label="Delete"
                                                                            onClick={() =>
                                                                                this.setState({ WarningDialogObj: n })
                                                                            }>
                                                                            <DeleteIcon color="primary" />
                                                                        </Button>
                                                                    </MuiTooltip>
                                                                </CustomTableCell>
                                                                <CustomTableCell>{n.id}</CustomTableCell>
                                                                <CustomTableCell>{getSteps()[n.step]}</CustomTableCell>
                                                                <CustomTableCell>{n.comment_for}</CustomTableCell>
                                                                <CustomTableCell>{n.date}</CustomTableCell>
                                                                <CustomTableCell>
                                                                    {n.user_.first_name + " " + n.user_.last_name}
                                                                </CustomTableCell>
                                                                <CustomTableCell>{n.comment}</CustomTableCell>
                                                            </TableRow>
                                                        );
                                                    })}
                                                {comments.length < 1 && (
                                                    <TableRow>
                                                        <CustomTableCell colSpan={7} className={classes.centerAlign}>
                                                            <caption style={{display:"inline"}}>No Comments Found</caption>
                                                        </CustomTableCell>
                                                    </TableRow>
                                                )}
                                            </TableBody>
                                            {comments.length > 25 && (
                                                <TableFooter>
                                                    <TableRow>
                                                        <TablePagination
                                                            colSpan={7}
                                                            count={comments.length}
                                                            rowsPerPage={rowsPerPage}
                                                            page={page}
                                                            onChangePage={this.handleChangePage}
                                                            onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                                            ActionsComponent={TablePaginationActionsWrapped}
                                                        />
                                                    </TableRow>
                                                </TableFooter>
                                            )}
                                        </Table>
                                    </Grid>
                                    <Grid item xs={12} lg={10} xl={7} className={classes.rightAlign}>
                                        <Button
                                            disabled={(draftPage && draftOpen) || (!draftPage && finalReview)}
                                            variant="contained"
                                            type="submit"
                                            style={{ float: "left" }}
                                            className={classes.button}>
                                            Save
                                        </Button>
                                        <Button
                                            component={Link}
                                            to="/adm/proposal/process"
                                            variant="contained"
                                            style={{ float: "left" }}
                                            className={classes.button}>
                                            Return to Proposal Process Page
                                        </Button>
                                    </Grid>
                                </Grid>
                            </form>
                        )}
                    </Form>
                )}
                <Dialog role="main" aria-label="Edit Staff Comment" open={CommentDialogObj ? true : false} onClose={this.handleClose} disableBackdropClick={true}>
                    <Form
                        defaultValues={CommentDialogObj}
                        dontValidateOnMount={true}
                        onSubmit={values => this.saveComment(values)}>
                        {formApi => (
                            <form onSubmit={formApi.submitForm}>
                                <DialogTitle
                                    className={classes.dialogTitleMargin}
                                    disableTypography
                                    id="form-dialog-title">
                                    <Typography variant="h1" className={classes.flex}>Edit Staff Comment</Typography>
                                    <IconButton
                                        aria-label="Close Dialog"
                                        className={classes.closeButton}
                                        onClick={this.handleClose}>
                                        <CloseIcon />
                                    </IconButton>
                                </DialogTitle>
                                <DialogContent>
                                    <Grid container spacing={8}>
                                        <Grid item xs={12}>
                                            <Select
                                                options={options}
                                                field="comment_for"
                                                label="Comment For"
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField field="comment" label="Comments" multiline fullWidth />
                                        </Grid>
                                    </Grid>
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={() => this.setState({ CommentDialogObj: null })}>Cancel</Button>
                                    <Button color="primary" variant="contained" type="submit">
                                        Save
                                    </Button>
                                </DialogActions>
                            </form>
                        )}
                    </Form>
                </Dialog>
                <Snackbar
                    handleSnackbarClose={() => this.setState({ error: false })}
                    snackbarOpen={this.state.error}
                    message={this.state.message}
                />
                <WarningDialog
                    confirmText={"Yes"}
                    confirmAction={this.deleteRecord}
                    cancelText={"No"}
                    cancelAction={this.cancelDeleteRecord}
                    open={!!WarningDialogObj}
                    title="Delete Comment record"
                    text={"Are you sure you want to delete this Comment record?"}
                />
            </>
        );
    }
}

Final_Comments = connect(
    (state, ownProps) => ({
        tab: getTab(state, ownProps),
        authState: state.auth,
        allStaff: getStaff(state, ownProps),
        review: getReview(state, ownProps),
        comments: getComments(state, ownProps)
    }),
    {
        ...ProposalReview.actions,
        ...StaffComment.actions
    }
)(Final_Comments);

export default withStyles(styles)(withRouter(Final_Comments));
