import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { Form } from "react-form";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import CircularProgress from "@material-ui/core/CircularProgress";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import Tooltip from "@material-ui/core/Tooltip";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";

import PersonIcon from "@material-ui/icons/Person";
import Visibility from "@material-ui/icons/Visibility";

import AppContainer from "../common/AppContainer";
import CustomTableCell from "../common/TableCell";
import EnhancedTableHead from "../common/EnhancedTableHead";
import PageHeader from "../common/PageHeader";
import TablePaginationActionsWrapped from "../common/Paginator";
import TextField from "../common/TextField";
import Select from "../common/Select";
import RadioGroup from "../common/RadioGroup";
import StateRadioGroup from "../common/StateRadioGroup";

import { ProposalView, MemberEvaluationComplete } from "../proposal/models";
import { MemberEvaluation, SystemSetting, ProposalReview, StaffScore } from "../home/models";
import { createSelector } from "../common/orm";

import * as navActions from "../common/actions";

function splitSearch(str, needed) {
    const params = new URLSearchParams(str);
    return params.get(needed);
}

const getStaffSlots = createSelector(schema => {
    return schema.StaffSlots.all().toModelArray();
});

const getRFPYears = createSelector(schema => {
    return schema.SystemSetting.all()
        .orderBy("year", "desc")
        .toRefArray();
});

const getYear = createSelector(
    (state, ownProps) => (ownProps.location.serach !== "" ? splitSearch(ownProps.location.search, "year") : null),
    (schema, year) => {
        return schema.SystemSetting.filter(x => x.year === year).first();
    }
);

const getCategories = createSelector(
    (state, ownProps) => (ownProps.location.serach !== "" ? splitSearch(ownProps.location.search, "year") : null),
    (schema, year) => {
        const setting = schema.SystemSetting.filter(x => x.year && parseInt(x.year) === year).first();

        var testreturn = schema.FundingCategory.all()
            .filter(x => x.year_label === year)
            .orderBy("suffix", "asc")
            .toModelArray()
            .map(cat => {
                return {
                    ...cat.ref,
                    draftProgress: schema.ProposalView.filter(
                        x =>
                            x.proposal_unique_id.includes(year) && (x.final_funding_category === cat.ref.category_name || (!x.final_funding_category && x.funding_category === cat.ref.category_name)) &&
                            x.status === "Draft In Progress"
                    ).toRefArray(),
                    draftSubmitted: schema.ProposalView.filter(
                        x =>
                            x.proposal_unique_id.includes(year) && (x.final_funding_category === cat.ref.category_name || (!x.final_funding_category && x.funding_category === cat.ref.category_name)) &&
                            x.status === "Draft Submitted"
                    ).toRefArray(),
                    feedback: schema.ProposalView.filter(
                        x =>
                            x.proposal_unique_id.includes(year) && (x.final_funding_category === cat.ref.category_name || (!x.final_funding_category && x.funding_category === cat.ref.category_name)) &&
                            x.status === "Draft Feedback Received"
                    ).toRefArray(),
                    finalProposals:
                        setting && setting.evaluation2_open
                            ? schema.ProposalView.filter(
                                  x =>
                                      x.proposal_unique_id.includes(year) && (x.final_funding_category === cat.ref.category_name || (!x.final_funding_category && x.funding_category === cat.ref.category_name)) &&
                                      (x.selected_presentation === false || x.selected_presentation === true) &&
                                      x.secondary_status !== "Withdrawn" &&
                                      x.secondary_status !== "Withdrawal Requested"
                              ).toRefArray()
                            : schema.ProposalView.filter(
                                  x =>
                                      x.proposal_unique_id.includes(year) && (x.final_funding_category === cat.ref.category_name || (!x.final_funding_category && x.funding_category === cat.ref.category_name)) &&
                                      (x.status === "Final Submitted" ||
                                          x.status === "Revisions Submitted" || x.status === "Revisions Needed") &&
                                      (x.secondary_status !== "Withdrawn" &&
                                          x.secondary_status !== "Withdrawal Requested")
                              ).toRefArray(),
                    selected: schema.ProposalView.filter(
                        x =>
                            x.proposal_unique_id.includes(year) && (x.final_funding_category === cat.ref.category_name || (!x.final_funding_category && x.funding_category === cat.ref.category_name)) &&
                            x.selected_presentation &&
                            (x.secondary_status !== "Withdrawn" && x.secondary_status !== "Withdrawal Requested")
                    ).toRefArray(),
                    recommended: schema.ProposalView.filter(
                        x =>
                            x.proposal_unique_id.includes(year) && (x.final_funding_category === cat.ref.category_name || (!x.final_funding_category && x.funding_category === cat.ref.category_name)) &&
                            x.recommended_for_funding &&
                            (x.secondary_status !== "Withdrawn" && x.secondary_status !== "Withdrawal Requested")
                    ).toRefArray()
                };
            });
            testreturn.push({
                category_name: "User has not entered in a category",
                suffix: "N/A",
                year_label: year,
                draftProgress: schema.ProposalView.filter(x => x.proposal_unique_id.includes(year) && !x.funding_category && x.status === "Draft In Progress").toRefArray(),
                draftSubmitted: schema.ProposalView.filter(x => x.proposal_unique_id.includes(year) && !x.funding_category && x.status === "Draft Submitted").toRefArray(),
                feedback: schema.ProposalView.filter(x => x.proposal_unique_id.includes(year) && !x.funding_category && x.status === "Draft Feedback Received").toRefArray(),
                finalProposals: setting && setting.evaluation2_open ? schema.ProposalView.filter(x =>
                    x.proposal_unique_id.includes(year) && !x.funding_category &&
                    (x.selected_presentation === false || x.selected_presentation === true) &&
                    x.secondary_status !== "Withdrawn" && x.secondary_status !== "Withdrawal Requested"
                ).toRefArray() : schema.ProposalView.filter(x =>
                    x.proposal_unique_id.includes(year) && !x.funding_category && 
                    (x.status === "Final Submitted" || x.status === "Revisions Submitted" || x.status === "Revisions Needed") &&
                    (x.secondary_status !== "Withdrawn" && x.secondary_status !== "Withdrawal Requested")
                ).toRefArray(),
                selected: schema.ProposalView.filter(x => x.proposal_unique_id.includes(year) && !x.funding_category && x.selected_presentation && x.secondary_status !== "Withdrawn" && x.secondary_status !== "Withdrawal Requested").toRefArray(),
                recommended: schema.ProposalView.filter(x => x.proposal_unique_id.includes(year) && !x.funding_category && x.recommended_for_funding && x.secondary_status !== "Withdrawn" && x.secondary_status !== "Withdrawal Requested").toRefArray()
            })
        return testreturn;
    }
);

const getReviews = createSelector(
    (state, ownProps) => (ownProps.location.serach !== "" ? splitSearch(ownProps.location.search, "year") : null),
    (schema, year) => {
        return schema.ProposalReview.all()
            .filter(x => x.year === year)
            .toModelArray();
    }
);

const totalProposal = createSelector(
    (state, ownProps) => (ownProps.location.serach !== "" ? splitSearch(ownProps.location.search, "year") : null),
    (schema, year) => {
        const setting = schema.SystemSetting.filter(x => x.year && parseInt(x.year) === year).first();

        if (setting && setting.evaluation2_open) {
            return schema.ProposalView.filter(
                x =>
                    x.ml_year === year &&
                    (x.selected_presentation === false || x.selected_presentation === true) &&
                    x.secondary_status !== "Withdrawn" &&
                    x.secondary_status !== "Withdrawal Requested"
            ).toRefArray();
        } else {
            return schema.ProposalView.filter(
                x =>
                    x.ml_year === year &&
                    (x.status === "Final Submitted" ||
                        x.status === "Revisions Submitted" ||
                        x.status === "Revisions Needed") &&
                    x.secondary_status !== "Withdrawn" &&
                    x.secondary_status !== "Withdrawal Requested"
            ).toRefArray();
        }
    }
);

const getScores = createSelector(
    (state, ownProps) => (ownProps.location.serach !== "" ? splitSearch(ownProps.location.search, "year") : null),
    (schema, year) => {
        const setting = schema.SystemSetting.filter(x => x.year && parseInt(x.year) === year).first();

        if (setting && setting.evaluation2_open) {
            return (
                schema.StaffScore.all()
                    .toModelArray()
                    // eslint-disable-next-line array-callback-return
                    .map(x => {
                        if (
                            x.proposal &&
                            x.proposal.ml_year === year &&
                            (x.proposal.selected_presentation === false || x.proposal.selected_presentation === true) &&
                            x.secondary_status !== "Withdrawn" &&
                            x.secondary_status !== "Withdrawal Requested"
                        ) {
                            return {
                                user: x.user,
                                ...x.ref
                            };
                        }
                    })
                    .filter(x => x)
            );
        } else {
            return (
                schema.StaffScore.all()
                    .toModelArray()
                    // eslint-disable-next-line array-callback-return
                    .map(x => {
                        if (
                            ((x.proposal && x.proposal.ml_year === year && x.proposal.status === "Final Submitted") ||
                                x.proposal.status === "Revisions Submitted" ||
                                x.status === "Revisions Needed") &&
                            x.secondary_status !== "Withdrawn" &&
                            x.secondary_status !== "Withdrawal Requested"
                        ) {
                            return {
                                user: x.user,
                                ...x.ref
                            };
                        }
                    })
                    .filter(x => x)
            );
        }
    }
);

const getRFPMembers = createSelector(
    (state, ownProps) =>
        ownProps.location.serach !== "" ? parseInt(splitSearch(ownProps.location.search, "rfp_id")) : null,
    (schema, year) => {
        return schema.RFPMember.all()
            .filter(x => x.year_id === year)
            .toModelArray()
            .map(member => {
                return {
                    member: member.user ? member.user : null,
                    eval: schema.MemberEvaluationComplete.filter(
                        z => z.user === member.ref.user && z.year === year
                    ).first(),
                    ...member.ref
                };
            });
    }
);

function getMedian(array) {
    array.sort(function(a, b) {
        return a - b;
    });
    var mid = array.length / 2;
    return mid % 1 ? array[mid - 0.5] : (array[mid - 1] + array[mid]) / 2;
}

const getReviewTable = createSelector(
    (state, ownProps) => (ownProps.location.serach !== "" ? splitSearch(ownProps.location.search, "year") : null),
    (state, ownProps) =>
        ownProps.location.serach !== "" ? parseInt(splitSearch(ownProps.location.search, "rfp_id")) : null,
    (schema, year, id_year) => {
        const setting = schema.SystemSetting.filter(x => x.year && x.year === year).first();
        const evalTwoClosed = setting && setting.member2_date ? new Date() > new Date(setting.member2_date) : false;

        if (setting && setting["evaluation2_open"]) {
            return schema.ProposalView.filter(
                x =>
                    x.ml_year === year &&
                    (x.selected_presentation === false || x.selected_presentation === true) &&
                    x.secondary_status !== "Withdrawn" &&
                    x.secondary_status !== "Withdrawal Requested"
            )
                .orderBy("proposal_unique_id", "asc")
                .toModelArray()
                .map(proposal => {
                    //  Going of rfp Members
                    const yearMembers = schema.RFPMember.all()
                        .filter(x => x.year_id === id_year)
                        .toRefArray();

                    const total = yearMembers.length;

                    const evals = proposal.eval_member
                        .all()
                        .filter(x => yearMembers.find(z => z.user === x.user_id))
                        .toModelArray()
                        .map(e => {
                            return {
                                user: schema.User.filter(x => x.id === e.user_id).first(),
                                ...e.ref
                            };
                        });

                    var org = schema.Organization.filter(x => x.id === proposal.organization_id).first();

                    const selected_total = total - evals.filter(x => x.conflict).length;
                    const selected = evals.filter(x => x.selected).length;
                    const compiled = Math.round((selected / selected_total) * 100);

                    const conflict = evals
                        .filter(x => x.conflict)
                        .map(x => (x.user ? x.user.first_name + " " + x.user.last_name : "Unknown"))
                        .join(", ");

                    const review = proposal.review_proposal.first();

                    const note_1 = evals.filter(x => x.comments && x.comments.length > 0).map(x => x.comments);
                    const note_2 = evals.filter(x => x.comments2 && x.comments2.length > 0).map(x => x.comments2);

                    const member_notes = evalTwoClosed ? note_2.join(", ") : note_1.join(", ");

                    const avg = Math.round(
                        evals.filter(x => x.score2 !== null).reduce((a, b) => a + b.score2, 0) /
                            evals.filter(x => x.score2 !== null).length
                    );

                    //selected_total is total not including members with conflict of interest
                    const score2_total = evals.filter(x => x.score2 !== null).length;
                    const scoring2Compiled = Math.round((score2_total / yearMembers.length) * 100);

                    const median = getMedian(evals.filter(x => x.score2 !== null).map(x => x.score2));

                    
                    const avg_allocation = Math.round(
                        evals.filter(x => x.allocation !== null).reduce((a, b) => a + b.allocation, 0) /
                            evals.filter(x => x.allocation !== null).length
                    );
                    const score2_total_allocation = evals.filter(x => x.allocation !== null).length;
                    const scoring2Compiled_allocation = Math.round((score2_total_allocation / selected_total) * 100);
                    const median_allocation = getMedian(evals.filter(x => x.allocation !== null).map(x => x.allocation));

                    // Category
                    var category = schema.FundingCategory.filter(
                        x =>
                            x.category_name ===
                                (proposal.final_funding_category
                                    ? proposal.final_funding_category
                                    : proposal.funding_category) && x.year_label === year
                    ).first();

                    // sub category
                    // if final_funding_category set use it and final_secondary_funding_category
                    var secondarySuffix = null;
                    if (proposal.funding_category === "Small Projects" && !proposal.final_funding_category) {
                        secondarySuffix = schema.FundingCategory.filter(
                            x => x.category_name === proposal.secondary_funding_category && x.year_label === year
                        ).first();
                    } else if (
                        proposal.final_funding_category &&
                        proposal.final_funding_category === "Small Projects"
                    ) {
                        secondarySuffix = schema.FundingCategory.filter(
                            x => x.category_name === proposal.final_secondary_funding_category && x.year_label === year
                        ).first();
                    }

                    var final_category = category && category.suffix ? category.suffix + ". " : "";
                    final_category += proposal.final_funding_category
                        ? proposal.final_funding_category
                        : proposal.funding_category
                        ? proposal.funding_category
                        : "";

                    if (proposal.final_funding_category) {
                        final_category +=
                            proposal.final_funding_category === "Small Projects" &&
                            proposal.final_secondary_funding_category
                                ? " / " +
                                  (secondarySuffix ? secondarySuffix.suffix + ". " : "") +
                                  proposal.final_secondary_funding_category
                                : "";
                    } else {
                        final_category +=
                            proposal.funding_category === "Small Projects" && proposal.secondary_funding_category
                                ? " / " +
                                  (secondarySuffix ? secondarySuffix.suffix + ". " : "") +
                                  proposal.secondary_funding_category
                                : "";
                    }

                    return {
                        score2_total_allocation,
                        scoring2Compiled_allocation: !isNaN(scoring2Compiled_allocation) ? scoring2Compiled_allocation : 0,
                        avg_allocation,
                        median_allocation: median_allocation !== null && !isNaN(median_allocation) ? Math.round(median_allocation) : NaN,
                        score2_total,
                        scoring2Compiled: !isNaN(scoring2Compiled) ? scoring2Compiled : 0,
                        compiled: !isNaN(compiled) ? compiled : 0,
                        total,
                        selected_total,
                        selected,
                        conflict,
                        category: category,
                        secondarySuffix: secondarySuffix ? secondarySuffix.suffix : null,
                        suffix: category ? category.suffix : null,
                        final_category,
                        member_notes,
                        avg,
                        median: median !== null && !isNaN(median) ? Math.round(median) : NaN,
                        mem_scoring2: yearMembers.length,
                        organization: org ? org.organization_name : "",
                        ...(review ? review : { final_score: null }),
                        ...proposal.ref
                    };
                });
        } else {
            return schema.ProposalView.filter(
                x =>
                    x.ml_year === year &&
                    (x.status === "Final Submitted" ||
                        x.status === "Revisions Submitted" ||
                        x.status === "Revisions Needed") &&
                    x.secondary_status !== "Withdrawn" &&
                    x.secondary_status !== "Withdrawal Requested"
            )
                .orderBy("proposal_unique_id", "asc")
                .toModelArray()
                .map(proposal => {
                    //  Going of rfp Members
                    const yearMembers = schema.RFPMember.all()
                        .filter(x => x.year_id === id_year)
                        .toRefArray();

                    const total = yearMembers.length;

                    const evals = proposal.eval_member
                        .all()
                        .filter(x => yearMembers.find(z => z.user === x.user_id))
                        .toModelArray()
                        .map(e => {
                            return {
                                user: schema.User.filter(x => x.id === e.user_id).first(),
                                ...e.ref
                            };
                        });

                    var org = schema.Organization.filter(x => x.id === proposal.organization_id).first();

                    const selected_total = total - evals.filter(x => x.conflict).length;
                    const selected = evals.filter(x => x.selected).length;
                    const compiled = Math.round((selected / selected_total) * 100);

                    const conflict = evals
                        .filter(x => x.conflict)
                        .map(x => (x.user ? x.user.first_name + " " + x.user.last_name : "Unknown"))
                        .join(", ");

                    const review = proposal.review_proposal.first();

                    const note_1 = evals.filter(x => x.comments && x.comments.length > 0).map(x => x.comments);
                    const note_2 = evals.filter(x => x.comments2 && x.comments2.length > 0).map(x => x.comments2);

                    const member_notes = evalTwoClosed ? note_2.join(", ") : note_1.join(", ");

                    const avg = Math.round(
                        evals.filter(x => x.score2 !== null).reduce((a, b) => a + b.score2, 0) /
                            evals.filter(x => x.score2 !== null).length
                    );

                    //selected_total is total not including members with conflict of interest
                    const score2_total = evals.filter(x => x.score2 !== null).length;
                    const scoring2Compiled = Math.round((score2_total / yearMembers.length) * 100);

                    const median = getMedian(evals.filter(x => x.score2 !== null).map(x => x.score2));

                    
                    const avg_allocation = Math.round(
                        evals.filter(x => x.allocation !== null).reduce((a, b) => a + b.allocation, 0) /
                            evals.filter(x => x.allocation !== null).length
                    );
                    const score2_total_allocation = evals.filter(x => x.allocation !== null).length;
                    const scoring2Compiled_allocation = Math.round((score2_total_allocation / selected_total) * 100);
                    const median_allocation = getMedian(evals.filter(x => x.allocation !== null).map(x => x.allocation));

                    // Category
                    var category = schema.FundingCategory.filter(
                        x =>
                            x.category_name ===
                                (proposal.final_funding_category
                                    ? proposal.final_funding_category
                                    : proposal.funding_category) && x.year_label === year
                    ).first();

                    var secondarySuffix = null;
                    if (proposal.funding_category === "Small Projects" && !proposal.final_funding_category) {
                        secondarySuffix = schema.FundingCategory.filter(
                            x => x.category_name === proposal.secondary_funding_category && x.year_label === year
                        ).first();
                    } else if (
                        proposal.final_funding_category &&
                        proposal.final_funding_category === "Small Projects"
                    ) {
                        secondarySuffix = schema.FundingCategory.filter(
                            x => x.category_name === proposal.final_secondary_funding_category && x.year_label === year
                        ).first();
                    }

                    var final_category = category && category.suffix ? category.suffix + ". " : "";
                    final_category += proposal.final_funding_category
                        ? proposal.final_funding_category
                        : proposal.funding_category
                        ? proposal.funding_category
                        : "";

                    if (proposal.final_funding_category) {
                        final_category +=
                            proposal.final_funding_category === "Small Projects" &&
                            proposal.final_secondary_funding_category
                                ? " / " +
                                  (secondarySuffix ? secondarySuffix.suffix + ". " : "") +
                                  proposal.final_secondary_funding_category
                                : "";
                    } else {
                        final_category +=
                            proposal.funding_category === "Small Projects" && proposal.secondary_funding_category
                                ? " / " +
                                  (secondarySuffix ? secondarySuffix.suffix + ". " : "") +
                                  proposal.secondary_funding_category
                                : "";
                    }

                    return {
                        score2_total_allocation,
                        scoring2Compiled_allocation: !isNaN(scoring2Compiled_allocation) ? scoring2Compiled_allocation : 0,
                        avg_allocation,
                        median_allocation: median_allocation !== null && !isNaN(median_allocation) ? Math.round(median_allocation) : NaN,
                        score2_total,
                        scoring2Compiled: !isNaN(scoring2Compiled) ? scoring2Compiled : 0,
                        compiled: !isNaN(compiled) ? compiled : 0,
                        total,
                        selected_total,
                        selected,
                        conflict,
                        category: category,
                        secondarySuffix: secondarySuffix ? secondarySuffix.suffix : null,
                        suffix: category ? category.suffix : null,
                        final_category,
                        member_notes,
                        avg,
                        median: median !== null && !isNaN(median) ? Math.round(median) : NaN,
                        mem_scoring2: yearMembers.length,
                        organization: org ? org.organization_name : "",
                        ...(review ? review : { final_score: null }),
                        ...proposal.ref
                    };
                });
        }
    }
);

const styles = theme => ({
    select: {
        width: 200
    },
    table: {
        width: "100%",
        "& tbody tr:nth-child(even)": {
            backgroundColor: "#eee"
        },
        marginBottom: 20,
        backgroundColor: "#fff"
    },
    deleteWidth: {
        minWidth: 34,
        width: 34,
        marginRight: 8
    },
    centerAlign: {
        textAlign: "center"
    },
    expansionSummaryRoot: {
        backgroundColor: "#eee",
        minHeight: 64,
        borderBottom: "1px dotted",
        "& div:first-child": {
            margin: 0,
            marginTop: 0
        }
    },
    expansionIcon: {
        marginTop: 6,
        marginBottom: -6,
        marginRight: 8
    },
    nowrap: {
        whiteSpace: "nowrap"
    },
    flex: {
        flex: 1
    },
    cellWidth_2: {
        minWidth: "200px !important"
    },
    cellWidth_3: {
        minWidth: "300px !important"
    }
});

const proposalSummaryData = [
    { id: "category", numeric: false, label: "Category" },
    { id: "draftProgress", numeric: false, label: "Proposals in progress" },
    { id: "draftSubmitted", numeric: false, label: "Draft Proposals Submitted" },
    { id: "feeBack", numeric: false, label: "Proposals Feed Back Received" },
    { id: "draftAmount", numeric: false, label: "Draft $ Requested" },
    { id: "finalSubmitted", numeric: false, label: "Final Proposals Submitted" },
    { id: "finalAmount", numeric: false, label: "Final $ Requested" },
    { id: "selected", numeric: true, label: "Proposals Selected For Presentation" },
    { id: "presentationAmount", numeric: true, label: "Presentation $ Requested" },
    { id: "recommended", numeric: false, label: "Proposals Recommmended For Funding" },
    { id: "recommendedAmount", numeric: false, label: "$ Recommended Amount" }
];

const reviewProcessData = [
    { id: "category", numeric: false, label: "Actions" },
    { id: "proposal_unique_id", numeric: false, label: "Proposal ID", allowSort: true },
    { id: "name", numeric: false, label: "Project Title", allowSort: true },
    { id: "summary", numeric: false, label: "30 Word Summary", allowSort: true },
    { id: "final_category", numeric: false, label: "Category", allowSort: true },
    { id: "organization", numeric: false, label: "Organization", allowSort: true },
    { id: "pm_last_name", numeric: false, label: "Project Manager", allowSort: true },
    { id: "funding_total", numeric: true, label: "$ Requested", allowSort: true },
    { id: "status", numeric: false, label: "Proposal Status", allowSort: true },
    { id: "secondary_Status", numeric: false, label: "LCCMR/Legislative Status", allowSort: true },
    { id: "final_score", numeric: true, label: "Staff Final Score", allowSort: true },
    { id: "final_member_comments2", numeric: false, label: "Comments #2 for Members (i.e., external)", allowSort: true },
    { id: "compiled", numeric: true, label: "Compiled Member Evaluation #1", allowSort: true },
    { id: "selected", numeric: true, label: "# of Members Selecting For Presentation", allowSort: true },
    { id: "conflict", numeric: false, label: "Members With Conflict of Interest" },
    { id: "member_notes", numeric: false, label: "Member Notes" },
    {
        id: "selected_presentation",
        numeric: false,
        label: "LCCMR Selected for Presentations",
        allowSort: true,
        bool: true
    },
    { id: "median", numeric: true, label: "Compiled Evaluation #2 Median Score", allowSort: true },
    { id: "avg", numeric: true, label: "Compiled Evaluation #2 Average Score", allowSort: true },
    { id: "scoring2Compiled", numeric: true, label: "# of Members Scoring Evaluation #2", allowSort: true },
    { id: "recommended_for_funding", numeric: false, label: "LCCMR Recommended", allowSort: true, bool: true },
    { id: "recommended_amount", numeric: true, label: "LCCMR Recommended Amount", allowSort: true },
    { id: "contingencies", numeric: false, label: "Contingencies", allowSort: true }
];

const reviewProcessDataAllocation = [
    { id: "category", numeric: false, label: "Actions" },
    { id: "proposal_unique_id", numeric: false, label: "Proposal ID", allowSort: true },
    { id: "name", numeric: false, label: "Project Title", allowSort: true },
    { id: "summary", numeric: false, label: "30 Word Summary", allowSort: true },
    { id: "final_category", numeric: false, label: "Category", allowSort: true },
    { id: "organization", numeric: false, label: "Organization", allowSort: true },
    { id: "pm_last_name", numeric: false, label: "Project Manager", allowSort: true },
    { id: "funding_total", numeric: true, label: "$ Requested", allowSort: true },
    { id: "status", numeric: false, label: "Proposal Status", allowSort: true },
    { id: "secondary_Status", numeric: false, label: "LCCMR/Legislative Status", allowSort: true },
    { id: "final_score", numeric: true, label: "Staff Final Score", allowSort: true },
    { id: "final_member_comments2", numeric: false, label: "Comments #2 for Members (i.e., external)", allowSort: true },
    { id: "compiled", numeric: true, label: "Compiled Member Evaluation #1", allowSort: true },
    { id: "selected", numeric: true, label: "# of Members Selecting For Presentation", allowSort: true },
    { id: "conflict", numeric: false, label: "Members With Conflict of Interest" },
    { id: "member_notes", numeric: false, label: "Member Notes" },
    {
        id: "selected_presentation",
        numeric: false,
        label: "LCCMR Selected for Presentations",
        allowSort: true,
        bool: true
    },
    { id: "median_allocation", numeric: true, label: "Compiled Evaluation #2 Median Allocation", allowSort: true },
    { id: "avg_allocation", numeric: true, label: "Compiled Evaluation #2 Average Allocation", allowSort: true },
    { id: "scoring2Compiled_allocation", numeric: true, label: "# of Members Allocating Evaluation #2", allowSort: true },
    { id: "recommended_for_funding", numeric: false, label: "LCCMR Recommended", allowSort: true, bool: true },
    { id: "recommended_amount", numeric: true, label: "LCCMR Recommended Amount", allowSort: true },
    { id: "contingencies", numeric: false, label: "Contingencies", allowSort: true }
];

class ConfigureProposalProcess extends Component {
    state = {
        pulled: false,
        sorted: false,
        page: 0,
        rowsPerPage: 50,
        order: "asc",
        orderBy: "proposal_unique_id",
        dont: false,
        filter: "All",
        loaded: false,
        defaultSorted: true,
        reviewSort: ""
    };

    componentDidMount() {
        document.title = "Proposal Process - LCCMR Proposal and Grant Management System";
        const { authState, history, sort } = this.props;
        if (authState && authState.user && authState.user.role !== "Staff") {
            history.push("/dashboard");
        }

        //handleSortRequest flips orderBy so flip before call to keep same
        if (authState && sort) {
            if (sort.sortInfo) {
                this.setState({
                    ...sort.sortInfo,
                    order: sort.sortInfo.order ? (sort.sortInfo.order === "asc" ? "desc" : "asc") : null,
                    pulled: true
                });
                this.handleYear(sort.sortInfo.activeRFP.year);
            }
        }
    };

    componentDidUpdate() {
        const { authState, sort, sync } = this.props;
        const { dont, pulled, sorted } = this.state;
        if (dont) {
            this.setState({ dont: false });
        }

        if (authState && pulled && sort.sortInfo && sync.ready && !sorted) {
            if (sort.sortInfo.radioSort) {
                this.radioSort(sort.sortInfo.radioSort, "reviewProcess");
            } else if (sort.sortInfo.orderBy) {
                this.setState({ sortKey: "reviewProcess" });
                this.handleSortRequest(null, sort.sortInfo.orderBy, "reviewProcess");
            }
            this.setState({ sorted: true, reviewSort: sort.sortInfo.radioSort });
        }
    };

    handleChangePage = (event, page) => {
        this.setState({ page });
    };

    handleChangeRowsPerPage = event => {
        this.setState({ rowsPerPage: event.target.value, page: 0 });
    };

    handleSwap = val => {
        this.setState({ dont: true, evaluation: !val });
    };

    handleYear = e => {
        const {
            sort,
            history,
            rfpYears,
            ormMemberEvaluationLoadDetailChildFilterMany,
            ormMemberEvaluationCompleteLoadDetailChildFilterMany,
            ormProposalReviewLoadDetailChildFilterMany,
            ormStaffScoreLoadDetailChildFilterMany
        } = this.props;
        const index = rfpYears.findIndex(x => x.year === e);
        const active = index > -1 ? rfpYears[index] : {};

        // these two params are used in most selectors
        history.push({ search: `year=${e.toString()}&rfp_id=${active.id}` });

        this.setState({
            page: 0,
            rowsPerPage: 50,
            dont: true,
            activeRFP: active,
            loaded: true,
            defaultSorted: true,
        });

        // If sort information is in store assume they are navigating back from proposal staff slot tabs on proposal page. This will reduce the number of
        // times it reloads member evaluations as it can take anywhere from 2-10 seconds.
        if (
            !sort.sortInfo ||
            !sort.sortInfo.order ||
            (sort.sortInfo && sort.sortInfo.activeRFP && sort.sortInfo.activeRFP.year !== e)
        ) {
            //load by year
            ormProposalReviewLoadDetailChildFilterMany({
                year: e
            });
            ormMemberEvaluationLoadDetailChildFilterMany({
                year: e
            });
            ormStaffScoreLoadDetailChildFilterMany({
                year: e
            });
            ormMemberEvaluationCompleteLoadDetailChildFilterMany({
                year: e
            });
        }
    };

    filterByValue = (array, string) => {
        return array.filter(o =>
            Object.keys(o).some(
                k =>
                    o[k] &&
                    o[k]
                        .toString()
                        .toLowerCase()
                        .includes(string.toLowerCase())
            )
        );
    };

    sortSubCategory = list => {
        var grouped = {};

        for (var i = 0; i < list.length; i += 1) {
            if (list[i].secondarySuffix && !grouped[list[i].secondarySuffix]) {
                grouped[list[i].secondarySuffix] = [];
            } else if (!list[i].secondarySuffix && !grouped[""]) {
                grouped[""] = [];
            }
            grouped[list[i].secondarySuffix ? list[i].secondarySuffix : ""].push(list[i]);
        }

        for (var group in grouped) {
            grouped[group] = grouped[group].sort(function(a, b) {
                return (a.proposal_unique_id || "").toUpperCase() < (b.proposal_unique_id || "").toUpperCase() ? -1 : 1;
            });
        }
        var keys = Object.keys(grouped).sort(function(a, b) {
            if (a === "") return 1;
            if (b === "") return -1;

            return (b || "").toUpperCase() > (a || "").toUpperCase() ? -1 : 1;
        });

        var arr = [];

        keys.forEach(x => {
            arr = arr.concat(grouped[x]);
        });

        return arr;
    };

    defaultSort = (key, stateKey) => {
        const values = this.props[key];

        var grouped = {};

        for (var i = 0; i < values.length; i += 1) {
            if (values[i].category && !grouped[values[i].category.suffix]) {
                grouped[values[i].category.suffix] = [];
            } else if (!values[i].category && !grouped[""]) {
                grouped[""] = [];
            }
            grouped[values[i].category ? values[i].category.suffix : ""].push(values[i]);
        }

        for (var group in grouped) {
            grouped[group] = grouped[group].sort(function(a, b) {
                return (a.proposal_unique_id || "").toUpperCase() < (b.proposal_unique_id || "").toUpperCase() ? -1 : 1;
            });
        }
        var keys = Object.keys(grouped).sort(function(a, b) {
            if (a === "") return 1;
            if (b === "") return -1;

            return (b || "").toUpperCase() > (a || "").toUpperCase() ? -1 : 1;
        });

        if (grouped["H"] && grouped["H"].length > 1) {
            grouped["H"] = this.sortSubCategory(grouped["H"]);
        }

        var arr = [];

        keys.forEach(x => {
            arr = arr.concat(grouped[x]);
        });

        this.props[key].sort(function(a, b) {
            return arr.findIndex(x => x.id === a.id) - arr.findIndex(z => z.id === b.id);
        });

        this.setState({ [stateKey]: false });
    };

    reviewRadioSort = (val, propKey) => {
        this.setState({ reviewSort: val });
        this.radioSort(val, propKey);
    };

    radioSort = (val, propKey) => {
        if (["final_score", "compiled", "median", "avg", "avg_allocation", "median_allocation"].includes(val)) {
            this.props[propKey].sort(function(a, b) {
                if (a[val] === b[val]) {
                    return 0;
                }
                // nulls sort after anything else
                else if (a[val] === null || isNaN(a[val])) {
                    return 1;
                } else if (b[val] === null || isNaN(b[val])) {
                    return -1;
                } else {
                    return parseFloat(b[val]) < parseFloat(a[val]) ? -1 : 1;
                }
            });
        } else {
            var key = "";
            if (val === "avg_category") key = "avg";
            else if (val === "final_category") key = "final_score";
            else if (val === "median_category") key = "median";
            else if (val === "median_category_allocation") key = "median_allocation";
            else if (val === "avg_category_allocation") key = "avg_allocation";
            else key = "compiled";

            var grouped = {};

            for (var i = 0; i < this.props[propKey].length; i += 1) {
                if (this.props[propKey][i].category && !grouped[this.props[propKey][i].category.suffix]) {
                    grouped[this.props[propKey][i].category.suffix] = [];
                } else if (!this.props[propKey][i].category && !grouped[""]) {
                    grouped[""] = [];
                }
                grouped[this.props[propKey][i].category ? this.props[propKey][i].category.suffix : ""].push(
                    this.props[propKey][i]
                );
            }

            for (var group in grouped) {
                grouped[group] = grouped[group].sort(function(a, b) {
                    if (a[key] === b[key]) {
                        return 0;
                    }
                    // nulls sort after anything else
                    else if (a[key] === null || isNaN(a[key])) {
                        return 1;
                    } else if (b[key] === null || isNaN(b[key])) {
                        return -1;
                    } else {
                        return parseFloat(b[key]) < parseFloat(a[key]) ? -1 : 1;
                    }
                });
            }

            var keys = Object.keys(grouped).sort(function(a, b) {
                return (b || "").toUpperCase() > (a || "").toUpperCase() ? -1 : 1;
            });

            for (var group2 in grouped) {
                grouped[group2] = grouped[group2].sort(function(a, b) {
                    if (a[key] === b[key]) {
                        return (a.proposal_unique_id || "").toUpperCase() < (b.proposal_unique_id || "").toUpperCase()
                            ? -1
                            : 1;
                    } else {
                        return b[key] - a[key];
                    }
                });
            }

            if (grouped["H"] && grouped["H"].length > 1) {
                grouped["H"] = this.sortSubCategory(grouped["H"]);
            }

            var arr = [];

            keys.forEach(x => {
                arr = arr.concat(grouped[x]);
            });

            this.props[propKey].sort(function(a, b) {
                return arr.findIndex(x => x.id === a.id) - arr.findIndex(z => z.id === b.id);
            });
        }

        this.setState({ radioSort: val, orderBy: null });
    };

    // EnhancedTabledHead needs "name" passed to it
    handleSortRequest = (event, property, sortKey) => {
        const { rfpYear } = this.props;
        const orderBy = property;
        let order = "desc";

        if (this.state.orderBy === property && this.state.order === "desc") {
            order = "asc";
        }

        var use_allocation = false;
        if (rfpYear && parseInt(rfpYear.year) >= 2023) {
            use_allocation = true;
        }
        var date, numeric, bool;
        this.props[sortKey].sort(function(a, b) {
            if (use_allocation) {
                date = reviewProcessDataAllocation.find(cD => cD.id === orderBy).date;
                numeric = reviewProcessDataAllocation.find(cD => cD.id === orderBy).numeric;
                bool = reviewProcessDataAllocation.find(cD => cD.id === orderBy).bool;
            } else {
                date = reviewProcessData.find(cD => cD.id === orderBy).date;
                numeric = reviewProcessData.find(cD => cD.id === orderBy).numeric;
                bool = reviewProcessData.find(cD => cD.id === orderBy).bool;
            }

            if (date) {
                if (order === "desc") return new Date(b[orderBy]) < new Date(a[orderBy]) ? -1 : 1;
                else return new Date(a[orderBy]) < new Date(b[orderBy]) ? -1 : 1;
            } else if (bool) {
                if (order === "desc") return b[orderBy] - a[orderBy];
                else return a[orderBy] - b[orderBy];
            } else if (numeric) {
                if (order === "desc") return parseFloat(b[orderBy] || 0) < parseFloat(a[orderBy] || 0) ? -1 : 1;
                else return parseFloat(a[orderBy] || 0) < parseFloat(b[orderBy] || 0) ? -1 : 1;
            } else {
                if (order === "desc")
                    return (b[orderBy] || "").toUpperCase() < (a[orderBy] || "").toUpperCase() ? -1 : 1;
                else return (a[orderBy] || "").toUpperCase() < (b[orderBy] || "").toUpperCase() ? -1 : 1;
            }
        });

        this.setState({ order, orderBy, radioSort: null });
    };

    saveSortInfo = () => {
        const { processSet } = this.props;
        const { activeRFP, rowsPerPage, order, orderBy, page, radioSort, wordFilter, filter } = this.state;

        processSet({
            activeRFP,
            rowsPerPage,
            order,
            orderBy,
            page,
            wordFilter,
            radioSort,
            filter
        });
    };

    getSummary = reviews => {
        const { scores, staffSlots } = this.props;
        var arr = [];

        //if (reviews.length === 0) {
            arr = [];
            staffSlots.forEach(z => {
                arr[z.slot - 1] = {
                    user: z.staff,
                    done: z.staff ? scores.filter(x => x.user === z.staff.id && x.done) : [],
                    //not: z.staff ? scores.filter(x => x.user === z.staff.id) : []
                };
            });
            return arr;
        //}

        /*arr = [];
        var grouped = {};
        for (var j = 1; j < 6; j++) {
            if (reviews[0][`staff_${j}`]) {
                if (!grouped[reviews[0][`staff_${j}`].id]) {
                    grouped[reviews[0][`staff_${j}`].id] = {};
                }
                grouped[reviews[0][`staff_${j}`].id].slot = j;
                grouped[reviews[0][`staff_${j}`].id].user = reviews[0][`staff_${j}`];
                grouped[reviews[0][`staff_${j}`].id].done = scores.filter(
                    x => x.user === reviews[0][`staff_${j}`].id && x.done // eslint-disable-line no-loop-func
                );
            }
        }*/
        /*for (var i = 0; i < reviews.length; i += 1) {
            for (var j = 1; j < 5; j++) {
                if (reviews[i][`staff_${j}`]) {
                    if (!grouped[reviews[i][`staff_${j}`].id]) {
                        grouped[reviews[i][`staff_${j}`].id] = {};
                    }
                    grouped[reviews[i][`staff_${j}`].id].slot = j;
                    grouped[reviews[i][`staff_${j}`].id].user = reviews[i][`staff_${j}`];
                    grouped[reviews[i][`staff_${j}`].id].done = scores.filter(
                        x => x.user === reviews[i][`staff_${j}`].id && x.done // eslint-disable-line no-loop-func
                    );
                }
            }
        }

        const keys = Object.keys(grouped);

        keys.forEach(x => {
            arr.push(grouped[x]);
        });

        arr.sort(function(a, b) {
            return a.slot - b.slot;
        });

        return arr;*/
    };

    countTotals = (categories, key) => {
        return categories.reduce((a, b) => a + b[key].length, 0);
    };

    totalCategoryDollar = (categories, key) => {
        var secKey = key === "recommended" ? "recommended_amount" : "funding_total";
        var total = 0;
        categories.forEach(x => {
            total += x[key].reduce((a, b) => a + b[secKey], 0);
        });
        return total;
    };

    render() {
        const {
            sync,
            classes,
            categories,
            sort,
            totalProposal,
            reviews,
            rfpYears,
            rfpMembers,
            reviewProcess,
            rfpYear
        } = this.props;
        const { loaded, defaultSorted, activeRFP, rowsPerPage, page, order, orderBy, wordFilter, filter } = this.state;

        var use_allocation = false;
        if (rfpYear && parseInt(rfpYear.year) >= 2023) {
            use_allocation = true;
        }

        if (sync && sync.ready && loaded && defaultSorted) {
            this.defaultSort("reviewProcess", "defaultSorted");
        }

        const years = rfpYears.map(x => ({
            label: x.year,
            value: x.year
        }));

        const table1 = rfpMembers.map(x => ({
            id: x.slot,
            numeric: false,
            label: x.member.first_name + " " + x.member.last_name
        }));

        var total = this.getSummary(reviews);

        var filtered = reviewProcess;

        if (wordFilter) {
            filtered = this.filterByValue(reviewProcess, wordFilter);
        }

        if (filter !== "All") {
            if (filter === "Selected for Further Consideration") {
                filtered = filtered.filter(x => x.selected_presentation === true);
            } else if (filter === "Not Selected for Further Consideration") {
                filtered = filtered.filter(x => x.selected_presentation === false);
            } else if (filter === "Recommended for Funding") {
                filtered = filtered.filter(x => x.recommended_for_funding === true);
            } else if (filter === "Not Recommended for Funding") {
                filtered = filtered.filter(x => x.recommended_for_funding === false);
            }
        }

        return (
            <AppContainer authenticated>
                <Grid container spacing={16}>
                    <PageHeader title="Administration - Proposal Process" admin_page />
                    <Grid item xs={12}>
                        <Form
                            dontValidateOnMount={true}
                            validateOnSubmit={true}
                            defaultValues={sort.sortInfo ? { rfp_year: sort.sortInfo.activeRFP.year } : null}>
                            {formApi => (
                                <form>
                                    <Select
                                        className={classes.select}
                                        field="rfp_year"
                                        label="RFP Year/M.L Year"
                                        options={years}
                                        eventHandle={val => {
                                            this.handleYear(val);
                                        }}
                                    />
                                </form>
                            )}
                        </Form>
                    </Grid>
                    <Grid item xs={12}>
                        <ExpansionPanel CollapseProps={{ unmountOnExit: true }} defaultExpanded={false} disabled={!activeRFP}>
                            <ExpansionPanelSummary
                                className={classes.expansionSummaryRoot}
                                expandIcon={<ExpandMoreIcon />}>
                                <Typography variant="h3" style={{ width: "100%" }}>
                                    <PersonIcon className={classes.expansionIcon} />
                                    Proposal Summary
                                </Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                <Table className={classes.table}>
                                    <EnhancedTableHead
                                        columnData={proposalSummaryData}
                                        stickyHeader
                                        order={order}
                                        orderBy={orderBy}
                                        onRequestSort={this.handleRequestSort}
                                    />
                                    <TableBody>
                                        {categories.map(n => {
                                            return (
                                                <TableRow key={n.id + n.suffix}>
                                                    <CustomTableCell style={{ whiteSpace: "nowrap" }}>
                                                        {n.suffix}. {n.category_name}
                                                    </CustomTableCell>
                                                    <CustomTableCell>{n.draftProgress.length}</CustomTableCell>
                                                    <CustomTableCell>{n.draftSubmitted.length}</CustomTableCell>
                                                    <CustomTableCell>{n.feedback.length}</CustomTableCell>
                                                    <CustomTableCell>
                                                        $
                                                        {n.draftSubmitted
                                                            .reduce((a, b) => a + b.funding_total, 0)
                                                            .toLocaleString()}
                                                    </CustomTableCell>
                                                    <CustomTableCell>{n.finalProposals.length}</CustomTableCell>
                                                    <CustomTableCell>
                                                        $
                                                        {n.finalProposals
                                                            .reduce((a, b) => a + b.funding_total, 0)
                                                            .toLocaleString()}
                                                    </CustomTableCell>
                                                    <CustomTableCell>{n.selected.length}</CustomTableCell>
                                                    <CustomTableCell>
                                                        $
                                                        {n.selected
                                                            .reduce((a, b) => a + b.funding_total, 0)
                                                            .toLocaleString()}
                                                    </CustomTableCell>
                                                    <CustomTableCell>{n.recommended.length}</CustomTableCell>
                                                    <CustomTableCell>
                                                        $
                                                        {n.recommended
                                                            .reduce((a, b) => a + b.recommended_amount, 0)
                                                            .toLocaleString()}
                                                    </CustomTableCell>
                                                </TableRow>
                                            );
                                        })}
                                        {categories.length > 1 && (
                                            <TableRow key="summary">
                                                <CustomTableCell style={{ whiteSpace: "nowrap" }}>
                                                    Total
                                                </CustomTableCell>
                                                <CustomTableCell>
                                                    {this.countTotals(categories, "draftProgress")}
                                                </CustomTableCell>
                                                <CustomTableCell>
                                                    {this.countTotals(categories, "draftSubmitted")}
                                                </CustomTableCell>
                                                <CustomTableCell>
                                                    {this.countTotals(categories, "feedback")}
                                                </CustomTableCell>
                                                <CustomTableCell>
                                                    $
                                                    {this.totalCategoryDollar(
                                                        categories,
                                                        "draftSubmitted"
                                                    ).toLocaleString()}
                                                </CustomTableCell>
                                                <CustomTableCell>
                                                    {this.countTotals(categories, "finalProposals")}
                                                </CustomTableCell>
                                                <CustomTableCell>
                                                    $
                                                    {this.totalCategoryDollar(
                                                        categories,
                                                        "finalProposals"
                                                    ).toLocaleString()}
                                                </CustomTableCell>
                                                <CustomTableCell>
                                                    {this.countTotals(categories, "selected")}
                                                </CustomTableCell>
                                                <CustomTableCell>
                                                    $
                                                    {this.totalCategoryDollar(
                                                        categories,
                                                        "selected"
                                                    ).toLocaleString()}
                                                </CustomTableCell>
                                                <CustomTableCell>
                                                    {this.countTotals(categories, "recommended")}
                                                </CustomTableCell>
                                                <CustomTableCell>
                                                    $
                                                    {this.totalCategoryDollar(
                                                        categories,
                                                        "recommended"
                                                    ).toLocaleString()}
                                                </CustomTableCell>
                                            </TableRow>
                                        )}
                                        {categories.length < 1 && (
                                            <TableRow>
                                                <CustomTableCell colSpan={11} className={classes.centerAlign}>
                                                    <caption style={{display:"inline"}}>No Summary Found</caption>
                                                </CustomTableCell>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </ExpansionPanelDetails>
                            {categories.length > 25 && (
                                <ExpansionPanelDetails>
                                    <Table>
                                        <TableFooter>
                                            <TableRow>
                                                <TablePagination
                                                    colSpan={11}
                                                    count={categories.length}
                                                    rowsPerPage={rowsPerPage}
                                                    page={page}
                                                    onChangePage={this.handleChangePage}
                                                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                                    ActionsComponent={TablePaginationActionsWrapped}
                                                />
                                            </TableRow>
                                        </TableFooter>
                                    </Table>
                                </ExpansionPanelDetails>
                            )}
                        </ExpansionPanel>
                        <ExpansionPanel CollapseProps={{ unmountOnExit: true }} defaultExpanded={false} disabled={!activeRFP}>
                            <ExpansionPanelSummary
                                className={classes.expansionSummaryRoot}
                                expandIcon={<ExpandMoreIcon />}>
                                <Typography variant="h3" style={{ width: "100%" }}>
                                    <PersonIcon className={classes.expansionIcon} />
                                    Staff Review and Evaluation Summary
                                </Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                <Table className={classes.table}>
                                    <EnhancedTableHead
                                        stickyHeader
                                        columnData={[
                                            { id: "staff", numeric: false, label: "Staff" },
                                            {
                                                id: "final_complete",
                                                numeric: false,
                                                label: "Number of Final Proposal Review Complete"
                                            },
                                            {
                                                id: "final_remaining",
                                                numeric: false,
                                                label: "Number of Final Proposal Review Remaining"
                                            }
                                        ]}
                                    />
                                    <TableBody>
                                        <TableRow>
                                            <CustomTableCell>
                                                {total[0] && total[0].user
                                                    ? total[0].user.first_name + " " + total[0].user.last_name
                                                    : "-"}
                                            </CustomTableCell>
                                            <CustomTableCell>
                                                {total[0] ? total[0]["done"].length : "-"}
                                            </CustomTableCell>
                                            <CustomTableCell>
                                                {total[0] ? totalProposal.length - total[0]["done"].length : "-"}
                                            </CustomTableCell>
                                        </TableRow>
                                        <TableRow>
                                            <CustomTableCell>
                                                {total[1] && total[1].user
                                                    ? total[1].user.first_name + " " + total[1].user.last_name
                                                    : "-"}
                                            </CustomTableCell>
                                            <CustomTableCell>
                                                {total[1] ? total[1]["done"].length : "-"}
                                            </CustomTableCell>
                                            <CustomTableCell>
                                                {total[1] ? totalProposal.length - total[1]["done"].length : "-"}
                                            </CustomTableCell>
                                        </TableRow>
                                        <TableRow>
                                            <CustomTableCell>
                                                {total[2] && total[2].user
                                                    ? total[2].user.first_name + " " + total[2].user.last_name
                                                    : "-"}
                                            </CustomTableCell>
                                            <CustomTableCell>
                                                {total[2] ? total[2]["done"].length : "-"}
                                            </CustomTableCell>
                                            <CustomTableCell>
                                                {total[2] ? totalProposal.length - total[2]["done"].length : "-"}
                                            </CustomTableCell>
                                        </TableRow>
                                        <TableRow>
                                            <CustomTableCell>
                                                {total[3] && total[3].user
                                                    ? total[3].user.first_name + " " + total[3].user.last_name
                                                    : "-"}
                                            </CustomTableCell>
                                            <CustomTableCell>
                                                {total[3] ? total[3]["done"].length : "-"}
                                            </CustomTableCell>
                                            <CustomTableCell>
                                                {total[3] ? totalProposal.length - total[3]["done"].length : "-"}
                                            </CustomTableCell>
                                        </TableRow>
                                        <TableRow>
                                            <CustomTableCell>
                                                {total[4] && total[4].user
                                                    ? total[4].user.first_name + " " + total[4].user.last_name
                                                    : "-"}
                                            </CustomTableCell>
                                            <CustomTableCell>
                                                {total[4] ? total[4]["done"].length : "-"}
                                            </CustomTableCell>
                                            <CustomTableCell>
                                                {total[4] ? totalProposal.length - total[4]["done"].length : "-"}
                                            </CustomTableCell>
                                        </TableRow>
                                        <TableRow>
                                            <CustomTableCell>
                                                {total[5] && total[5].user
                                                    ? total[5].user.first_name + " " + total[5].user.last_name
                                                    : "-"}
                                            </CustomTableCell>
                                            <CustomTableCell>
                                                {total[5] ? total[5]["done"].length : "-"}
                                            </CustomTableCell>
                                            <CustomTableCell>
                                                {total[5] ? totalProposal.length - total[5]["done"].length : "-"}
                                            </CustomTableCell>
                                        </TableRow>
                                        <TableRow>
                                            <CustomTableCell>
                                                {total[6] && total[6].user
                                                    ? total[6].user.first_name + " " + total[6].user.last_name
                                                    : "-"}
                                            </CustomTableCell>
                                            <CustomTableCell>
                                                {total[6] ? total[6]["done"].length : "-"}
                                            </CustomTableCell>
                                            <CustomTableCell>
                                                {total[6] ? totalProposal.length - total[6]["done"].length : "-"}
                                            </CustomTableCell>
                                        </TableRow>
                                        <TableRow>
                                            <CustomTableCell>
                                                {total[7] && total[7].user
                                                    ? total[7].user.first_name + " " + total[7].user.last_name
                                                    : "-"}
                                            </CustomTableCell>
                                            <CustomTableCell>
                                                {total[7] ? total[7]["done"].length : "-"}
                                            </CustomTableCell>
                                            <CustomTableCell>
                                                {total[7] ? totalProposal.length - total[7]["done"].length : "-"}
                                            </CustomTableCell>
                                        </TableRow>
                                        <TableRow>
                                            <CustomTableCell>
                                                {total[8] && total[8].user
                                                    ? total[8].user.first_name + " " + total[8].user.last_name
                                                    : "-"}
                                            </CustomTableCell>
                                            <CustomTableCell>
                                                {total[8] ? total[8]["done"].length : "-"}
                                            </CustomTableCell>
                                            <CustomTableCell>
                                                {total[8] ? totalProposal.length - total[8]["done"].length : "-"}
                                            </CustomTableCell>
                                        </TableRow>
                                        <TableRow>
                                            <CustomTableCell>
                                                {total[9] && total[9].user
                                                    ? total[9].user.first_name + " " + total[9].user.last_name
                                                    : "-"}
                                            </CustomTableCell>
                                            <CustomTableCell>
                                                {total[9] ? total[9]["done"].length : "-"}
                                            </CustomTableCell>
                                            <CustomTableCell>
                                                {total[9] ? totalProposal.length - total[9]["done"].length : "-"}
                                            </CustomTableCell>
                                        </TableRow>
                                        <TableRow>
                                            <CustomTableCell>
                                                {total[10] && total[10].user
                                                    ? total[10].user.first_name + " " + total[10].user.last_name
                                                    : "-"}
                                            </CustomTableCell>
                                            <CustomTableCell>
                                                {total[10] ? total[10]["done"].length : "-"}
                                            </CustomTableCell>
                                            <CustomTableCell>
                                                {total[10] ? totalProposal.length - total[10]["done"].length : "-"}
                                            </CustomTableCell>
                                        </TableRow>
                                        <TableRow>
                                            <CustomTableCell>
                                                {total[11] && total[11].user
                                                    ? total[11].user.first_name + " " + total[11].user.last_name
                                                    : "-"}
                                            </CustomTableCell>
                                            <CustomTableCell>
                                                {total[11] ? total[11]["done"].length : "-"}
                                            </CustomTableCell>
                                            <CustomTableCell>
                                                {total[11] ? totalProposal.length - total[11]["done"].length : "-"}
                                            </CustomTableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                        <ExpansionPanel CollapseProps={{ unmountOnExit: true }} defaultExpanded={false} disabled={!activeRFP}>
                            <ExpansionPanelSummary
                                className={classes.expansionSummaryRoot}
                                expandIcon={<ExpandMoreIcon />}>
                                <Typography variant="h3" style={{ width: "100%" }}>
                                    <PersonIcon className={classes.expansionIcon} />
                                    Member Evaluation Summary
                                </Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <Typography variant="h3" gutterBottom>
                                            Member Evaluation #1 Complete
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Table className={classes.table}>
                                            <EnhancedTableHead
                                                 stickyHeader
                                                columnData={[
                                                    {
                                                        id: "title",
                                                        numeric: false,
                                                        label: "Name"
                                                    },
                                                    ...table1
                                                ]}
                                            />
                                            <TableBody>
                                                <TableRow>
                                                    <CustomTableCell>Evaluation #1 Complete</CustomTableCell>
                                                    {rfpMembers.map(x => {
                                                        return (
                                                            <CustomTableCell>
                                                                {x.eval && x.eval.done ? "yes" : "No"}
                                                            </CustomTableCell>
                                                        );
                                                    })}
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </Grid>
                                </Grid>
                            </ExpansionPanelDetails>
                            <ExpansionPanelDetails>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <Typography variant="h3" gutterBottom>
                                            Member Evaluation #2 Complete
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Table className={classes.table}>
                                            <EnhancedTableHead
                                                stickyHeader
                                                columnData={[
                                                    {
                                                        id: "title",
                                                        numeric: false,
                                                        label: "Name"
                                                    },
                                                    ...table1
                                                ]}
                                            />
                                            <TableBody>
                                                <TableRow>
                                                    <CustomTableCell>Evaluation #2 Complete</CustomTableCell>
                                                    {rfpMembers.map(x => {
                                                        return (
                                                            <CustomTableCell>
                                                                {x.eval && x.eval.done2 ? "yes" : "No"}
                                                            </CustomTableCell>
                                                        );
                                                    })}
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </Grid>
                                </Grid>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                        <ExpansionPanel CollapseProps={{ unmountOnExit: true }} defaultExpanded={false} disabled={!activeRFP}>
                            <ExpansionPanelSummary
                                className={classes.expansionSummaryRoot}
                                expandIcon={<ExpandMoreIcon />}>
                                <Typography variant="h3" style={{ width: "100%" }}>
                                    <PersonIcon className={classes.expansionIcon} />
                                    Proposal Review Process Table Summary
                                </Typography>
                            </ExpansionPanelSummary>
                            {sync && !sync.ready && loaded && (
                                <ExpansionPanelDetails>
                                    <Grid container spacing={16} justify="center" alignItems="center">
                                        <Grid item>
                                            <CircularProgress size={100} aria-label="Loading Icon" thickness={1.5} />
                                            <Typography>Loading Data...</Typography>
                                        </Grid>
                                    </Grid>
                                </ExpansionPanelDetails>
                            )}
                            {sync && sync.ready && !defaultSorted && (
                                <>
                                    <ExpansionPanelDetails>
                                        <Grid container>
                                            <Grid item xs={10}>
                                                <Form
                                                    dontValidateOnMount={true}
                                                    validateOnSubmit={true}
                                                    defaultValues={{
                                                        filter: sort.sortInfo ? sort.sortInfo.filter : "All"
                                                    }}>
                                                    {formApi => (
                                                        <form
                                                            style={{ display: "inline-flex" }}
                                                            onSubmit={formApi.submitForm}>
                                                            <RadioGroup
                                                                field="filter"
                                                                name="filters"
                                                                eventHandle={val => this.setState({ filter: val })}
                                                                fullWidth
                                                                options={[
                                                                    {
                                                                        label: "All",
                                                                        value: "All"
                                                                    },
                                                                    {
                                                                        label: "Selected for presentation",
                                                                        value: "Selected for Further Consideration"
                                                                    },
                                                                    {
                                                                        label: "Recommended",
                                                                        value: "Recommended for Funding"
                                                                    },
                                                                    {
                                                                        label: "Not Selected for presentation",
                                                                        value: "Not Selected for Further Consideration"
                                                                    },
                                                                    {
                                                                        label: "Not Recommended",
                                                                        value: "Not Recommended for Funding"
                                                                    }
                                                                ]}
                                                                label="Search"
                                                                alignment={true}
                                                            />
                                                        </form>
                                                    )}
                                                </Form>
                                            </Grid>
                                            <Grid item xs={2}>
                                                <Form
                                                    dontValidateOnMount={true}
                                                    validateOnSubmit={true}
                                                    defaultValues={{
                                                        wordfilter: sort.sortInfo ? sort.sortInfo.wordFilter : null
                                                    }}>
                                                    {formApi => (
                                                        <form
                                                            style={{ float: "right", marginTop: 10 }}
                                                            onSubmit={formApi.submitForm}>
                                                            <TextField
                                                                eventHandle={val => this.setState({ wordFilter: val })}
                                                                field="wordfilter"
                                                                label="Search By"
                                                                filterInput
                                                            />
                                                        </form>
                                                    )}
                                                </Form>
                                            </Grid>
                                        </Grid>
                                    </ExpansionPanelDetails>
                                    <ExpansionPanelDetails>
                                        <Grid container spacing={16}>
                                            <Grid item xs={12} md={3} xl={4}>
                                                <StateRadioGroup
                                                    field="sort"
                                                    eventHandle={val => this.reviewRadioSort(val, "reviewProcess")}
                                                    value={this.state.reviewSort}
                                                    fullWidth
                                                    noLabel
                                                    options={[
                                                        {
                                                            label: "Staff Final Score Overall High to Low",
                                                            value: "final_score"
                                                        },
                                                        {
                                                            label: "Staff Final Score By Category High to Low",
                                                            value: "final_category"
                                                        }
                                                    ]}
                                                    label="Sort"
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={3} xl={4}>
                                                <StateRadioGroup
                                                    field="sort"
                                                    eventHandle={val => this.reviewRadioSort(val, "reviewProcess")}
                                                    value={this.state.reviewSort}
                                                    fullWidth
                                                    noLabel
                                                    options={[
                                                        {
                                                            label:
                                                                "Member Evaluation #1 Selection Calculation By Category High to Low",
                                                            value: "compiled_category"
                                                        },
                                                        {
                                                            label:
                                                                "Member Evaluation #1 Selection Calculation Overall High to Low",
                                                            value: "compiled"
                                                        }
                                                    ]}
                                                    label="Sort"
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={3} xl={4}>
                                                {use_allocation ? (
                                                    <StateRadioGroup
                                                        eventHandle={val => this.reviewRadioSort(val, "reviewProcess")}
                                                        noLabel
                                                        value={this.state.reviewSort}
                                                        fullWidth
                                                        options={[
                                                            {
                                                                label:"Member Evaluation #2 Compiled Median Allocation By Category High to Low",
                                                                value: "median_category_allocation"
                                                            },
                                                            {
                                                                label: "Member Evaluation #2 Compiled Median Allocation Overall High to Low",
                                                                value: "median_allocation"
                                                            },
                                                            {
                                                                label: "Member Evaluation #2 Compiled Average Allocation By Category High to Low",
                                                                value: "avg_category_allocation"
                                                            },
                                                            {
                                                                label: "Member Evaluation #2 Compiled Average Allocation Overall High to Low",
                                                                value: "avg_allocation"
                                                            }
                                                        ]}
                                                        label="Sort"
                                                    />
                                                ) : (
                                                    <StateRadioGroup
                                                        eventHandle={val => this.reviewRadioSort(val, "reviewProcess")}
                                                        noLabel
                                                        value={this.state.reviewSort}
                                                        fullWidth
                                                        options={[
                                                            {
                                                                label:
                                                                    "Member Evaluation #2 Compiled Median Score By Category High to Low",
                                                                value: "median_category"
                                                            },
                                                            {
                                                                label:
                                                                    "Member Evaluation #2 Compiled Median Score Overall High to Low",
                                                                value: "median"
                                                            },
                                                            {
                                                                label:
                                                                    "Member Evaluation #2 Compiled Average Score By Category High to Low",
                                                                value: "avg_category"
                                                            },
                                                            {
                                                                label:
                                                                    "Member Evaluation #2 Compiled Average Score Overall High to Low",
                                                                value: "avg"
                                                            }
                                                        ]}
                                                        label="Sort"
                                                    />
                                                )}
                                            </Grid>
                                        </Grid>
                                    </ExpansionPanelDetails>
                                    <ExpansionPanelDetails>
                                        <Table className={classes.table}>
                                            <EnhancedTableHead
                                                stickyHeader
                                                name="reviewProcess"
                                                order={order}
                                                orderBy={orderBy}
                                                onRequestSort={this.handleSortRequest}
                                                columnData={use_allocation ? reviewProcessDataAllocation : reviewProcessData}
                                            />
                                            <TableBody>
                                                {filtered
                                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                    .map((n, index) => {
                                                        return (
                                                            <TableRow>
                                                                <CustomTableCell className={classes.nowrap}>
                                                                    <Tooltip title="View Proposal">
                                                                        <Button
                                                                            color="primary"
                                                                            className={classes.deleteWidth}
                                                                            aria-label="View Proposal"
                                                                            component={Link}
                                                                            onClick={e => this.saveSortInfo()}
                                                                            to={`/proposal/${n.id}`}>
                                                                            <Visibility color="primary" />
                                                                        </Button>
                                                                    </Tooltip>
                                                                </CustomTableCell>
                                                                <CustomTableCell>
                                                                    {n.proposal_unique_id}
                                                                </CustomTableCell>
                                                                <CustomTableCell className={classes.cellWidth_2}>
                                                                    {n.name}
                                                                </CustomTableCell>
                                                                <CustomTableCell className={classes.cellWidth_2}>
                                                                    {n.summary}
                                                                </CustomTableCell>
                                                                <CustomTableCell>
                                                                    {n.final_category}
                                                                </CustomTableCell>
                                                                <CustomTableCell>{n.organization}</CustomTableCell>
                                                                <CustomTableCell>
                                                                    {n.pm_first_name} {n.pm_last_name}
                                                                </CustomTableCell>
                                                                <CustomTableCell>
                                                                    $
                                                                    {n.funding_total
                                                                        ? n.funding_total.toLocaleString()
                                                                        : 0}
                                                                </CustomTableCell>
                                                                <CustomTableCell>{n.status}</CustomTableCell>
                                                                <CustomTableCell>
                                                                    {n.secondary_status}
                                                                </CustomTableCell>
                                                                <CustomTableCell>
                                                                    {!isNaN(n.final_score) ? n.final_score : "-"}
                                                                </CustomTableCell>
                                                                <CustomTableCell className={classes.cellWidth_2}>
                                                                    {n.final_member_comments2}
                                                                </CustomTableCell>
                                                                <CustomTableCell>{n.compiled}</CustomTableCell>
                                                                <CustomTableCell>
                                                                    {n.selected} out of {n.selected_total}
                                                                </CustomTableCell>
                                                                <CustomTableCell className={classes.cellWidth_3}>
                                                                    {n.conflict ? n.conflict : "-"}
                                                                </CustomTableCell>
                                                                <CustomTableCell className={classes.cellWidth_3}>
                                                                    {n.member_notes ? n.member_notes : "-"}
                                                                </CustomTableCell>
                                                                <CustomTableCell>
                                                                    {activeRFP.presentation_selection_open
                                                                        ? n.selected_presentation
                                                                            ? "Yes"
                                                                            : "No"
                                                                        : "-"}
                                                                </CustomTableCell>
                                                                {use_allocation ? (
                                                                    <>
                                                                    <CustomTableCell>
                                                                        {isNaN(n.median_allocation) ? "-" : "$" + n.median_allocation.toLocaleString()}
                                                                    </CustomTableCell>
                                                                    <CustomTableCell>
                                                                        {isNaN(n.avg_allocation) ? "-" : "$" + n.avg_allocation.toLocaleString()}
                                                                    </CustomTableCell>
                                                                    <CustomTableCell>
                                                                        {n.score2_total_allocation} out of {n.selected_total}
                                                                    </CustomTableCell>
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                    <CustomTableCell>
                                                                        {isNaN(n.median) ? "-" : n.median}
                                                                    </CustomTableCell>
                                                                    <CustomTableCell>
                                                                        {isNaN(n.avg) ? "-" : n.avg}
                                                                    </CustomTableCell>
                                                                    <CustomTableCell>
                                                                        {n.score2_total} out of {n.mem_scoring2}
                                                                    </CustomTableCell>
                                                                    </>
                                                                )}
                                                                <CustomTableCell>
                                                                    {activeRFP.recommendations_open
                                                                        ? n.recommended_for_funding
                                                                            ? "Yes"
                                                                            : "No"
                                                                        : "-"}
                                                                </CustomTableCell>
                                                                <CustomTableCell>
                                                                    {activeRFP.recommendations_open
                                                                        ? "$" +
                                                                            (n.recommended_amount
                                                                                ? n.recommended_amount.toLocaleString()
                                                                                : 0)
                                                                        : "-"}
                                                                </CustomTableCell>
                                                                <CustomTableCell>
                                                                    {n.contingencies ? n.contingencies : "-"}
                                                                </CustomTableCell>
                                                            </TableRow>
                                                        );
                                                    })}
                                                {filtered.length < 1 && (
                                                    <TableRow>
                                                        <CustomTableCell
                                                            colSpan={reviewProcessData.length}
                                                            className={classes.centerAlign}>
                                                            <caption style={{display:"inline"}}>No Proposals Found</caption>
                                                        </CustomTableCell>
                                                    </TableRow>
                                                )}
                                            </TableBody>
                                        </Table>
                                    </ExpansionPanelDetails>
                                    {filtered.length > 25 && (
                                        <ExpansionPanelDetails>
                                            <Table>
                                                <TableFooter>
                                                    <TableRow>
                                                        <TablePagination
                                                            colSpan={reviewProcessData.length}
                                                            count={filtered.length}
                                                            rowsPerPage={rowsPerPage}
                                                            page={page}
                                                            onChangePage={this.handleChangePage}
                                                            onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                                            ActionsComponent={TablePaginationActionsWrapped}
                                                        />
                                                    </TableRow>
                                                </TableFooter>
                                            </Table>
                                        </ExpansionPanelDetails>
                                    )}
                                </>
                            )}
                        </ExpansionPanel>
                    </Grid>
                </Grid>
            </AppContainer>
        );
    }
}

ConfigureProposalProcess = connect(
    (state, ownProps) => ({
        sort: state.nav,
        sync: state.sync,
        authState: state.auth,
        totalProposal: totalProposal(state, ownProps),
        rfpYears: getRFPYears(state),
        categories: getCategories(state, ownProps),
        rfpMembers: getRFPMembers(state, ownProps),
        rfpYear: getYear(state, ownProps),
        reviews: getReviews(state, ownProps),
        reviewProcess: getReviewTable(state, ownProps),
        scores: getScores(state, ownProps),
        staffSlots: getStaffSlots(state, ownProps)
    }),
    {
        ...MemberEvaluationComplete.actions,
        ...MemberEvaluation.actions,
        ...ProposalReview.actions,
        ...ProposalView.actions,
        ...StaffScore.actions,
        ...SystemSetting.actions,
        ...navActions
    }
)(ConfigureProposalProcess);

export default withStyles(styles)(withRouter(ConfigureProposalProcess));
