import orm, { Model, createSelector } from "../common/orm";
import { fk } from "redux-orm";

export class SystemSetting extends Model {
    static get fields() {
        return {};
    }
    static get modelName() {
        return "SystemSetting";
    }
}
orm.register(SystemSetting);

export class StaffSlots extends Model {
    static get fields() {
        return {
            staff: fk("User", "slot_staff")
        };
    }
    static get modelName() {
        return "StaffSlots";
    }
}
orm.register(StaffSlots);

export class Message extends Model {
    static get fields() {
        return {};
    }
    static get modelName() {
        return "Message";
    }
}
orm.register(Message);

export class ProposalTab extends Model {
    static get fields() {
        return {};
    }
    static get modelName() {
        return "ProposalTab";
    }

    static selectMessage(id) {
        return createSelector(session => {
            return session.ProposalTab.withId(id);
        });
    }
}
orm.register(ProposalTab);

export class MemberHelpText extends Model {
    static get fields() {
        return {};
    }
    static get modelName() {
        return "MemberHelpText";
    }
}
orm.register(MemberHelpText);

export class ProposalHelpText extends Model {
    static get fields() {
        return {};
    }
    static get modelName() {
        return "ProposalHelpText";
    }
}
orm.register(ProposalHelpText);

export class WorkPlanTab extends Model {
    static get fields() {
        return {};
    }
    static get modelName() {
        return "WorkPlanTab";
    }

    static selectMessage(id) {
        return createSelector(session => {
            return session.WorkPlanTab.withId(id);
        });
    }
}
orm.register(WorkPlanTab);

export class WorkPlanHelpText extends Model {
    static get fields() {
        return {};
    }
    static get modelName() {
        return "WorkPlanHelpText";
    }
}
orm.register(WorkPlanHelpText);

export class EmailTemplate extends Model {
    static get fields() {
        return {};
    }
    static get admin_only() {
        return true;
    }
    static get modelName() {
        return "EmailTemplate";
    }
}
orm.register(EmailTemplate);

export class StatusUpdateTab extends Model {
    static get fields() {
        return {};
    }
    static get modelName() {
        return "StatusUpdateTab";
    }

    static selectMessage(id) {
        return createSelector(session => {
            return session.StatusUpdateTab.withId(id);
        });
    }
}
orm.register(StatusUpdateTab);

export class StatusUpdateHelpText extends Model {
    static get fields() {
        return {};
    }
    static get modelName() {
        return "StatusUpdateHelpText";
    }
}
orm.register(StatusUpdateHelpText);

export class MemberMessage extends Model {
    static get fields() {
        return {};
    }
    static get member_or_staff() {
        return true;
    }
    static get modelName() {
        return "MemberMessage";
    }
}
orm.register(MemberMessage);

export class User extends Model {
    static get fields() {
        return {};
    }
    static get modelName() {
        return "User";
    }
}
orm.register(User);

export class MemberTerm extends Model {
    static get fields() {
        return {
            user: fk("User", "term_member")
        };
    }
    static get admin_only() {
        return true;
    }
    static get modelName() {
        return "MemberTerm";
    }
}
orm.register(MemberTerm);

export class Organization extends Model {
    static get fields() {
        return {};
    }
    static get modelName() {
        return "Organization";
    }
}
orm.register(Organization);

export class FiscalAgent extends Model {
    static get fields() {
        return {};
    }
    static get modelName() {
        return "FiscalAgent";
    }
}
orm.register(FiscalAgent);

export class FundingCategory extends Model {
    static get fields() {
        return {};
    }
    static get modelName() {
        return "FundingCategory";
    }
}
orm.register(FundingCategory);

export class StaffScore extends Model {
    static get fields() {
        return {
            proposal: fk("ProposalView", "score_proposal"),
            user: fk("User", "score_user")
        };
    }
    static get dontLoadByDefault() {
        return true;
    }
    static get modelName() {
        return "StaffScore";
    }
}
orm.register(StaffScore);

export class ProposalReview extends Model {
    static get fields() {
        return {
            proposal: fk("ProposalView", "review_proposal"),
            staff_1: fk("User", "1_user"),
            staff_2: fk("User", "2_user"),
            staff_3: fk("User", "3_user"),
            staff_4: fk("User", "4_user"),
            staff_5: fk("User", "5_user"),
            staff_6: fk("User", "6_user"),
            staff_7: fk("User", "7_user"),
            staff_8: fk("User", "8_user"),
            staff_9: fk("User", "9_user"),
            staff_10: fk("User", "10_user"),
            staff_11: fk("User", "11_user"),
            staff_12: fk("User", "12_user")
        };
    }
    static get dontLoadByDefault() {
        return true;
    }
    static get modelName() {
        return "ProposalReview";
    }
}
orm.register(ProposalReview);

export class MemberEvaluation extends Model {
    static get fields() {
        return {
            proposal: fk("ProposalView", "eval_member")
        };
    }
    static get modelName() {
        return "MemberEvaluation";
    }
    static get dontLoadByDefault() {
        return true;
    }
}
orm.register(MemberEvaluation);

export class RFPMember extends Model {
    static get fields() {
        return {
            user: fk("User", "rfp_user")
        };
    }
    static get modelName() {
        return "RFPMember";
    }
}
orm.register(RFPMember);


export class LegislativeAmendment extends Model {
    static get fields() {
        return {};
    }
    static get admin_only() {
        return true;
    }
    static get modelName() {
        return "LegislativeAmendment";
    }
}
orm.register(LegislativeAmendment);